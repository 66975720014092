import * as React from "react";
import { ScrollView } from "react-native";
import dayjs from "dayjs";
import { useApolloClient } from "@apollo/client";

import {
  Page,
  ListQuery,
  ListItem,
  renderAction,
  Form as CDForm,
} from "components";
import {
  Flex,
  Text,
  Tooltip,
  Touchable,
  Icon,
  Input,
  Form,
  useTheme,
} from "unikit";
import { useHandleDoc } from "api";
import { useAppContext } from "../../context";
import i18n from "../../i18n";

const defaultAction = ({ key, item, label, changeDoc, ...rest }) => [
  {
    icon: item?.[key] ? "check" : "x",
    bg: item?.[key] ? "success:setAlpha:0.1" : "error:setAlpha:0.1",
    color: item?.[key] ? "success" : "error",
    w: 100,
    label: label,
    onPress: () => {
      changeDoc({
        id: item._id,
        doc: { [key]: item?.[key] ? false : true },
      });
    },
    ...rest,
  },
];

const defaultProps = {
  DeliveryNote: {
    title: ({ item }) => item.name,
    sort: { number: -1 },
    groupOtions: [
      {
        label: "Monat",
        icon: "calendar",
      },
      {
        label: "Behandelnder Arzt",
        groupBy: "patient.doctor.name",
        groupByFormat: ({ key, value }) =>
          value || "Kein Behandelnder Arzt vorhanden",
        icon: "user",
      },
    ],
    actions: ({ item, navigation, changeDoc, theme }) => [
      {
        icon: "list",
        bg: "transparent",
        text: `${item?.number}`,
        label: "Nummer",
        w: 100,
        font: "default",
        justifyContent: "flex-start",
        px: 0,
      },
      {
        icon: "file-text",
        bg: "transparent",
        text: `${item?.files?.length || 0}`,
        label: "Anhänge",
        w: 70,
        font: "default",
        justifyContent: "flex-start",
        px: 0,
      },
      {
        icon: "user",
        bg: "transparent",
        text: `${item?.patient?.lastName}, ${item?.patient?.firstName}`,
        onPress: () => {
          navigation.navigate("PatientProfile", {
            _id: item?.patient?._id,
            options: "recipe",
          });
        },
        label: "Patient",
        w: 200,
        font: "default",
        justifyContent: "flex-start",
        px: 0,
      },
      ...defaultAction({
        key: "recipe_requested",
        label: "Rez. angefordert",
        changeDoc,
        item,
      }),
      ...defaultAction({
        key: "recipe_received",
        label: "Rez. erhalten",
        changeDoc,
        item,
      }),
      ...defaultAction({
        key: "material_send",
        label: "Mat. geliefert",
        changeDoc,
        item,
      }),
      {
        icon: "printer",
        w: 100,
        label: "Rezeptanforderung",
        onPress: () => {
          navigation.navigate("PDF", {
            typename: "DeliveryNote",
            id: item._id,
            options: "recipe",
          });
        },
      },
      // {
      //   icon: "edit",
      //   w: 100,
      //   label: "Bearbeiten",
      //   onPress: () => {
      //     navigation.navigate("Form", {
      //       id: item._id,
      //       title: `${item.name} bearbeiten`,
      //       typename: "DeliveryNote",
      //       defaultDoc: item,
      //       mode: "update",
      //     });
      //   },
      // },
      {
        icon: "message-circle",
        w: 100,
        label: "Bemerkung",
        onPress: () => {
          theme.alert({
            bg: "surface",
            message: "Deine Bemerkung",
            timeout: false,
            position: "center",
            closeButton: true,
            renderContent: ({ close }) => {
              return (
                <Flex w="100%">
                  <CDForm
                    schema={[
                      {
                        key: "listNote",
                        input: "text",
                        placeholder: "Bemerkung",
                        label: "Bemerkung",
                        defaultValue: item?.listNote || "",
                      },
                    ]}
                    groupBy={null}
                    scrollEnabled={false}
                    style={{ width: "100%" }}
                    spacer={false}
                    onSubmit={({ doc }) => {
                      changeDoc({
                        id: item._id,
                        doc: { listNote: doc.listNote },
                      });
                      close?.();
                    }}
                  />
                </Flex>
              );
            },
          });
        },
      },
    ],
  },
  Evidence: {
    title: ({ item }) => dayjs(item.surveyDate).format("DD MMM YYYY"),
    sort: { surveyDate: -1 },
    groupOtions: [
      {
        label: "Monat",
        groupBy: "surveyDate",
        groupByFormat: ({ key, value }) => dayjs(value).format("MMM YYYY"),
        icon: "calendar",
      },
      {
        label: "Behandelnder Arzt",
        groupBy: "patient.doctor.name",
        groupByFormat: ({ key, value }) =>
          value || "Kein Behandelnder Arzt vorhanden",
        icon: "user",
      },
    ],
    actions: ({ item, navigation, changeDoc }) => [
      {
        icon: "user",
        bg: "transparent",
        text: `${item?.patient?.lastName}, ${item?.patient?.firstName}`,
        label: "Patient",
        w: 200,
        font: "default",
        justifyContent: "flex-start",
        px: 15,
      },
      ...defaultAction({
        key: "bandagedChange",
        label: "Verbandswechsel",
        text: item?.bandagedDuration ? `${item?.bandagedDuration}min` : "",
        changeDoc,
        item,
      }),
      ...defaultAction({
        key: "medicalRound",
        label: "Therapieänderung",
        changeDoc,
        item,
      }),
      ...defaultAction({
        key: "woundImage",
        label: "Wundbild",
        changeDoc,
        item,
      }),
      ...defaultAction({
        key: "inPatient",
        label: "Stationär",
        changeDoc,
        item,
      }),
      ...defaultAction({
        key: "recipe",
        label: "Rezept",
        changeDoc,
        item,
      }),
      ...defaultAction({
        key: "cashSale",
        label: "Barverkauf",
        changeDoc,
        item,
      }),
      ...defaultAction({
        key: "laser",
        label: "Laser",
        changeDoc,
        item,
      }),
      {
        icon: "edit",
        w: 100,
        label: "Bearbeiten",
        onPress: () => {
          navigation.navigate("Form", {
            id: item._id,
            title: `${item.name} bearbeiten`,
            typename: "Evidence",
            defaultDoc: item,
            mode: "update",
          });
        },
      },
    ],
  },
};

function DocTable({ navigation }) {
  const theme = useTheme();
  const client = useApolloClient();
  const { handleDoc } = useHandleDoc();
  const [typename, setTypename] = React.useState("DeliveryNote");
  const [group, setGroup] = React.useState("surveyDate");
  const { team } = useAppContext();
  const [Comp, setComp] = React.useState(null);
  const [subTitle, setSubTitel] = React.useState("");

  const teamOptions = team.map((member) => ({
    value: member._id,
    label: `${member?.firstName} ${member?.lastName}`,
  }));

  const typeProps = defaultProps[typename] || {};
  const activeGrouping = typeProps.groupOtions.find((t) => t.groupBy === group);

  const changeDoc = React.useCallback(({ id, doc }) => {
    handleDoc({
      doc,
      typename,
      id,
      mode: "update",
      ignoreLock: true,
    });
  });

  const renderItem = React.useCallback(({ item }) => {
    return (
      <ListItem
        users={[item.user]}
        title={typeProps?.title?.({ item })}
        note={item.listNote}
        canceled={item.canceled}
        noteProps={{ icon: "message-circle" }}
        desc={`Erstellt am ${dayjs(item?.surveyDate || item?.createdAt).format(
          "DD. MMMM YYYY"
        )}`}
        onPress={() =>
          navigation.navigate("Form", {
            id: item._id,
            title: `${item.name} bearbeiten`,
            typename,
            defaultDoc: item,
            mode: "update",
          })
        }
        actionType="inline"
        actions={
          typeProps?.actions?.({ item, changeDoc, navigation, theme }) || []
        }
      />
    );
  });

  return (
    <Page
      title={`${i18n.t(`types.${typename.toLowerCase()}_plural`)}`}
      subTitle={`${subTitle}`}
      leftAction={
        <>
          <Tooltip
            popover={({ toggleTooltip }) => {
              return (
                <Flex>
                  <Text px={15} py={10}>
                    Dokument
                  </Text>
                  <Input
                    type="select"
                    bg="transparent"
                    value={typename}
                    options={[
                      {
                        value: "DeliveryNote",
                        label: `${i18n.t(`types.deliverynote_plural`)}`,
                      },
                      {
                        value: "Evidence",
                        label: `${i18n.t(`types.evidence_plural`)}`,
                      },
                    ]}
                    onChange={(v) => setTypename(v || "DeliveryNote")}
                    inline
                    picker="switch"
                  />
                  <Text px={15} py={10}>
                    Gruppierung wählen
                  </Text>
                  {typeProps?.groupOtions?.map((option, i) => {
                    return (
                      <Touchable
                        opacity={group === option.groupBy ? 1 : 0.5}
                        key={`group-${i}`}
                        w="100%"
                        h={50}
                        onPress={() => {
                          setGroup(option.groupBy);
                          toggleTooltip();
                        }}
                        px={20}
                        alignItems="center"
                        row
                      >
                        <Icon name={option.icon} mr={10} />
                        <Text>{option.label}</Text>
                      </Touchable>
                    );
                  })}
                </Flex>
              );
            }}
          >
            {renderAction({ icon: "list" })}
          </Tooltip>
          {renderAction({
            icon: "refresh-ccw",
            ml: 10,
            onPress: () => {
              client.refetchQueries({
                include: [`${typename.toLowerCase()}s`],
              });
            },
          })}
        </>
      }
      rightAction={Comp}
      useFlatList={true}
      scrollWrapperComponent={ScrollView}
      scrollWrapperProps={{
        horizontal: true,
        style: { minWidth: "100%" },
        contentContainerStyle: { minWidth: "100%" },
      }}
      scrollComponent={ListQuery}
      scrollProps={{
        onLoad: ({ typename, count, data }) => {
          setSubTitel(`${data?.length}/${count}`);
        },
        loadMoreMode: "paging",
        limit: 100,
        renderToSection: (c) => setComp(c),
        filterOptions: {
          Lieferscheinnummer: {
            input: "number",
            placeholder: "Nummer",
            key: "number",
            formatFilter: (v) => {
              return v;
            },
          },
          Mitarbeiter: {
            key: "createdBy",
            formatFilter: (v) => {
              return v;
            },
            options: teamOptions,
          },
          Von: {
            key: "from",
            formatFilter: (v) => {
              return v;
            },
            input: "date",
          },
          Bis: {
            key: "to",
            formatFilter: (v) => {
              return v;
            },
            input: "date",
          },
        },
        defaultOptions: {},
        ListHeaderComponent: (
          <Flex w="100%" px={20} h={40} justifyContent="flex-end" row>
            {typeProps?.actions?.({})?.map((item, i) => {
              return (
                <Flex
                  key={`action-header-${i}`}
                  ml={10}
                  w={item.w}
                  h="100%"
                  justifyContent="center"
                >
                  <Text font="label" numberOfLines={1}>
                    {item.label}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        ),
        renderItem,
        typename,
        sort: typeProps?.sort,
        ...(activeGrouping || {}),
      }}
    />
  );
}

export default DocTable;

import * as React from "react";
import { isWeb, useTheme } from "unikit";

import { Page, Form } from "components";
import { useHandleDoc } from "api";

import { useAppContext } from "../../context";

export default function Screen({ route, navigation }) {
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();

  const orderId = route.params?.orderId;

  const [doc, setDoc] = React.useState(
    route.params?.defaultDoc || {
      type: orderId ? "warehouse_in" : "warehouse_out",
      warehouseFromId: "warehouse",
      warehouseToId: orderId ? "warehouse_in" : "warehouse_out",
    }
  );
  const { handleDoc } = useHandleDoc();
  const { user, refetchUser } = useAppContext();

  const getWarehouses = (user, filter) => {
    const array = [];
    array.push({
      label: `Zentrallager`,
      value: "warehouse",
    });
    array.push({
      label: `${user.lastName}, ${user.firstName}`,
      value: user._id,
    });
    if (doc?.type === "warehouse_in") {
      return [
        {
          label: `Zentrallager`,
          value: "warehouse",
        },
      ];
    }
    if (user.team) {
      user.team.map((member) => {
        if (member.banned !== true) {
          array.push({
            label: `${member.lastName}, ${member.firstName}`,
            value: member._id,
          });
        }
      });
    }
    if (doc && doc.warehouseFromId && filter) {
      return array.filter((a) => a.value !== doc.warehouseFromId);
    } else {
      return array;
    }
  };

  const getCountByWarehouse = ({ warehouseId, item, seperator = "" }) => {
    if (warehouseId === "warehouse") {
      return `Bestand Zentrallager: ${item.inStock || 0}${seperator}`;
    } else {
      const memberWarehouse = [user, ...(user.team || [])].find(
        (t) => t._id === warehouseId
      );
      if (!memberWarehouse) return "";
      return `Bestand ${memberWarehouse.lastName}, ${
        memberWarehouse.firstName
      }: ${memberWarehouse?.warehouseMaterials?.[item._id] || 0}${seperator}`;
    }
  };

  const schema = React.useMemo(
    () => [
      ...(isWeb || orderId
        ? []
        : [
            {
              key: "code",
              label: "Barcode scannen um Material hinzuzufügen",
              input: "BarCode",
              group: "Allgemein",
              optional: true,
            },
          ]),
      {
        key: "type",
        label: "Type",
        placeholder: "Type",
        input: "select",
        options: orderId
          ? [{ label: "Eingang", value: "warehouse_in" }]
          : [
              { label: "Eingang", value: "warehouse_in" },
              { label: "Entnahme", value: "warehouse_out" },
              { label: "Umbuchen", value: "warehouse_change" },
            ],
        defaultValue: orderId ? "warehouse_in" : "warehouse_out",
        group: "Allgemein",
      },
      ...(orderId || doc?.type === "warehouse_in"
        ? []
        : [
            {
              key: "warehouseFromId",
              label: "Von",
              placeholder: "Von",
              group: "Allgemein",
              input: "select",
              defaultValue: "warehouse",
              options: getWarehouses(user),
            },
          ]),
      ...(doc?.type === "warehouse_out"
        ? []
        : [
            {
              key: "warehouseToId",
              label: "Nach",
              placeholder: "Nach",
              group: "Allgemein",
              input: "select",
              options: getWarehouses(user, true),
              defaultValue:
                orderId || doc?.type === "warehouse_in"
                  ? "warehouse_in"
                  : "warehouse_out",
            },
          ]),
      {
        key: "materials",
        input: "DocList",
        label: "Materialien",
        group: "Allgemein",
        defaultValue: [],
        inputProps: {
          queryData: false,
          allowSearch: true,
          allowRemove: true,
          typename: "Material",
          actions: ({ showPrompt, item }) => [
            {
              icon: "refresh-ccw",
              text: "Anzahl ändern",
              onPress: () => {
                showPrompt({
                  item,
                  title: "Wähle eine Anzahl",
                  force: true,
                  schema: [
                    {
                      key: "count",
                      input: "number",
                      label: "Anzahl",
                      defaultValue: item.count || 1,
                    },
                  ],
                });
              },
            },
          ],
          prompt: {
            title: "Wähle eine Anzahl",
            schema: [
              {
                key: "count",
                input: "number",
                label: "Anzahl",
                defaultValue: 1,
              },
            ],
          },
          title: (item) => `${item.count}x ${item.producer}, ${item.name}`,
          desc: (item) => {
            return `Größe: ${item.size}, Verpackungseinheit: ${item.packagingUnit}`;
          },
          note: (item) => {
            return `${getCountByWarehouse({
              warehouseId: doc.warehouseFromId,
              item,
              seperator: " - ",
            })}${getCountByWarehouse({
              warehouseId: doc.warehouseToId,
              item,
            })}`;
          },
        },
      },
    ],
    [orderId, isWeb, doc]
  );

  console.log({ schema, doc });

  return (
    <Page
      title="Warenausgang/umbuchung"
      goBack={navigation?.goBack}
      scrollable={true}
    >
      <Form
        isLoading={loading}
        navigation={navigation}
        buttonText="Buchen"
        defaultDoc={doc}
        onChangeDoc={({ doc }) => {
          setDoc({ ...doc });
        }}
        onValidationError={() => {
          setLoading(false);
        }}
        onError={() => {
          setLoading(false);
        }}
        onSubmit={({ doc }) => {
          setLoading(true);
          if (!doc.materials || doc.materials.length === 0) {
            theme.alert({
              type: "error",
              message: "Bitte wählen Sie ein Material",
            });
            setLoading(false);
          } else {
            if (doc.type === "warehouse_in") {
              delete doc["warehouseFromId"];
            }
            if (doc.type === "warehouse_out") {
              delete doc["warehouseToId"];
            }
            handleDoc({
              doc: { ...doc, orderId },
              refetchQueries: ["orders"],
              typename: "Action",
              mode: "insert",
              onSuccess: () => {
                if (route.params && route.params.onSuccess) {
                  route.params.onSuccess(navigation);
                  setLoading(false);
                } else {
                  navigation.goBack();
                  setLoading(false);
                }
                if (refetchUser) refetchUser();
              },
            });
          }
        }}
        schema={schema}
      />
    </Page>
  );
}

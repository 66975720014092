import * as React from "react";
import { FlatList, ScrollView } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import dayjs from "dayjs";
import Hyperlink from "react-native-hyperlink";
import { useNavigation } from "@react-navigation/native";

import { Text, Flex, Input, Icon, Avatar, Button, isIOS } from "unikit";
import { useData, useHandleDoc } from "api";

import { useAppContext, formatUserName } from "../../context";
import { ListItem } from "../list";

const App = ({
  docId,
  typeName,
  patientId,
  therapyId,
  withCompanyId,
  style = {},
  ...rest
}) => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const { handleDoc, loading } = useHandleDoc();
  console.log({ loading });
  const { user } = useAppContext();
  const { data } = useData({
    typename: "Action",
    variables: {
      filter: {
        type: "chat",
        ...(docId ? { docId } : {}),
        ...(typeName ? { typeName } : {}),
        ...(patientId ? { patientId } : {}),
        ...(therapyId ? { therapyId } : {}),
      },
      sort: { createdAt: -1 },
    },
    //pollInterval: 50000,
  });

  const [message, setMessage] = React.useState({
    text: "",
    track: false,
    trackString: "",
    trackPosition: null,
  });

  const suggestions = [...(user.team || [])];

  React.useEffect(() => {
    if (message?.text?.length > 0) {
      const { selection, text } = message;
      const { start, end } = selection;
      const last = start === end ? text[start - 1] : text[message.length - 1];
      if (last === "@") {
        setMessage((m) => ({ ...m, track: true, trackPosition: start }));
      } else if (message.track === true) {
        setMessage((m) => ({
          ...m,
          trackString: m.trackString + last,
        }));
      }
    }
  }, [message.selection]);

  const renderItem = ({ item, index }) => {
    const isOwn = item?.createdBy === user._id;
    const UserAvatar = item.user ? (
      <Avatar
        source={item.user?.image ? { uri: item.user.image.url } : undefined}
        size={36}
        ml={isOwn ? 5 : 0}
        mr={!isOwn ? 5 : 0}
        char={`${formatUserName({ user: item.user, short: true })}`}
      />
    ) : null;
    return (
      <Flex
        w="100%"
        px={15}
        py={5}
        justifyContent={isOwn ? "flex-end" : "flex-start"}
        alignItems="flex-end"
        row
      >
        {isOwn ? null : UserAvatar}
        <Flex w="auto" maxWidth="80%">
          <Flex
            borderRadius={15}
            borderBottomLeftRadius={isOwn ? 15 : 0}
            borderBottomRightRadius={isOwn ? 0 : 15}
            bg={isOwn ? "primary" : "surface"}
            shadow={1}
          >
            <Flex p={15}>
              {item.docId && !docId ? (
                <ListItem
                  icons={[{ name: "file-text" }]}
                  borderWidth={1}
                  borderColor="primary:setAlpha:0.1"
                  title={
                    item?.doc?.name
                      ? item?.doc?.name
                      : item?.typeName === "Evidence" && item?.doc?.createdAt
                      ? `Monatsnachweis ${dayjs(item?.doc.createdAt).format(
                          "MMM YYYY"
                        )}`
                      : "PDF"
                  }
                  desc={`${
                    item.patient
                      ? `${item.patient.lastName}, ${item.patient.firstName}`
                      : ``
                  }`}
                  onPress={() => {
                    if (item?.typeName === "Evidence" && item.doc) {
                      var start = dayjs(item.doc.surveyDate)
                        .startOf("month")
                        .toDate();
                      var end = dayjs(item.doc.surveyDate)
                        .endOf("month")
                        .toDate();
                      navigation.navigate("PDF", {
                        typename: "Evidence",
                        id: item.docId,
                        patientId: item.patientId,
                        options: JSON.stringify({
                          list: true,
                          name: `Monatsnachweise ${dayjs(
                            item.doc.surveyDate
                          ).format("MMM YYYY")}`,
                          filter: {
                            patientId: item.doc.patientId,
                            from: start,
                            to: end,
                            canceled: { $ne: true },
                          },
                        }),
                      });
                    } else {
                      navigation.navigate("PDF", {
                        typename: item.typeName,
                        id: item.docId,
                        patientId: item.patientId,
                        therapyId: item.therapyId,
                      });
                    }
                  }}
                  mb={5}
                />
              ) : null}
              <Hyperlink onPress={(url, text) => alert(url + ", " + text)}>
                <Text color={isOwn ? "#fff" : "text"}>{item.message}</Text>
              </Hyperlink>
            </Flex>
          </Flex>
          <Text font="label" opacity={0.75} mt={2} px={10}>
            {`${dayjs(item.createdAt).format(
              "DD.MM.YYYY HH:mm"
            )} ${formatUserName({ user: item.user })}`}
          </Text>
        </Flex>
        {!isOwn ? null : UserAvatar}
      </Flex>
    );
  };

  const config = {
    enableOnAndroid: true,
    enableAutomaticScroll: true,
  };

  return (
    <KeyboardAwareScrollView
      scrollEnabled={false}
      contentContainerStyle={{ flex: 1, ...style }}
      bounces={false}
      keyboardShouldPersistTaps="handled"
      extraScrollHeight={50}
      {...config}
    >
      <FlatList
        inverted
        showsVerticalScrollIndicator={false} // does not work
        contentContainerStyle={{ paddingTop: 25, paddingBottom: 25 }}
        data={data}
        keyExtractor={(item, index) => `list-item-${index}`}
        renderItem={renderItem}
        onEndReached={() => {
          // alert("end");
        }}
      />
      <Flex w="100%" px={15} pb={10 + insets.bottom / 2} relative>
        {message.track === true && suggestions?.length > 0 ? (
          <Flex
            l={10}
            bg="surface:setAlpha:0.8"
            borderRadius={10}
            r={10}
            maxHeight={370}
            b={55 + 10 + insets.bottom / 2}
            p={10}
            absolute
          >
            <ScrollView contentContainerStyle={{ flex: 1 }}>
              {suggestions?.map((member, i) => {
                return (
                  <ListItem
                    w="100%"
                    users={[member]}
                    title={`${member?.lastName}, ${member?.firstName}`}
                    onPress={() => {
                      const m = message;
                      const replaced =
                        m.text.substring(0, m.trackPosition) +
                        `${member?.lastName}${member?.firstName}` +
                        m.text.substring(
                          m.trackPosition + m.trackString.length
                        );
                      setMessage((m) => ({
                        ...m,
                        text: replaced,
                        track: false,
                        trackString: "",
                      }));
                    }}
                  />
                );
              })}
            </ScrollView>
          </Flex>
        ) : null}

        <Input
          value={message.text}
          blurOnSubmit={false}
          placeholder="Nachricht"
          onChange={(v) => setMessage((m) => ({ ...m, text: v }))}
          onSelectionChange={(e) => {
            const { selection } = e.nativeEvent;
            setMessage((m) => ({ ...m, selection }));
          }}
          renderRight={
            <Button
              rounded
              light
              mr={-10}
              loading={loading}
              disabled={loading || message?.text?.length === 0}
              onPress={() => {
                if (!loading && message?.text?.length > 0) {
                  handleDoc({
                    doc: {
                      message: message.text,
                      type: "chat",
                      docId,
                      typeName,
                      patientId,
                      therapyId,
                      withCompanyId,
                    },
                    typename: "Action",
                    mode: "insert",
                    onSuccess: () => {
                      setMessage({ text: "" });
                    },
                  });
                }
              }}
            >
              <Icon name="send" />
            </Button>
          }
        />
      </Flex>
    </KeyboardAwareScrollView>
  );
};

export default App;

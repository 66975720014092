import * as React from "react";
import { useTheme, Flex, Tooltip, Icon, isAndroid, Button } from "unikit";
import { Platform, KeyboardAvoidingView } from "react-native";
import { WebView } from "react-native-webview";
import * as WebBrowser from "expo-web-browser";

const CHATRA_ID = "57jG5ByjNDptPyqyK";

const HTML = `<!DOCTYPE html>
<html>
  <meta charset="utf-8" />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
  />
  <style>
    body,
    html {
      width: 100vw;
      height: 100vh;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }
    * {
      box-sizing: border-box;
      text-decoration: none;
      -webkit-user-select: none; /* Safari 3.1+ */
      -moz-user-select: none; /* Firefox 2+ */
      -ms-user-select: none; /* IE 10+ */
      user-select: none; /* Standard syntax */
    }

    #chatra-wrapper {
      padding-bottom: 0px;
      background: transparent;
    }

    .container {
      padding: 50px;
    }


  </style>

  <body onload="initChatra()">
    <div
      style="position: relative; width: 100vw; height: 100vh;"
      id="chatra-wrapper"
    ></div>

    <script>
      function initChatra() {
        setTimeout(() => {
            var userId = window.userId;
            var userColor = window.userColor;
            var name = window.name;
            var email = window.email;
            var company = window.company;


          window.ChatraSetup = {
            mode: "frame",
            injectTo: "chatra-wrapper",
            clientId: userId,
          };
          window.ChatraIntegration = {
            name: name,
            email: email,
            company: company
          };

          (function(d, w, c) {
            w.ChatraID = "${CHATRA_ID}";
            var s = d.createElement("script");
            w[c] =
              w[c] ||
              function() {
                (w[c].q = w[c].q || []).push(arguments);
              };
            s.async = true;
            s.src = "https://call.chatra.io/chatra.js";

            if (d.head) d.head.appendChild(s);
          })(document, window, "Chatra");
        }, 1000)
      }
    </script>
  </body>
</html>
`;

export default ({ label, isOpen = false, user }) => {
  const theme = useTheme();
  let yourAlert = `
      window.userId = "${user?._id}"
      window.email = "${user.emails[0].address}"
    `;

  const initChatra = () => {
    window.ChatraSetup = {
      colors: {
        buttonText: "#FFF" /* chat button text color */,
        buttonBg: theme.colors.primary /* chat button background color */,
      },
      mode: "frame",
      injectTo: "chatra-wrapper",
      clientId: user?._id,
    };
    window.ChatraIntegration = {
      email: user.emails[0].address,
    };
    (function (d, w, c) {
      w.ChatraID = CHATRA_ID;
      var s = d.createElement("script");
      w[c] =
        w[c] ||
        function () {
          (w[c].q = w[c].q || []).push(arguments);
        };
      s.async = true;
      s.src = "https://call.chatra.io/chatra.js";
      if (d.head) d.head.appendChild(s);
    })(document, window, "Chatra");
  };

  React.useEffect(() => {
    if (Platform.OS === "web" && isOpen) {
      initChatra();
    }
  }, [isOpen]);

  if (!user) return null;

  return (
    <Flex w="100%">
      {isAndroid ? (
        <Button
          shadow={60}
          size={50}
          labelProps={{ fontSize: 13 }}
          onPress={() => {
            WebBrowser.openBrowserAsync("https://clouddoku.de");
          }}
          renderLeft={
            <Icon
              mr={8}
              name="message-circle"
              color="#FFF"
              size={label ? 25 : 30}
            />
          }
          rounded
        >
          {label || ""}
        </Button>
      ) : (
        <Tooltip
          backdrop
          onOpen={() => {
            if (Platform.OS === "web") {
              initChatra();
            }
          }}
          h={500}
          w={350}
          gap={0}
          popover={
            <Flex w="100%" h={500}>
              {Platform.OS === "web" ? (
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                  id="chatra-wrapper"
                ></div>
              ) : (
                <KeyboardAvoidingView
                  contentContainerStyle={{ flex: 1 }}
                  behavior={Platform.select({
                    ios: "position",
                    android: null,
                  })}
                  keyboardVerticalOffset={Platform.select({
                    ios: 0,
                    android: 20,
                  })}
                  style={{ flexGrow: 1 }}
                >
                  <WebView
                    javaScriptEnabled={true}
                    domStorageEnabled={true}
                    originWhitelist={["*"]}
                    source={{ html: HTML }}
                    injectedJavaScriptBeforeContentLoaded={yourAlert}
                    style={{ flex: 1, backgroundColor: "transparent" }}
                    onError={(e) => {
                      console.log({ error: e.nativeEvent });
                    }}
                    onLoadEnd={(e) => {
                      console.log("onLoadEnd", e);
                    }}
                    originWhitelist={["*"]}
                  />
                </KeyboardAvoidingView>
              )}
            </Flex>
          }
        >
          <Button
            shadow={60}
            size={50}
            labelProps={{ fontSize: 13 }}
            renderLeft={
              <Icon
                mr={8}
                name="message-circle"
                color="#FFF"
                size={label ? 25 : 30}
              />
            }
            rounded
          >
            {label || ""}
          </Button>
        </Tooltip>
      )}
    </Flex>
  );
};

import * as React from "react";
import { ScrollView } from "react-native";
import { Grid, Chart, Text, Flex, Form, Input, isWeb } from "unikit";

import { Page, share } from "components";
import { useQuery, gql } from "../../apollo";
import { useAppContext } from "../../context";

export default function Stats() {
  const { callMeteor, callMeteorState } = useAppContext();
  const { data } = useQuery(gql`
    query getStats {
      getStats {
        patients
        therapys
        patientsData
        therapysData
      }
    }
  `);
  const { patients, therapys, patientsData, therapysData } =
    data?.getStats || {};
  return (
    <Page title="Analytics" scrollable>
      <Grid gap={10} min={500} maxCols={2} outerGap={15}>
        <Flex bg="surface" shadow={5} borderRadius={5} p={10}>
          <Text font="h5">{`${patients || 0} Patienten`}</Text>

          <ScrollView showsHorizontalScrollIndicator={false} horizontal>
            <Chart
              width={400}
              height={250}
              data={
                patientsData
                  ? patientsData.map((data) => ({
                      label: data.name,
                      value: data.count,
                    }))
                  : []
              }
              yAxis
              yAxisFont="p"
              yTicks={4}
              xAxis
              useScaleBand
            >
              <Chart.Bar
                dataKey="value"
                valueFont="label"
                valueColor="#FFF"
                showValue
              />
            </Chart>
          </ScrollView>
        </Flex>

        <Flex bg="surface" shadow={5} borderRadius={5} p={10}>
          <Text font="h5">{`${therapys || 0} Wunden`}</Text>

          <ScrollView showsHorizontalScrollIndicator={false} horizontal>
            <Chart
              width={800}
              height={250}
              data={
                therapysData
                  ? therapysData.map((data) => ({
                      label: data.name,
                      value: data.count,
                    }))
                  : []
              }
              yAxis
              yTicks={4}
              xAxis
              useScaleBand
            >
              <Chart.Bar
                dataKey="value"
                valueFont="label"
                valueColor="#FFF"
                showValue
              />
            </Chart>
          </ScrollView>
        </Flex>

        <Flex bg="surface" shadow={5} borderRadius={5}>
          <Text p={10} font="h5">{`Daten Export`}</Text>
          <Form
            bg="primary:setAlpha:0.1"
            p={10}
            defaultDoc={{ typename: "Patient", filter: {} }}
            buttonLabel="Export"
            buttonProps={{ loading: callMeteorState.loading, mt: 10 }}
            onSubmit={(doc) => {
              callMeteor({
                variables: {
                  name: "exportCollection",
                  config: doc,
                },
              }).then((result) => {
                if (isWeb) {
                  const encodedUri = encodeURI(
                    "data:text/csv;charset=utf-8," +
                      result.data.callMeteor.result
                  );
                  const link = document.createElement("a");
                  link.setAttribute("href", encodedUri);
                  link.setAttribute("download", `cd-${doc.typename}.csv`);
                  document.body.appendChild(link); // Required for FF
                  link.click();
                } else {
                  share({
                    url: result.data.callMeteor.result,
                    filename: `cd-${doc.typename}.csv`,
                    options: {
                      UTI: ".csv",
                      mimeType: "text/csv",
                    },
                  });
                }
              });
            }}
          >
            <Input
              type="select"
              field="typename"
              label="Typ"
              options={[
                { value: "Contact", label: "Kontakte" },
                { value: "Patient", label: "Patienten" },
              ]}
            />
          </Form>
        </Flex>

        {/* <LineChart
          title={`${therapys || 0} Wunden`}
          data={
            therapysData
              ? therapysData.map(data => ({
                  label: data.name,
                  value: data.count
                }))
              : []
          }
          minBarWidth={80}
          maxBarWidth={80}
          scrollable
        /> */}
      </Grid>
    </Page>
  );
}

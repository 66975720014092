import SimpleSchema, { minWidth } from "../simple";

const schema = {
  surveyDate: {
    type: Date,
    label: "Erhebungsdatum",
    defaultValue: function () {
      return new Date();
    },
    input: "date",
    group: "Allgemeine Erhebungsdaten",
    groupOptions: {
      width: minWidth,
      mode: "grid",
    },
  },
  name: {
    type: String,
    label: "Dokumentenname",
    group: "Allgemeine Erhebungsdaten",
    max: 100,
    defaultValue: (props, state) => {
      return props.count + 1 + ". Arztbrief";
    },
  },
  anamneseId: {
    type: String,
    label: "Anamnese",
    optional: true,
    input: "DocList",
    inputProps: {
      multiple: false,
      typename: "Anamnese",
      skipSearch: false,
      getFilter: ({ doc }) => ({ therapyId: doc.therapyId }),
      title: (item) => `${item.name}`,
    },
    options: [],
    group: "Wund - und Therapieinformationen",
    groupOptions: {
      width: minWidth,
      mode: "grid",
    },
    listedPDF: false,
  },
  therapyRangeStart: {
    type: Date,
    label: "Behandlung seit",
    defaultValue: function () {
      return new Date();
    },
    input: "date",
    group: "Wund - und Therapieinformationen",
  },
  therapyRangeNow: {
    type: Date,
    label: "Behandlung bis",
    defaultValue: function () {
      return new Date();
    },
    input: "date",
    group: "Wund - und Therapieinformationen",
  },
  woundNote: {
    type: String,
    label: "Wundbeschreibung",
    optional: true,
    input: "textarea",
    group: "Wundbeschreibung",
    inputProps: {
      hideLabel: true,
    },
  },
  note: {
    type: String,
    label: "Anmerkung",
    optional: true,
    input: "textarea",
    group: "Anmerkung",
    inputProps: {
      hideLabel: true,
    },
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  patientId: {
    type: String,
    label: "PatientId",
    listed: false,
    defaultValue: (props) => {
      return props.patientId;
    },
  },
  therapyId: {
    type: String,
    label: "TherapyId",
    listed: false,
    defaultValue: (props) => {
      return props.therapyId;
    },
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  canceled: {
    type: Boolean,
    label: "Canceled",
    optional: true,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  DoctorsLetters = new Mongo.Collection("doctorsLetters");
  DoctorsLetters.schema = simpleSchema;
  DoctorsLetters.attachSchema(simpleSchema);
  DoctorsLetters._ensureIndex({ therapyId: 1 });
  DoctorsLetters._ensureIndex({ patientId: 1 });
  DoctorsLetters._ensureIndex({ companyId: 1 });
}

export { simpleSchema as DoctorsLetterSchema };

export default schema;

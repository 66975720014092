import * as React from "react";
import { withThemeProps } from "../../style";

import Flex from "../Flex";
import Text from "../Text";
import Avatar from "../Avatar";
import Icon from "../Icon";

const iconTypes = {
  success: "check",
  error: "x",
  warning: "alert-triangle",
};

const Toast = ({
  theme,
  type = "surface",
  icon = true,
  title,
  message,
  close,
  renderContent,
  closeButton = false,
  ...rest
}) => {
  React.useEffect(() => {
    if (type === "error" || type === "success" || type === "warning") {
      if (theme.onFeedback) theme.onFeedback(type);
    } else {
      if (theme.onFeedback) theme.onFeedback("light");
    }
  }, []);
  return (
    <>
      <Flex
        w="100%"
        position="relative"
        borderRadius={theme.globals.roundness}
        p={theme.spacing.m}
        pt={
          closeButton && ((icon && iconTypes[type]) || (icon !== true && icon))
            ? 32
            : undefined
        }
        bg={type}
        shadow={5}
        justifyContent="space-between"
        pointerEvents="box-none"
        alignItems="center"
      >
        <Flex row position="absolute" left={-10} top={-10}>
          {closeButton === true ? (
            <Avatar
              bg={`${type}:darken:5`}
              borderRadius={15}
              onPress={close}
              size={34}
              shadow={2}
            >
              <Icon
                color={`${type}:darken:35`}
                name="x"
                size={20}
                animate
                delay={300}
                duration={1200}
              />
            </Avatar>
          ) : null}
          {(icon && iconTypes[type]) || (icon !== true && icon) ? (
            <Avatar
              bg="surface"
              borderRadius={15}
              onPress={close}
              size={34}
              ml={-5}
            >
              <Icon
                color={type}
                name={icon === true ? iconTypes[type] : icon}
                size={20}
                animate
                delay={300}
                duration={1200}
              />
            </Avatar>
          ) : null}
        </Flex>
        <Flex>
          <Text colorAware={type}>{message}</Text>
        </Flex>
        {renderContent ? (
          <Flex w="100%" pt={10}>
            {renderContent?.({ close })}
          </Flex>
        ) : null}
      </Flex>
    </>
  );
};

export default withThemeProps(Toast, "Toast");

import * as React from "react";
import { withThemeProps, Touchable } from "../../style";
import { isNumber } from "../../util";

import Flex from "../Flex";

import Text from "../Text";
import Animate from "../Animate";

import TextInput from "./Text";
import Switch from "./Switch";
import Slider from "./Slider";
import Color from "./Color";
import Select from "./Select";
import Number from "./Number";
import Tabs from "./Tabs";
import DatePicker from "./DatePicker";
import File from "./File";
import Tags from "./Tags";

const TYPES = {
  tags: {
    component: Tags,
    props: () => ({}),
    focus: true,
  },
  color: {
    component: TextInput,
    props: ({ size }) => ({
      renderRight: <Color height="65%" minHeight={size * 0.8} mr={-8} />,
    }),
    focus: true,
  },
  date: {
    component: TextInput,
    props: ({ size }) => ({
      renderRight: <DatePicker light size={size * 0.8} mr={-8} />,
      mask: "date",
    }),
    focus: true,
  },
  daterange: {
    component: TextInput,
    props: ({ size }) => ({
      renderRight: <DatePicker light size={size * 0.8} mr={-8} />,
      mask: "date",
    }),
    focus: true,
  },
  timeago: {
    component: TextInput,
    props: ({ size }) => ({
      renderRight: <DatePicker light size={size * 0.8} mr={-8} />,
      mask: "timeago",
      editable: false,
    }),
    focus: true,
  },
  datetime: {
    component: TextInput,
    props: ({ size }) => ({
      renderRight: <DatePicker light size={size * 0.8} mr={-5} time />,
      mask: "datetime",
    }),
    focus: true,
  },
  time: {
    component: TextInput,
    props: () => ({
      mask: "time",
    }),
    focus: true,
  },
  phone: {
    component: TextInput,
    props: () => ({
      mask: "phone",
    }),
    focus: true,
  },
  tabs: {
    component: Tabs,
    props: () => ({
      size: 40,
    }),
  },
  number: {
    component: Number,
    props: () => ({
      mask: "number",
    }),
    focus: true,
  },
  password: {
    component: TextInput,
    props: () => ({
      textContentType: "password",
      secureTextEntry: true,
    }),
    focus: true,
  },
  text: { component: TextInput, focus: true },
  textarea: {
    component: TextInput,
    props: () => ({
      multiline: true,
      numberOfLines: 3,
    }),
    focus: true,
  },
  switch: {
    component: Switch,
    onChange: ({ value, onChange }) => {
      onChange(!value);
    },
    props: () => ({
      mr: 15,
    }),
  },
  range: {
    component: Slider,
    props: ({ trackColor = "input" }) => ({
      trackColor: `${trackColor}:darken:5`,
    }),
  },
  select: {
    component: Select,
    inline: false,
    props: ({ picker, pickerProps }) =>
      (picker = "switch"
        ? {
            pickerProps: {
              trackColor: `${pickerProps?.trackColor || "input"}:darken:5`,
            },
          }
        : {}),
  },
  file: {
    component: File,
    inline: false,
  },
};

interface Props {
  theme: object;
  children?: React.ReactNode;
  type?: string;
  labelPosition?: "top" | "left";
  label?: string;
  inputProps?: object;
  roundness: number;
  clean?: boolean;
  shadow?: number;
  animationProps?: object;
}

const needsBg = {
  text: true,
  date: true,
  select: true,
  phone: true,
  number: true,
  textarea: true,
  password: true,
  timeago: true,
  time: true,
  datetime: true,
};

const needsAutoHeight = {
  textarea: true,
  file: true,
  select: true,
};

const Input = React.memo(
  ({
    value,
    labelPosition = "top",
    size = 55,
    error = false,
    theme,
    children,
    type = "text",
    indicatorFocusColor = "primary",
    indicatorBlurColor,
    indicatorSize = 2,
    justifyContent,
    label,
    showLabel = true,
    roundness,
    animationProps = {}, //{ from: { o: 0 }, to: { o: 1 } },
    shadow,
    icon,
    iconSize,
    onChange,
    field,
    labelProps = {},
    inputWrapperProps = {},
    bg,
    style,
    mt,
    mr,
    ml,
    mb,
    row,
    ...rest
  }: Props) => {
    const [focused, setFocus] = React.useState(false);
    const TYPE = TYPES[type] || TYPES["text"];
    const Comp = TYPE ? TYPE.component : null;

    if (type === "switch") {
      labelPosition = "left";
      labelProps = {
        font: "default",
      };
      bg = "input";
      justifyContent = "center";
    }

    const radius = isNumber(roundness) ? roundness : theme.globals.roundness;
    const labelPositionStyle =
      labelPosition === "top"
        ? { pb: 5 }
        : {
            pl: 15,
            pr: 15,
            height: size,
            zIndex: 100,
            center: true,
          };

    const inputProps = {
      value,
      onChange,
      field,
      ...(TYPE && TYPE.props ? TYPE.props({ ...rest, size }) : {}),
      setFocus,
      position: "relative",
      row,
      zIndex: type === "range" ? 999 : 0,
      ...rest,
      width: "100%",
      roundness: type === "switch" ? undefined : radius,
      size: type === "switch" ? size * 0.66 : size,
      bg: needsBg[type] ? bg || "input" : undefined,
      textAlign: labelPosition === "top" ? undefined : "right",
    };

    const WrapComp = TYPE.onChange ? Touchable : Flex;

    return (
      <Flex
        w="100%"
        mb={mb}
        mt={mt}
        {...rest}
        justifyContent={"space-between"}
        borderRadius={radius}
        relative
      >
        {label && showLabel === true ? (
          <Flex pointerEvents="none" pb={5}>
            <Text {...labelProps} font="label">
              {label}
            </Text>
          </Flex>
        ) : null}

        <WrapComp
          h={children || needsAutoHeight[type] ? "auto" : size}
          borderRadius={radius}
          justifyContent={labelPosition !== "top" ? "space-between" : "center"}
          alignItems={justifyContent}
          relative
          row={labelPosition !== "top"}
          bg={
            (needsBg[type] && labelPosition !== "top") ||
            (bg && labelPosition !== "top")
              ? bg
              : undefined
          }
          {...(TYPE.onChange
            ? {
                onPress: () => {
                  TYPE.onChange?.({ value, onChange });
                },
              }
            : {})}
          {...inputWrapperProps}
        >
          {label && labelPosition !== "top" ? (
            <Flex pointerEvents="none" row {...labelPositionStyle}>
              <Text>{label}</Text>
            </Flex>
          ) : null}
          <Animate
            visible={focused}
            from={{ scale: 0.9, opacity: 0 }}
            to={{ scale: 1, opacity: 1 }}
            bg="primary"
            borderRadius={radius + indicatorSize}
            l={-indicatorSize}
            t={-indicatorSize}
            r={-indicatorSize}
            b={-indicatorSize}
            //duration={500}
            absolute
          />
          {children ? children : <Comp {...inputProps} />}
        </WrapComp>
      </Flex>
    );
  },
  (prevProps, nextProps) => {
    if (nextProps.type === "select" && nextProps.multi) {
      return false;
    } else if (
      JSON.stringify(prevProps.value) !== JSON.stringify(nextProps.value)
    ) {
      return false;
    } else if (prevProps.needsDoc || nextProps.needsDoc) {
      return false;
    } else if (prevProps.children) {
      return false;
    } else if (prevProps.renderRight !== nextProps.renderRight) {
      return false;
    } else {
      return true;
    }
  }
);

export default withThemeProps(Input, "Input");

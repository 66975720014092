import * as React from "react";
import { Flex, useDebounce, TextInput, Icon } from "unikit";
import { Page, ListQuery, ListItem } from "components";
import dayjs from "dayjs";
import i18n from "../../i18n";

const types = {
  Patient: {
    filter: (string) => ({
      $or: [
        {
          $expr: {
            $regexMatch: {
              input: { $concat: ["$firstName", " ", "$lastName"] },
              regex: `.*${string}.*`, //Your text search here
              options: "i",
            },
          },
        },
        {
          $expr: {
            $regexMatch: {
              input: { $concat: ["$lastName", " ", "$firstName"] },
              regex: `.*${string}.*`, //Your text search here
              options: "i",
            },
          },
        },
        { insurer: { $regex: `.*${string}.*`, $options: "i" } },
      ],
    }),
    name: "patients",
    groupBy: "lastName",
    groupByFormat: ({ key, value }) => value.toUpperCase().charAt(0),
    title: (item) => `${item.lastName}, ${item.firstName}`,
    desc: (item) =>
      `Bearbeitet am ${dayjs(item.updatedAt).format("DD. MMMM YYYY")}`,
    pills: (item) => (item.archived === true ? [{ label: "Archiv" }] : []),
  },
  Contact: {
    filter: (string, filter) => ({
      $or: [
        { name: { $regex: `.*${string}.*`, $options: "i" } },
        { email: { $regex: `.*${string}.*`, $options: "i" } },
      ],
      ...filter,
    }),
    name: "contacts",
    sort: { name: 1 },
    groupBy: "typ",
    groupByFormat: undefined,
    title: (item) => `${item.name}`,
    desc: (item) =>
      `Bearbeitet am ${dayjs(item.updatedAt).format("DD. MMMM YYYY")} ${
        item?.email
      }`,
  },
  User: {
    filter: (string) => ({
      $or: [
        { "emails.0.address": { $regex: `.*${string}.*`, $options: "i" } },
        { company: { $regex: `.*${string}.*`, $options: "i" } },
      ],
    }),
    name: "users",
    groupBy: "company",
    groupByFormat: undefined,
    title: (item) => `${item.emails?.[0]?.address}`,
    desc: (item) => `${item.company}`,
  },
  Material: {
    filter: (string, filter) => ({
      $or: [
        { name: { $regex: `.*${string}.*`, $options: "i" } },
        { producer: { $regex: `.*${string}.*`, $options: "i" } },
        { category: { $regex: `.*${string}.*`, $options: "i" } },
        { materialNumber: { $regex: `.*${string}.*`, $options: "i" } },
      ],
      ...filter,
    }),
    name: "materials",
    groupBy: "name",
    groupByFormat: undefined,
    title: (item) =>
      `Größe: ${item.size}, Verpackungseinheit: ${item.packagingUnit}`,
    desc: (item) => `Hersteller: ${item.producer}, PZN: ${item.materialNumber}`,
  },
  MaterialGroup: {
    filter: (string, filter) => ({
      $or: [
        { name: { $regex: `.*${string}.*`, $options: "i" }, ...filter },
        { producer: { $regex: `.*${string}.*`, $options: "i" }, ...filter },
        { category: { $regex: `.*${string}.*`, $options: "i" }, ...filter },
        {
          materialNumber: { $regex: `.*${string}.*`, $options: "i" },
          ...filter,
        },
      ],
    }),
    name: "materialgroups",
    groupBy: "category",
    groupByFormat: undefined,
    title: (item) => `${item.name}`,
    desc: (item) => `Hersteller: ${item.producer}`,
  },
  Appointment: {
    filter: (string) => ({
      patient: { $regex: `.*${string}.*`, $options: "i" },
    }),
    sort: { datum: -1 },
    name: "appointments",
    groupBy: "datum",
    groupByFormat: ({ key, value }) => dayjs(value).format("MMMM YYYY"),
    title: (item) =>
      `${dayjs(item.datum).format("DD. MMMM YYYY")}, ${item.patient}`,
    desc: (item) =>
      `Bearbeitet am ${dayjs(item.updatedAt).format("DD. MMMM YYYY")}`,
  },
  Share: {
    filter: (string, filter) => ({
      patientName: { $regex: `.*${string}.*`, $options: "i" },
      ...filter,
    }),
    sort: { createdAt: -1 },
    name: "shares",
    groupBy: "createdAt",
    groupByFormat: ({ key, value, item }) =>
      `${dayjs(value).format("MMM YYYY")} `,
    title: (item) =>
      `${item.documents?.length} Dokumente von ${item?.patientName}`,
    desc: (item) =>
      `Geteilt am ${dayjs(item.createdAt).format("DD.MM.YYYY")} von ${
        item?.user?.lastName
      }, ${item?.user?.firstName} (${item?.user?.company}) ${
        item.shareEnd
          ? `- Geteilt bis ${dayjs(item.shareEnd).format("DD.MM.YYYY")}`
          : ""
      }`,
  },
  Order: {
    filter: (string) => ({
      name: { $regex: `.*${string}.*`, $options: "i" },
    }),
    sort: { datum: -1 },
    name: "orders",
    groupBy: "surveyDate",
    groupByFormat: ({ key, value }) => dayjs(value).format("MMMM YYYY"),
    title: (item) => `${item.name}`,
    desc: (item) =>
      `Bearbeitet am ${dayjs(item.updatedAt).format("DD. MMMM YYYY")}`,
  },
  default: {
    filter: (string, filter) => ({
      name: { $regex: `.*${string}.*`, $options: "i" },
      ...filter,
    }),
    sort: { surveyDate: -1 },
    title: (item) => `${item.name}`,
    desc: (item) =>
      `Bearbeitet am ${dayjs(item.surveyDate).format("DD. MMMM YYYY")}`,
  },
};

function Screen({ route, navigation }) {
  const { typename, onSelect, filter, skip = true } = route.params || {};

  const [string, setString] = React.useState("");
  const debouncedString = useDebounce(string, 500);

  const SearchType = types[typename] || types.default;

  console.log({ SearchType, typename });

  const ListHeaderComponent = React.useMemo(
    () => (
      <Flex w="100%" h={50}>
        <TextInput
          renderLeft={<Icon name="search" pl={25} size={25} />}
          value={string}
          onChange={(text) => {
            setString(text);
          }}
          bg="surface"
          style={{
            height: 50,
            outline: "none",
          }}
          placeholder={i18n.t("search")}
          autoCorrect={false}
          autoFocus
        />
      </Flex>
    ),
    [string, setString]
  );

  return (
    <Page
      title={`${i18n.t(`types.${typename?.toLowerCase()}`)} suchen`}
      goBack={navigation.goBack}
      useFlatList={true}
      scrollComponent={ListQuery}
      modal={true}
      navbarProps={{
        footerComponent: ListHeaderComponent,
      }}
      scrollProps={{
        ...SearchType,
        limit: 50,
        typename,
        skip: skip ? debouncedString?.length < 3 : false,
        filter: SearchType.filter(debouncedString, filter || {}),
        sort: SearchType.sort || undefined,

        renderItem: ({ item, index }) => {
          return (
            <ListItem
              title={SearchType.title(item)}
              desc={SearchType.desc(item)}
              pills={SearchType.pills ? SearchType.pills(item) : undefined}
              users={item.users}
              onPress={() => {
                if (onSelect) {
                  onSelect(item, navigation.goBack);
                } else {
                  if (typename === "Patient") {
                    navigation.navigate("PatientProfile", {
                      _id: item._id,
                      title: types[typename].title(item),
                    });
                  } else if (typename === "Share") {
                    navigation.navigate("ShareProfile", {
                      ...item,
                    });
                  } else {
                    navigation.navigate("Form", {
                      title: `${i18n.t(
                        `types.${typename?.toLowerCase()}_plural`
                      )} bearbeiten`,
                      defaultDoc: item,
                      id: item._id,
                      typename,
                      mode: "update",
                    });
                  }
                }
              }}
            />
          );
        },
      }}
    />
  );
}

export default Screen;

import SimpleSchema, { minWidth } from "./simple";

const schema = {
  // username: {
  //   type: String,
  //   regEx: /^[a-z0-9A-Z_]{3,15}$/,
  //   optional: true,
  //   listed: false
  // },
  emails: {
    type: Array,
    label: "E-Mail",
    group: "Allgemein",
    defaultValue: [],
    groupOptions: {
      width: minWidth,
      mode: "masonry",
    },
  },
  "emails.$": {
    type: Object,
  },
  "emails.$.address": {
    optional: true,
    type: String,
    regEx: SimpleSchema.RegEx.Email,
    listed: false,
  },
  "emails.$.verified": {
    optional: true,
    type: Boolean,
    listed: false,
  },
  emailVerified: {
    optional: true,
    type: Boolean,
    listed: false,
  },
  createdAt: {
    type: Date,
    label: "Created At",
    optional: true,
    listed: false,
  },
  firstName: {
    type: String,
    label: "Vorname",
    group: "Allgemein",
    optional: true,
    autoValue: function () {
      if (typeof Meteor !== "undefined" && !this.isSet && this.isInsert) {
        return "-";
      }
    },
  },
  lastName: {
    type: String,
    label: "Nachname",
    group: "Allgemein",
    optional: true,
    autoValue: function () {
      if (typeof Meteor !== "undefined" && !this.isSet && this.isInsert) {
        return "-";
      }
    },
  },
  company: {
    type: String,
    label: "Firma",
    optional: true,
    group: "Praxis",
    roles: ["admin", "client"],
    groupOptions: {
      width: minWidth,
      mode: "grid",
    },
  },
  companyId: {
    type: String,
    label: "Firma",
    optional: true,
    listed: false,
    autoValue: function () {
      if (typeof Meteor !== "undefined" && !this.isSet && this.isInsert) {
        return Random.id();
      }
    },
  },
  companyLogoFileId: {
    type: String,
    label: "Logo",
    optional: true,
    group: "Praxis",
    roles: ["admin", "client"],
    input: "File",
    multiple: false,
  },
  userPictureFileId: {
    type: String,
    label: "User Image",
    optional: true,
    group: "Allgemein",
    input: "File",
    multiple: false,
  },
  address: {
    type: String,
    label: "Adresse",
    optional: true,
    group: "Praxis",
    roles: ["admin", "client"],
  },
  zipCode: {
    type: String,
    label: "Postleitzahl",
    max: 50,
    optional: true,
    group: "Praxis",
    roles: ["admin", "client"],
  },
  residence: {
    type: String,
    label: "Wohnort",
    max: 50,
    optional: true,
    group: "Praxis",
    roles: ["admin", "client"],
  },
  phone: {
    type: String,
    label: "Telefon",
    max: 50,
    optional: true,
    group: "Praxis",
    roles: ["admin", "client"],
  },
  mobil: {
    type: String,
    label: "Handy",
    max: 50,
    optional: true,
    group: "Allgemein",
    roles: ["admin", "client", "employee"],
  },
  website: {
    type: String,
    label: "Website",
    max: 50,
    optional: true,
    group: "Praxis",
    roles: ["client"],
  },
  employeeCount: {
    type: Number,
    label: "Anzahl Mitarbeiter",
    optional: true,
    group: "Einstellungen",
    input: "number",
    roles: ["admin"],
    groupOptions: {
      width: minWidth,
      mode: "grid",
    },
  },
  banned: {
    type: Boolean,
    label: "Account sperren?",
    optional: true,
    group: "Allgemein",
    roles: ["employee", "admin"],
    input: "switch",
    defaultValue: false,
  },
  joined: {
    type: Date,
    label: "Vertragsunterschiftsdatum",
    input: "date",
    optional: true,
    roles: ["admin"],
    group: "Einstellungen",
  },
  createdBy: {
    type: String,
    optional: true,
    listed: false,
  },
  color: {
    type: String,
    label: "Farbe",
    optional: true,
    defaultValue: "#7D4CDB",
    input: "color",
    group: "Allgemein",
  },
  language: {
    type: String,
    label: "Sprache",
    allowedValues: ["de_DE", "en_US"],
    optional: true,
    group: "Allgemein",
    input: "select",
    options: [
      {
        label: "Deutsch",
        value: "de_DE",
      },
      {
        label: "Englisch",
        value: "en_US",
      },
    ],
  },
  docLock: {
    type: String,
    label: "Dokumente sperren",
    allowedValues: ["day", "week", "month", "never"],
    optional: true,
    group: "Dokumente",
    roles: ["admin", "client"],
    input: "select",
    placeholder: "Bitte wählen",
    options: [
      {
        label: "Tag",
        value: "day",
      },
      {
        label: "Woche",
        value: "week",
      },
      {
        label: "Monat",
        value: "month",
      },
      {
        label: "Nie",
        value: "never",
      },
    ],
  },
  country: {
    label: "Land",
    type: String,
    optional: true,
    group: "Praxis",
    roles: ["admin", "client"],
  },
  recipeText: {
    type: String,
    label: "Rezeptanforderung",
    optional: true,
    group: "Dokumente",
    roles: ["admin", "client"],
    input: "TextEditor",
    defaultValue:
      "<h1>Sehr geehrter Dr., liebes Praxisteam,</h1><p>ihr Patient (siehe Stammdaten), befindet sich bei uns in professioneller Wundbehandlung. Die Dokumentation der Wunde ist bei Bedarf in unserer Clouddoku einzusehen. Bitte sprechen Sie uns hierzu an. Für die weitere Wundbehandlung und Versorgungspe- riode erbitten wir die Rezeptierung der folgenden Verbandsmaterialien unter Angabe der Diagnose:</p>",
  },
  informedConsentText: {
    type: String,
    label: "Einverständiserklärung",
    optional: true,
    group: "Dokumente",
    roles: ["admin", "client"],
    input: "TextEditor",
    defaultValue:
      "<h1>Aufklärung & Einverständniserklärung zur Erhebung/Übermittlung von Patientendaten gem. § 73 Abs. 1 b SGB V</h1><p>Ich erkläre mich einverstanden, dass mein behandelnder Arzt meine Behandlungsdaten und Befunde zum Zweck der beim Hausarzt zu führenden Dokumentation und der weiteren Behandlung an meinen Hausarzt übermittelt.</p><br /><p>Der mich behandelnde Arzt bei meinem Hausarzt oder anderen Ärzten oder Leistungserbringern die für meine Behandlung erforderlichen Behandlungsdaten und Befunde erhebt und für die Zwecke der von meinem behandelnden Arzt zu erbringenden ärztlichen Leistungen verarbeitet und nutzt.</p><br /><p>Grundsätzlich ist unser Service, bestehend aus Wundbehandlung, Verbandswechseln und Anfahrtswegen, für Sie kostenlos. Trotzdem kann es dazu kommen, dass wir gesetzliche Zuzahlungsleistungen für die Krankenkassen (Rezeptgebühren) oder bestimmte Zusatzleistungen in Rechnung stellen müssen: - Wir sind dazu verpflichtet, für verschriebene Materialien eine Rezeptgebühr zu berechnen. Diese wird von uns zu 100% an die Krankenkassen weitergeleitet. Von dieser Gebühr können Sie sich bei Ihrer Krankenkasse befreien lassen, bitte Fragen Sie uns für weitere Informationen. - Wichtige zusätzliche Materialien, welche die Krankenkassen grundsätzlich nicht übernehmen wie Wundspüllösung (18€), Wundgel (25€), ZincCream (13,50€), NutrientCream (23,50€) oder eine Lasertherapie.</p>",
  },
  adviceSignatureNote: {
    type: String,
    label: "Therapieempfehlung Unterschrift-Hinweis",
    optional: true,
    group: "Dokumente",
    roles: ["admin", "client"],
    input: "TextEditor",
  },
  deliveryNoteSignatureLabel: {
    type: String,
    label: "Lieferschein Unterschrift-Label",
    group: "Dokumente",
    roles: ["admin", "client"],
    optional: true,
    defaultValue: "Lieferdatum _______________",
  },
  adminNote: {
    type: String,
    label: "Admin Anmerkung",
    group: "Allgemein",
    roles: ["admin"],
    optional: true,
  },
  globalInfo: {
    type: String,
    label: "Global Info Banner",
    listed: false,
    optional: true,
  },
  materialList: {
    type: Boolean,
    label: "MaterialList",
    optional: true,
    listed: false,
  },
  globalMaterials: {
    type: Boolean,
    label: "Globale Materialien",
    optional: true,
    group: "Einstellungen",
    roles: ["admin"],
    input: "switch",
  },
  warehouse: {
    type: Boolean,
    label: "Lager",
    optional: true,
    group: "Einstellungen",
    roles: ["admin"],
    input: "switch",
  },
  warehouseStartNumber: {
    type: Number,
    label: "Bestellnummer Warenwirtschaft (wird hochgezählt)",
    optional: true,
    group: "Praxis",
    roles: ["client"],
    input: "number",
  },
  warehouseDeliveryAddress: {
    type: String,
    label: "Lieferadresse Lager falls abweichend",
    group: "Praxis",
    input: "Textarea",
    roles: ["admin"],
    optional: true,
  },
  warehouseMaterials: {
    type: Object,
    optional: true,
    listed: false,
    blackbox: true,
  },
  lastLogin: {
    type: Date,
    label: "Last Login",
    optional: true,
    listed: false,
  },
  services: {
    type: Object,
    optional: true,
    blackbox: true,
    listed: false,
  },
  roles: {
    type: Array,
    optional: true,
    listed: false,
  },
  "roles.$": {
    type: String,
  },
  twoFactorCode: {
    type: String,
    label: "twoFactorCode",
    optional: true,
    listed: false,
  },
  pushTokens: {
    type: Array,
    optional: true,
    listed: false,
  },
  "pushTokens.$": {
    type: String,
    optional: true,
  },
  unseenChats: {
    type: Number,
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Meteor.users.schema = simpleSchema;
  Meteor.users.attachSchema(simpleSchema);
}

export { simpleSchema as UserSchema };

export default schema;

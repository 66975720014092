import dayjs from "dayjs";

var colors = [
  "#000000",
  "#fdc02f",
  "#f1453d",
  "#1fbcd2",
  "#009688",
  "#8bc34a",
  "#ffeb3b",
  "#ff9800",
  "#795548",
  "#607d8b",
];

export const getDocLock = (lock, date) => {
  var diff = dayjs().diff(date, "days");
  console.log("getDocLock", { diff, lock, date });
  if (lock === "day" && diff > 1) {
    return false;
  }
  if (lock === "week" && diff > 7) {
    return false;
  }
  if (lock === "month" && diff > 30) {
    return false;
  }
  return true;
};

const values = { none: 0, ease: 1, moderate: 2, strong: 3 };

export const getWoundSurface = (anamnesen = []) => {
  const data = [];
  const keys = {
    nekrose: "#000000",
    fibrin: "#fdc02f",
    granulation: "#f1453d",
    epithelision: "#1fbcd2",
  };
  anamnesen
    ?.filter((a) => a.canceled !== true)
    .map((item) => {
      if (
        item?.nekrose ||
        item?.fibrin ||
        item?.granulation ||
        item?.epithelision
      ) {
        data.push({
          label: dayjs(item?.surveyDate).format("DD.MM.YYYY"),
          nekrose: item?.nekrose || 0,
          fibrin: item?.fibrin || 0,
          granulation: item?.granulation || 0,
          epithelision: item?.epithelision || 0,
        });
      }
      // Object.keys(keys).map((key) => {
      //   data.push({
      //     label: dayjs(item.surveyDate).format("DD.MM.YYYY"),
      //     value: item[key] || 0,
      //     color: keys[key],
      //     key: key,
      //   });
      // });
    });
  return data.reverse();
};

export const getWoundSurrounding = (anamnesen = []) => {
  const data = [];
  const keys = {
    macerationSurroundingGrad: "#000000",
    rednessSurroundingGrad: "#fdc02f",
    xeroticSurroundingGrad: "#f1453d",
    lividSurroundingGrad: "#1fbcd2",
    atrophSurroundingGrad: "#1fbcd2",
    infectionSurroundingGrad: "#1fbcd2",
    mycosisSurroundingGrad: "#1fbcd2",
    edematousSurroundingGrad: "#1fbcd2",
    pruritusSurroundingGrad: "#1fbcd2",
  };
  anamnesen
    ?.filter((a) => a.canceled !== true)
    .map((item) => {
      if (
        item?.macerationSurroundingGrad ||
        item?.rednessSurroundingGrad ||
        item?.xeroticSurroundingGrad ||
        item?.lividSurroundingGrad
      ) {
        data.push({
          label: dayjs(item.surveyDate).format("DD.MM.YYYY"),
          macerationSurroundingGrad:
            values[item.macerationSurroundingGrad] || 0,
          rednessSurroundingGrad: values[item.rednessSurroundingGrad] || 0,
          xeroticSurroundingGrad: values[item.xeroticSurroundingGrad] || 0,
          lividSurroundingGrad: values[item.lividSurroundingGrad] || 0,
        });
      }
      // Object.keys(keys).map((key, index) => {
      //   if (item[key]) {
      //     data.push({
      //       label: dayjs(item.surveyDate).format("DD.MM.YYYY"),
      //       value: values[item[key]],
      //       color: colors[index],
      //       key: key,
      //     });
      //   }
      // });
    });
  return data.reverse();
};

export const getDermicState = (anamnesen = []) => {
  const data = [];
  const keys = {
    macerationDermicStateGrad: "#000000",
    rednessDermicStateGrad: "#fdc02f",
    xeroticDermicStateGrad: "#f1453d",
    pruritusDermicStateGrad: "#1fbcd2",
  };
  anamnesen
    ?.filter((a) => a.canceled !== true)
    .map((item) => {
      Object.keys(keys).map((key, index) => {
        if (item[key]) {
          data.push({
            label: dayjs(item.surveyDate).format("DD.MM.YYYY"),
            value: values[item[key]],
            color: colors[index],
            key: key,
          });
        }
      });
    });
  return data.reverse();
};

export const getMacerationEdging = (anamnesen = []) => {
  const data = [];
  const keys = {
    macerationEdgingGrad: "#000000",
    rednessEdgingGrad: "#fdc02f",
    xeroticEdgingGrad: "#f1453d",
    lividEdgingGrad: "#1fbcd2",
    atrophEdgingGrad: "#000000",
    edematousEdgingGrad: "#fdc02f",
  };
  anamnesen
    ?.filter((a) => a.canceled !== true)
    .map((item) => {
      Object.keys(keys).map((key, index) => {
        if (item[key]) {
          data.push({
            label: dayjs(item.surveyDate).format("DD.MM.YYYY"),
            value: values[item[key]],
            color: colors[index],
            key: key,
          });
        }
      });
    });
  return data.reverse();
};

export const getWoundSize = (woundImages = []) => {
  const data = [];
  woundImages
    ?.filter((a) => a.canceled !== true)
    .map((item) => {
      data.push({
        label: dayjs(item.surveyDate).format("DD.MM.YYYY") || "-",
        value: item.surface ? parseFloat(item.surface) : 0,
      });
    });

  return data.reverse();
};

export function isNumber(value) {
  if (value === 0) return true;
  return isFinite(value);
}

export const getCutIntensity = (anamnesen = []) => {
  const data = [];
  const keys = {
    one: { value: 1, color: "#c7d961" },
    two: { value: 2, color: "#dfd138" },
    three: { value: 3, color: "#ffcb4e" },
    four: { value: 4, color: "#fdb300" },
    five: { value: 5, color: "#f79934" },
    six: { value: 6, color: "#ec6830" },
    seven: { value: 7, color: "#d14a1c" },
    eight: { value: 8, color: "#d1361c" },
    nine: { value: 9, color: "#db0018" },
    ten: { value: 10, color: "#bb0000" },
  };
  anamnesen
    ?.filter((a) => a.canceled !== true)
    .map((item) => {
      data.push({
        label: dayjs(item.surveyDate).format("DD.MM.YYYY"),
        value:
          keys[item.cutIntensity] && isNumber(keys[item.cutIntensity].value)
            ? keys[item.cutIntensity].value
            : 0,
      });
    });
  return data.reverse();
};

export const getObjValue = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];

    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export const getTherapyName = (therapy) => {
  var name = "";
  if (therapy.ulcusCruris) {
    name += "UlcusCruris";
    if (therapy.venosum) {
      name += " Venosum";
    }
    if (therapy.arteriosum) {
      name += " Arteriosum";
    }
    if (therapy.mixtum) {
      name += " Mixtum";
    }
  }
  if (therapy.decubitus) {
    name += "Decubitus";
  }
  if (therapy.secondaryWoundHealing) {
    name += "Sekundäre Wundheilung";
  }
  if (therapy.diabeticFoot) {
    name += "Diabetischer Fuß";
  }
  if (therapy.traumatic) {
    name += "Traumatisch";
  }
  if (therapy.TUWound) {
    name += "TU Wunde";
  }
  if (therapy.burn) {
    name += "Thermische Wunde / Verbrennung";
  }
  if (therapy.woundTypeOther) {
    if (therapy.woundTypeOtherName) {
      name += therapy.woundTypeOtherName;
    } else {
      name += "Andere";
    }
  }
  if (therapy.woundLocalisation) {
    name += " - " + therapy.woundLocalisation;
  }
  return name;
};

import * as React from "react";
import { Touchable, Flex, Text, Avatar, Icon } from "unikit";

import { renderAction } from "../page";
import ImageModal from "../imagemodal";

export default ({
  onPress,
  icons,
  title,
  titleSize,
  desc,
  note,
  users,
  actions = [],
  actionType = "tooltip",
  actionPosition = "right",
  onActionPressProps,
  border = true,
  renderLeft,
  renderRight,
  image,
  pills,
  wrapperProps = {},
  noteProps = {},
  canceled,
  footerComponent,
  ...rest
}) => {
  const Comp = onPress ? Touchable : Flex;

  return (
    <>
      <Flex
        w="100%"
        px={border ? 0 : 0}
        mt={border ? 2.5 : 0}
        mb={border && !note ? 2.5 : 0}
        {...wrapperProps}
      >
        <Comp
          onPress={() => onPress?.()}
          w="100%"
          h={60}
          borderRadius={border ? 10 : 0}
          bg={border ? "surface" : "transparent"}
          alignItems="center"
          justifyContent="space-between"
          row
          relative
          opacity={canceled ? 0.4 : 1}
          {...rest}
        >
          <Flex flex={1} alignItems="center" h="100%" row>
            {renderLeft}
            {image ? (
              <Flex w={60} h={rest.h || 60} relative>
                <ImageModal
                  source={image}
                  modalImageResizeMode="contain"
                  resizeMode="cover"
                  style={{
                    width: 60,
                    height: rest.h || 60,
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                  }}
                />
                <Icon
                  name="zoom-in"
                  size={24}
                  absolute
                  l={17}
                  t={17}
                  color="#FFF"
                  pointerEvents="none"
                />
              </Flex>
            ) : null}
            {icons ? (
              <Flex pl={15} row>
                {icons?.map((icon) =>
                  renderAction({
                    icon: icon.name,
                    iconLabel: icon.iconLabel,
                    size: 38,
                    bg: `${icon?.color || "primary"}:setAlpha:0.1`,
                    color: icon?.color || "primary",
                    ...icon,
                  })
                )}
              </Flex>
            ) : null}
            {users ? (
              <Flex
                pl={icons?.length > 0 ? 0 : 15}
                ml={icons?.length > 0 ? -8 : 0}
                row
              >
                {users?.map((user, i) => {
                  return (
                    <Flex key={`user-${i}`}>
                      <Avatar
                        bg={user.color || "primary"}
                        borderWidth={2}
                        borderColor="surface:darken:5"
                        ml={i > 0 ? -10 : 0}
                        source={
                          user?.image ? { uri: user.image.url } : undefined
                        }
                        size={36}
                        char={`${user?.firstName?.charAt(
                          0
                        )}${user?.lastName?.charAt(0)}`}
                      />
                    </Flex>
                  );
                })}
              </Flex>
            ) : null}
            <Flex flex={1} px={users ? 10 : renderLeft ? 5 : 15}>
              <Flex maxWidth="80%" row>
                <Text font={titleSize} numberOfLines={1}>
                  {title}
                </Text>
                {pills ? (
                  <Flex ml={10} row>
                    {pills?.map((pill, i) => (
                      <Flex
                        bg={pill?.bg || "primary"}
                        borderRadius={15}
                        px={10}
                        h={20}
                        center
                      >
                        <Text font="label" color={pill?.color || "#FFF"}>
                          {pill.label}
                        </Text>
                      </Flex>
                    ))}
                  </Flex>
                ) : null}
              </Flex>
              {desc ? <Text font="label">{desc}</Text> : null}
            </Flex>
          </Flex>

          <Flex row mr={15} alignItems="center">
            {renderRight}
            {actions?.length > 0 && actionType === "tooltip"
              ? renderAction({
                  actions,
                  actionType,
                  onActionPressProps,
                  icon: "more-vertical",
                })
              : null}
            {actions?.length > 0 &&
            actionType === "inline" &&
            actionPosition === "right" ? (
              <Flex mr={onPress && border === true ? 0 : 10} row>
                {actions.map((action, i) =>
                  renderAction(action, i, onActionPressProps)
                )}
              </Flex>
            ) : null}
            {/* {onPress && border === true ? (
            <Icon name="chevron-right" mr={10} color="primary:setAlpha:0.5" />
          ) : null} */}
          </Flex>
        </Comp>
        {canceled ? (
          <Flex absoluteFill center>
            <Text fontSize={30} color="text:setAlpha:0.4" bold>
              STORNIERT
            </Text>
          </Flex>
        ) : null}
      </Flex>

      {note && note?.length > 0 ? (
        <Flex
          bg="surface:setAlpha:0.5"
          borderBottomLeftRadius={border ? 10 : 0}
          borderBottomRightRadius={border ? 10 : 0}
          px={15}
          pt={13}
          pb={10}
          mt={-7}
          mb={border ? 2.5 : 0}
          row
          {...noteProps}
        >
          {noteProps?.icon ? (
            <Icon name={noteProps.icon} size={20} mr={10} />
          ) : null}
          <Text {...(noteProps?.labelProps || {})}>{note}</Text>
        </Flex>
      ) : null}
      {footerComponent ? (
        <Flex
          bg="surface:setAlpha:0.5"
          borderBottomLeftRadius={border ? 10 : 0}
          borderBottomRightRadius={border ? 10 : 0}
          px={15}
          pt={13}
          pb={10}
          mt={-7}
          mb={border ? 2.5 : 0}
          row
          {...noteProps}
        >
          {footerComponent}
        </Flex>
      ) : null}
      {actions?.length > 0 &&
      actionType === "inline" &&
      actionPosition === "bottom" ? (
        <Flex mt={5} w="100%" row wrap>
          {actions.map((action, i) =>
            renderAction(
              { ...action, mb: 5, ml: 0, mr: 2.5 },
              i,
              onActionPressProps
            )
          )}
        </Flex>
      ) : null}
    </>
  );
};

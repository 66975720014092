import * as React from "react";
import { useNavigation } from "@react-navigation/native";

import dayjs from "dayjs";
import "dayjs/locale/de";
dayjs.locale("de");

import { Icon, Tooltip, Flex, Text } from "unikit";
import { useAppContext } from "../context";
import { ListItem } from "./list";

export default () => {
  const navigation = useNavigation();
  const { sharing, setSharing } = useAppContext();

  if (!sharing?.count > 0) return null;

  return (
    <Flex l={0} r={0} b={0} absolute center pointerEvents="box-none">
      <Tooltip
        color="rgba(255,255,255,0.8)"
        shadow={50}
        width={350}
        withOverlay={false}
        borderWidth={1}
        borderColor="primary:setAlpha:0.2"
        popover={({ toggleTooltip }) => (
          <Flex w="100%">
            {sharing.patientWithDocs.map((item) => {
              return (
                <ListItem
                  title={`${item.patientName}`}
                  desc={`Dokumente: ${item.documents?.length}`}
                  icons={[{ name: "share" }]}
                  onPress={() => {
                    toggleTooltip?.();
                    navigation.navigate("Form", {
                      defaultDoc: {
                        ...item,
                      },
                      title: `${item.patientName} teilen`,
                      typename: "Share",
                      mode: "insert",
                      onSuccess: ({ navigation }) => {
                        navigation?.goBack?.();
                        setSharing(undefined, item.patientId);
                      },
                    });
                  }}
                  actionType="inline"
                  actions={[
                    {
                      icon: "trash",
                      bg: "error:setAlpha:0.1",
                      color: "error",
                      onPress: () => {
                        setSharing(undefined, item.patientId);
                      },
                    },
                  ]}
                />
              );
            })}
          </Flex>
        )}
      >
        <Flex
          bg="primary"
          w={60}
          h={44}
          borderRadius={22}
          center
          mb={10}
          shadow={50}
        >
          <Icon name="share" color="#FFF" />
          <Flex
            bg="success"
            w={20}
            h={20}
            borderRadius={10}
            right={-3}
            top={-5}
            absolute
            center
          >
            <Text font="label" color="#FFF">
              {sharing?.count}
            </Text>
          </Flex>
        </Flex>
      </Tooltip>
    </Flex>
  );
};

import React, { useCallback, useReducer } from "react";
import { Text, View, TouchableOpacity, ScrollView } from "react-native";
import { color } from "unikit";

const reducer = (state, _action) => !state;

const useForceUpdate = () => {
  const [, dispatch] = useReducer(reducer, true);

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = useCallback((): void => {
    dispatch(null);
  }, [dispatch]);
  return memoizedDispatch;
};

var colors = ["#9cfd9d", "#feca9b", "#fecb6e", "#fd9827", "#fc0d1b", "#000000"];

export default ({ value, options, onChange }) => {
  const forceUpdate = useForceUpdate();
  return (
    <ScrollView
      style={{ width: "100%" }}
      contentContainerStyle={{ minWidth: "100%" }}
      horizontal
    >
      <View style={{ minWidth: "100%", flexDirection: "row" }}>
        {options.map((data, index) => {
          return (
            <View
              key={index}
              style={{
                flex: 1,
                minWidth: 150,
                paddingRight: 2,
                height: 300,
              }}
            >
              <TouchableOpacity
                style={{
                  width: "100%",
                  justifyContent: "center",
                  textAlign: "center",
                  height: 100,
                  paddingHorizontal: 15,
                  backgroundColor:
                    value && value[data.name] === true
                      ? color("#9A9EB2").lighten(15).toString()
                      : color("#9A9EB2").lighten(30).toString(),
                }}
                onPress={() => {
                  const newValue = value || {};
                  newValue[data.name] = newValue[data.name] ? false : true;
                  onChange(newValue);
                  forceUpdate();
                }}
              >
                <Text style={{ fontSize: 14, textAlign: "center" }}>
                  {data.label}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    marginTop: 5,
                    textAlign: "center",
                    maxWidth: 200,
                  }}
                >
                  {data.sublabel}
                </Text>
              </TouchableOpacity>
              {data.options.map((subData, subIndex) => {
                return (
                  <TouchableOpacity
                    key={subIndex}
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingHorizontal: 15,
                      flex: 1,
                      marginTop: 2,
                      backgroundColor:
                        value && value[subData.name] === true
                          ? colors[subIndex]
                          : color(colors[subIndex]).lighten(15).toString(),
                    }}
                    onPress={() => {
                      const newValue = value || {};
                      newValue[subData.name] = newValue[subData.name]
                        ? false
                        : true;
                      onChange(newValue);
                      forceUpdate();
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 10,
                        textAlign: "center",
                        maxWidth: 200,
                      }}
                    >
                      {subData.label}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          );
        })}
      </View>
    </ScrollView>
  );
};

import SimpleSchema from "./simple";

const schema = {
  alert: {
    type: String,
    label: "Globale Mitteilung",
    optional: true,
    group: "Allgemein"
  },
  lastMaterialsSync: {
    type: Date,
    label: "Letzte Material synchronisierung",
    optional: true,
    autoValue: function() {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    input: "DateTimePicker",
    group: "Allgemein"
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function() {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function() {
      return new Date();
    },
    optional: true,
    listed: false
  },
  companyId: {
    type: String,
    listed: false
  }
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Settings = new Mongo.Collection("settings");
  Settings.schema = simpleSchema;
  Settings.attachSchema(simpleSchema);
}

export { simpleSchema as SettingSchema };

export default schema;

import { useCallback, useEffect, useState } from "react";
import {
  ApolloClient,
  useApolloClient,
  HttpLink,
  InMemoryCache,
  useQuery,
  useMutation,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import AsyncStorage from "@react-native-async-storage/async-storage";
import gql from "graphql-tag";
import { AsyncStorageWrapper, CachePersistor } from "apollo3-cache-persist";
import createExpoFileSystemStorage from "expo-filesystem-storage";
import { setAppContext } from "./context";
import OfflineLink from "./apolloOfflineLink";

import { showAlert, isWeb } from "unikit";

const SCHEMA_VERSION = "8"; // Must be a string.
const SCHEMA_VERSION_KEY = "apollo-schema-version";
const GRAPHQL_API_URL = __DEV__
  ? "http://localhost:4000/graphql"
  : "https://staging.clouddoku.de/graphql";

import getSchemaByType from "./clouddoku/lib/schemas";
import { Platform } from "react-native";
import { useNavigation } from "@react-navigation/core";

export { useQuery, useMutation, gql };

export const useClient = () => {
  const [client, setClient] = useState();
  const [persistor, setPersistor] = useState();
  const clearCache = useCallback(() => {
    persistor?.purge();
  }, [persistor]);

  const logout = async () => {
    console.log("logout");
    await AsyncStorage.removeItem("token");
    setAppContext({ user: null });
    // if (isWeb) {
    //   clearCache?.();
    // }
    client
      ?.resetStore()
      .then(() => {
        console.log("logout and reset");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    //console.log({ operation, graphQLErrors });
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );
        if (message === "no_user") {
          logout();
        }
      });
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  useEffect(() => {
    async function init() {
      const offlineLink = new OfflineLink({
        storage: AsyncStorage,
      });
      const authLink = setContext(async (_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = await AsyncStorage.getItem("token");

        // return the headers to the context so httpLink can read them
        return {
          headers: {
            ...headers,
            authorization: token ? token : null,
          },
        };
      });

      const cache = new InMemoryCache({
        dataIdFromObject: (object) => object._id,
      });

      if (Platform.OS === "android") {
        const expoFsStorage = createExpoFileSystemStorage();
        let newPersistor = new CachePersistor({
          cache,
          storage: expoFsStorage,
          debug: __DEV__,
          trigger: "write",
        });
        await newPersistor.restore();
        setPersistor(newPersistor);
      } else if (!isWeb) {
        let newPersistor = new CachePersistor({
          cache,
          storage: new AsyncStorageWrapper(AsyncStorage),
          debug: __DEV__,
          trigger: "write",
        });
        setPersistor(newPersistor);
        const currentVersion = await AsyncStorage.getItem(SCHEMA_VERSION_KEY);

        if (currentVersion === SCHEMA_VERSION) {
          // If the current version matches the latest version,
          // we're good to go and can restore the cache.
          try {
            // Might throw Couldn't read row 0, col 0 from CursorWindow
            await newPersistor.restore();
          } catch (err) {
            // CachePersistor failed - purge
            await newPersistor.purge();
          }
        } else {
          // Otherwise, we'll want to purge the outdated persisted cache
          // and mark ourselves as having updated to the latest version.
          await newPersistor.purge();
          await AsyncStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
        }
      }

      const httpLink = new HttpLink({
        uri: GRAPHQL_API_URL,
      });

      const client = new ApolloClient({
        link: isWeb
          ? from([errorLink, authLink, httpLink])
          : from([offlineLink, errorLink, authLink, httpLink]),
        cache,
        defaultOptions: {
          watchQuery: {
            fetchPolicy: "cache-and-network",
            errorPolicy: "all",
          },
          query: {
            fetchPolicy: "cache-and-network",
            errorPolicy: "all",
          },
          mutate: {
            errorPolicy: "all",
          },
        },
      });
      if (!isWeb) {
        offlineLink.setup(client);
      }

      setClient(client);
    }

    init();
  }, []);

  return {
    client,
    logout,
  };
};

export const USER_QUERY = gql`
  query currentUser {
    currentUser {
      ${getSchemaByType("User").typeDef}
      warehouseMaterials
      image {
        _id
        url
        name
      }
      logo {
        _id
        url
        name
      }
      team {
        _id
        emails
        firstName
        lastName
        color
        mobil
        image {
          _id
          url
          name
        }
      }
      teamBanned {
        _id
        emails
        firstName
        lastName
        color
        mobil
        banned
        image {
          _id
          url
          name
        }
      }
    }
  }
`;

export const USERS_QUERY = gql`
  query users($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    users(filter: $filter, sort: $sort, page: $page, limit: $limit)
      @connection(key: "users", filter: ["filter"]) {
        data {
          ${getSchemaByType("User").typeDef}
          team {
            _id
            emails
            firstName
            lastName
            banned
            roles
          }
        }
        count
      
    }
  }
`;

export const PATIENTS_QUERY = gql`
  query patients(
    $filter: Object
    $sort: Object
    $page: Int
    $limit: Int
    $withDeleted: Boolean
  ) {
    patients(
      filter: $filter
      sort: $sort
      page: $page
      limit: $limit
      withDeleted: $withDeleted
    ) @connection(key: "patients", filter: ["filter"]) {
      data {
        _id
        firstName
        lastName
        zipcode
        residence
        address
        archived
        updatedAt
        myPatient
        users {
          _id
          firstName
          lastName
          userPictureFileId
          color
          image {
            _id
            url
            name
          }
        }
      }
      count
    }
  }
`;

export const PATIENT_QUERY = gql`
  query patient($filter: Object, $sort: Object) {
    patient(filter: $filter, sort: $sort) {
      ${getSchemaByType("Patient").typeDef}
      anamnese {
        ${getSchemaByType("Anamnese").typeDef}
      }
    }
  }
`;

export const THERAPY_QUERY = gql`
  query therapy($filter: Object, $sort: Object) {
    therapy(filter: $filter, sort: $sort) {
      ${getSchemaByType("Therapy").typeDef}
      anamnesen {
        ${getSchemaByType("Anamnese").typeDef}
      }
      woundImages {
        ${getSchemaByType("WoundImage").typeDef}
      }
    }
  }
`;

export const MATERIALS_QUERY = gql`
  query materials($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    materials(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted)
      @connection(key: "materials", filter: ["filter"]) {
        data {
          ${getSchemaByType("Material").typeDef}
        }
        count
    }
  }
`;

export const CONTACTS_QUERY = gql`
  query contacts($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    contacts(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted)
      @connection(key: "contacts", filter: ["filter"]) {
        data {
          ${getSchemaByType("Contact").typeDef}
        }
        count
    }
  }
`;

export const THERAPIES_QUERY = gql`
  query therapys($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    therapys(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted) @connection(key: "therapys", filter: ["filter"]) {
      data {
        ${getSchemaByType("Therapy").typeDef}
      }
      count
    }
  }
`;

export const SHARES_QUERY = gql`
  query shares($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    shares(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted) 
      @connection(key: "shares", filter: ["filter"]) {
        data {
          ${getSchemaByType("Share").typeDef}
          user {
            _id
            firstName
            lastName
            company
          }
        }
        count
    }
  }
`;

export const EVIDENCES_QUERY = gql`
  query evidences($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    evidences(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted) @connection(key: "evidences", filter: ["filter"]) {
      data {
      ${getSchemaByType("Evidence").typeDef}
      patient {
        firstName
        lastName
        contactIDs
        doctor {
          name
        }
      }
      user {
        _id
        firstName
        lastName
        userPictureFileId
        color
        image {
          _id
          url
          name
        }
      }
      }
      count
    }
  }
`;

export const ANAMNESES_QUERY = gql`
  query anamneses($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    anamneses(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted) @connection(key: "anamneses", filter: ["filter"]) {
      data {
        ${getSchemaByType("Anamnese").typeDef}
      }
      count
    }
  }
`;

export const WOUNDIMAGES_QUERY = gql`
  query woundimages($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    woundimages(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted) @connection(key: "woundimages", filter: ["filter"]) {
      data {
        ${getSchemaByType("WoundImage").typeDef}
        image {
          _id
          url
          name
          key
        }
      }
      count 
    }
  }
`;

export const DELIVERYNOTES_QUERY = gql`
  query deliverynotes($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    deliverynotes(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted) @connection(key: "deliverynotes", filter: ["filter"]) {
      data {
        ${getSchemaByType("DeliveryNote").typeDef}
        patient {
          _id
          firstName
          lastName
          contactIDs
          doctor {
            name
          }
        }
        user {
          _id
          firstName
          lastName
          userPictureFileId
          color
          image {
            _id
            url
            name
          }
        }
      }
      count
    }
  }
`;

export const MATERIALSGROUP_QUERY = gql`
  query materialgroups(
    $filter: Object
    $sort: Object
    $page: Int
    $limit: Int
    $withDeleted: Boolean
  ) {
    materialgroups(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted)
      @connection(key: "materialgroups", filter: ["filter"]) {
        data {
          ${getSchemaByType("MaterialGroup").typeDef}
        }
        count
    }
  }
`;

export const APPOINTMENTS_QUERY = gql`
  query appointments($filter: Object, $sort: Object, $limit: Int, $withDeleted: Boolean) {
    appointments(filter: $filter, sort: $sort, limit: $limit, withDeleted: $withDeleted) 
    @connection(key: "appointments", filter: ["filter"]) {
      data {
        ${getSchemaByType("Appointment").typeDef}
      }
      count
    }
  }
`;

export const DOCUMENTS_QUERY = gql`
  query documents($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    documents(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted) @connection(key: "documents", filter: ["filter"]){
      data {
        ${getSchemaByType("Document").typeDef}
      }
      count
    }
  }
`;

export const ADVICES_QUERY = gql`
  query advices($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    advices(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted) @connection(key: "advices", filter: ["filter"]) {
      data {
        ${getSchemaByType("Advice").typeDef}
      }
      count
    }
  }
`;

export const ORDERS_QUERY = gql`
  query orders($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    orders(filter: $filter, sort: $sort, page: $page, limit: $limit) @connection(key: "orders", filter: ["filter"]) {
      data {
        ${getSchemaByType("Order").typeDef}
        contact {
          name
          discount
        }
      }
      count
      }
  }
`;

export const WOUNDSUMMARYS_QUERY = gql`
query woundsummarys($filter: Object, $sort: Object, $page: Int, $limit: Int) {
  woundsummarys(filter: $filter, sort: $sort, page: $page, limit: $limit) @connection(key: "woundsummarys", filter: ["filter"]) {
    data {
      ${getSchemaByType("WoundSummary").typeDef}
    }
    count
  }
}
`;

export const OWNDOCS_QUERY = gql`
query owndocs($filter: Object, $sort: Object, $page: Int, $limit: Int) {
  owndocs(filter: $filter, sort: $sort, page: $page, limit: $limit) @connection(key: "woundsummarys", filter: ["filter"]) {
    data {
      ${getSchemaByType("OwnDoc").typeDef}
    }
    count
  }
}
`;

export const DOCTORSLETTERS_QUERY = gql`
query doctorsletters($filter: Object, $sort: Object, $page: Int, $limit: Int) {
  doctorsletters(filter: $filter, sort: $sort, page: $page, limit: $limit) @connection(key: "doctorsletters", filter: ["filter"]) {
    data {
      ${getSchemaByType("DoctorsLetter").typeDef}
    }
    count
  }
}
`;

export const ACTIONS_QUERY = gql`
  query actions($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    actions(filter: $filter, sort: $sort, page: $page, limit: $limit)
      @connection(key: "actions", filter: ["filter"]) {
      data {
        ${getSchemaByType("Action").typeDef}
        patient {
          firstName
          lastName
        }
        doc
        user {
          _id
          emails
          firstName
          lastName
          color
          image {
            _id
            url
            name
          }
        }
      }
      count
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($base64: String!) {
    uploadFile(base64: $base64)
  }
`;

export const DELETE_FILE = gql`
  mutation deleteFile($_id: String!) {
    deleteFile(_id: $_id)
  }
`;

export const CALL_METEOR = gql`
  mutation callMeteor($name: String!, $config: Object!) {
    callMeteor(name: $name, config: $config)
  }
`;

export const QUERIES = {
  CurrentUser: { query: USER_QUERY, name: "currentUser" },
  User: { query: USERS_QUERY, name: "users" },
  Patient: {
    query: PATIENTS_QUERY,
    name: "patients",
    singleQuery: PATIENT_QUERY,
    singleName: "patient",
  },
  Contact: { query: CONTACTS_QUERY, name: "contacts" },
  Material: { query: MATERIALS_QUERY, name: "materials" },
  Therapy: { query: THERAPIES_QUERY, name: "therapys" },
  Share: { query: SHARES_QUERY, name: "shares" },
  Evidence: { query: EVIDENCES_QUERY, name: "evidences" },
  Anamnese: { query: ANAMNESES_QUERY, name: "anamneses" },
  WoundImage: { query: WOUNDIMAGES_QUERY, name: "woundimages" },
  DeliveryNote: { query: DELIVERYNOTES_QUERY, name: "deliverynotes" },
  MaterialGroup: { query: MATERIALSGROUP_QUERY, name: "materialgroups" },
  Appointment: { query: APPOINTMENTS_QUERY, name: "appointments" },
  DoctorsLetter: { query: DOCTORSLETTERS_QUERY, name: "doctorsletters" },
  Advice: { query: ADVICES_QUERY, name: "advices" },
  Order: { query: ORDERS_QUERY, name: "orders" },
  WoundSummary: { query: WOUNDSUMMARYS_QUERY, name: "woundsummarys" },
  Action: { query: ACTIONS_QUERY, name: "actions" },
  Document: { query: DOCUMENTS_QUERY, name: "documents" },
  OwnDoc: { query: OWNDOCS_QUERY, name: "owndocs" },
};

export function useData({ typename, single = false, variables = {}, ...rest }) {
  const query = QUERIES[typename];

  const result = useQuery(single ? query.singleQuery : query.query, {
    variables,
  });
  const queryData = result?.data?.[single ? query.singleName : query.name];
  return {
    ...result,
    data: queryData?.data || queryData,
    count: queryData?.count,
    name: query?.name,
    fetchMore: result.fetchMore,
  };
}

export function mongoObjectId() {
  var timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    "xxxxxxxxxxxxxxxx"
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
}

export function useHandleDoc() {
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const navigation = useNavigation();

  const handleDoc = ({
    typename,
    id,
    doc,
    mode,
    customMode,
    onSuccess,
    onError,
    refetchQueries,
    queryVariables = {},
    ignoreLock = false,
  }) => {
    setLoading(true);
    const query = QUERIES[typename];
    const schema = getSchemaByType(typename);

    if (mode === "insert" && !doc["_id"]) {
      doc["_id"] = mongoObjectId();
    }

    const responseDoc = Object.assign({}, doc, {
      __typename: typename,
    });

    if (id) {
      responseDoc["_id"] = id;
    }
    if (mode === "delete") {
      responseDoc["deleted"] = true;
    }

    client
      .mutate({
        mutation: gql`
        mutation handleDoc($_id: ID, $doc: Object, $type: String!, $mode: String!, $customMode: String$, ignoreLock: Boolean) {
          handleDoc(_id: $_id, doc: $doc, type: $type, mode: $mode, customMode: $customMode, ignoreLock: $ignoreLock) {
            ... on ${typename} {
              ${schema.typeDef}
            }
          }
        }
      `,
        variables: {
          _id: id,
          doc,
          type: typename,
          mode,
          customMode,
          ignoreLock,
        },
        optimisticResponse: {
          __typename: "Mutation",
          handleDoc: responseDoc,
        },
        update: (cache, { data: { handleDoc } }) => {
          if (mode === "insert") {
            const readQuery = cache.readQuery({
              query: query.query,
              variables: queryVariables,
            });
            console.log({ readQuery, query, queryVariables });
            if (readQuery && handleDoc) {
              cache.writeQuery({
                query: query.query,
                variables: queryVariables,
                data: {
                  [query.name]: {
                    data: [handleDoc, ...readQuery[query.name].data],
                    count: readQuery[query.name].count + 1,
                  },
                },
              });
            }
          }
        },
      })
      .then((result) => {
        setLoading(false);
        console.log({ result });
        const successMessages = {
          Share: "erfolgreich gesendet 😊",
        };
        showAlert({
          type: "success",
          message: successMessages?.[typename] || "Gespeichert 😊",
          timeout: 2000,
        });
        if (mode === "insert" && typename) {
          client.refetchQueries({
            include: [`${query?.name}`],
          });
        }
        if (refetchQueries?.length > 0) {
          client.refetchQueries({
            include: refetchQueries,
          });
        }
        if (onSuccess) {
          onSuccess({ item: result?.data?.handleDoc, navigation, client });
        }
      })
      .catch((error) => {
        console.log({ error });
        setLoading(false);
        onError?.();
        showAlert({
          type: "error",
          message: error?.message,
          timeout: 2000,
        });
      });
  };

  return {
    handleDoc,
    loading,
  };
}

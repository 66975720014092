import * as React from "react";
import { Linking, Platform } from "react-native";
import { Button, Grid, useTheme, Flex, Text, Progress } from "unikit";

import { ListItem, ListQuery, List } from "../list";
import i18n from "../../i18n";
import Form from "../form";
import item from "../list/item";

export default ({
  value = [],
  inputKey,
  onChange,
  navigation,
  doc,
  multiple = true,
  title,
  desc,
  progress,
  note,
  noteProps,
  actions,
  typename,
  skipOnInsert = false,
  mode,
  allowSearch = true,
  allowCreate = false,
  allowEdit = false,
  allowRemove = false,
  withDeleted = true,
  setDocKey,
  defaultValues,
  queryData = true,
  prompt,
  skipSearch = true,
  min,
  getFilter,
  listFilter,
  tableFooter,
  footerActions,
  actionPosition = "right",
}) => {
  const theme = useTheme();

  console.log("DocList", { multiple, value, inputKey, typename });
  if (multiple === false) {
    value = value?.length > 0 ? [value] : [];
  } else {
    value = value === undefined || value === null ? [] : value;
  }

  const setItem = (item, goBack, force) => {
    console.log("setItem", { item });
    if (typeof value === "object") {
      var found = value.filter((e) =>
        queryData ? e && e === item._id : e && e._id === item._id
      );
      if (found.length > 0 && force !== true) {
        theme.alert({
          type: "surface",
          message: "Schon in Liste vorhanden",
        });
        return;
      }
    }
    if (multiple === false) {
      var newValue = item._id;
    } else {
      var newValue = [...(value || [])];
      if (found.length > 0) {
        newValue = newValue.map((e) => {
          const match = queryData
            ? e && e === item._id
            : e && e._id === item._id;
          if (match) {
            return { ...e, ...item };
          } else {
            return e;
          }
        });
      } else {
        newValue.push(queryData === false ? item : item._id);
        newValue = newValue.filter((v) => v !== null);
      }
    }
    if (setDocKey) onChange(title?.(item), setDocKey);
    console.log({ newValue, multiple, found });
    onChange(newValue);

    goBack?.();
  };

  const showPrompt = ({ title = "", schema, item, goBack, force = false }) => {
    theme.alert({
      bg: "surface",
      message: title,
      timeout: false,
      position: "center",
      renderContent: ({ close }) => {
        return (
          <Flex w="100%">
            <Form
              schema={schema}
              groupBy={null}
              scrollEnabled={false}
              style={{ width: "100%" }}
              spacer={false}
              onSubmit={({ doc }) => {
                setItem({ ...item, ...doc }, goBack, force);
                close?.();
              }}
              onCancel={() => {
                close?.();
              }}
            />
          </Flex>
        );
      },
    });
  };

  const renderItem = ({ item, index }) => {
    return (
      <ListItem
        title={title?.(item)}
        desc={desc?.(item)}
        note={note?.(item)}
        renderLeft={
          progress ? (
            <Progress
              size={35}
              showValue
              trackWidth={3}
              progressWidth={3}
              trackColor="primary:setAlpha:0.1"
              ml={15}
              {...progress?.(item)}
            />
          ) : null
        }
        actionType={actionPosition === "bottom" ? "inline" : undefined}
        actionPosition={actionPosition}
        noteProps={noteProps}
        onPress={() => {
          if (allowEdit) {
            navigation.push("Form", {
              title: title?.(item),
              typename,
              mode: "update",
              defaultDoc: item,
              id: item._id,
              onSuccess: ({ navigation, item, mode }) => {
                if (mode === "delete") {
                  let newValue = [...(value || [])];
                  if (queryData) {
                    onChange(newValue.filter((v) => v._id !== item._id));
                  } else {
                    onChange(newValue.filter((v) => v !== item._id));
                  }
                }
                navigation.goBack();
              },
            });
          }
        }}
        pills={[
          ...(item.deleted ? [{ bg: "error", label: "gelöscht" }] : []),
          ...(item.copyId
            ? [{ bg: "primary", color: "#FFF", label: "Sync" }]
            : []),
        ]}
        actions={
          allowSearch || allowRemove
            ? [
                ...(actions
                  ? actions?.({
                      doc,
                      item,
                      navigation,
                      Linking,
                      platform: Platform.OS,
                      showPrompt,
                    })
                  : []),
                {
                  icon: "trash",
                  bg: "error:setAlpha:0.1",
                  color: "error",
                  text: "Aus Liste löschen",
                  onPress: () => {
                    if (multiple) {
                      let newValue = [...(value || [])];
                      if (queryData === false) {
                        console.log(
                          "=====>queryData",
                          newValue.filter((v) => v._id !== item._id)
                        );
                        onChange(newValue.filter((v) => v._id !== item._id));
                      } else {
                        onChange(newValue.filter((v) => v !== item._id));
                      }
                    } else {
                      onChange(null);
                    }
                  },
                },
              ]
            : actions
            ? actions?.({
                doc,
                item,
                navigation,
                Linking,
                platform: Platform.OS,
                showPrompt,
              })
            : undefined
        }
      />
    );
  };

  console.log({ value });

  if (mode === "insert" && skipOnInsert === true) {
    return (
      <Flex py={30} w="100%" center>
        <Text>{`${i18n.t(
          `types.${typename?.toLowerCase()}_plural`
        )} können erst nach dem speichern hinzugefügt werden`}</Text>
      </Flex>
    );
  }

  return (
    <Flex f={Platform.OS !== "web" ? 0 : undefined} w="100%">
      {/* <Text>{JSON.stringify(value)}</Text> */}
      {queryData === false ? (
        <List data={value} gap={0} renderItem={renderItem} />
      ) : null}
      {(multiple === false && value.length === 0) ||
      queryData === false ? null : (
        <ListQuery
          gap={0}
          typename={typename}
          filter={
            listFilter ? listFilter?.({ doc }) : { _id: { $in: value || [] } }
          }
          withDeleted={withDeleted}
          name={typename?.toLowerCase() + "s"}
          renderItem={renderItem}
          showListEmpty={false}
          min={min}
          skip={skipOnInsert === true && mode === "insert"}
        />
      )}
      <Grid gap={5} min={300} w="100%" style={{ marginBottom: 8 }}>
        {(allowSearch && multiple) ||
        (multiple === false && !value?.length > 0) ? (
          <Button
            w="100%"
            mt={5}
            light
            onPress={() => {
              navigation.push("Search", {
                typename,
                skip: skipSearch,
                ...(getFilter ? { filter: getFilter({ doc }) } : {}),
                onSelect: (item, goBack) => {
                  if (prompt) {
                    showPrompt({ ...prompt, item, goBack });
                  } else {
                    setItem(item, goBack);
                  }
                },
              });
            }}
          >
            {`${i18n.t(`types.${typename?.toLowerCase()}`)} ${
              getFilter ? "wählen" : "suchen"
            }`}
          </Button>
        ) : null}
        {allowCreate && !doc?.copyId ? (
          <Button
            w="100%"
            light
            mt={5}
            onPress={() => {
              navigation.push("Form", {
                title: `${i18n.t(
                  `types.${typename?.toLowerCase()}`
                )} hinzufügen`,
                typename,
                mode: "insert",
                defaultDoc: defaultValues?.(doc),
                onSuccess: ({ navigation, item }) => {
                  if (multiple === false) {
                    var newValue = item._id;
                  } else {
                    var newValue = value || [];
                    newValue.push(item._id);
                  }
                  onChange(newValue);
                  navigation.goBack();
                },
              });
            }}
          >
            {`${i18n.t(`types.${typename?.toLowerCase()}`)} hinzufügen`}
          </Button>
        ) : null}
      </Grid>
      {footerActions?.map((item, index) => {
        return (
          <Button key={`footer-action-${index}`} onPress={item?.onPress} light>
            {item?.label}
          </Button>
        );
      })}
      {tableFooter?.map((item, index) => {
        return (
          <Flex
            key={`tablefooter-${index}`}
            style={{
              width: "100%",
              marginTop: 10,
              alignItems: "flex-end",
            }}
          >
            <Flex
              style={{
                flexDirection: "row",
                width: 300,
                justifyContent: "space-between",
              }}
            >
              <Text>{item.label}</Text>
              <Text style={{ fontWeight: "bold" }}>{item.value}</Text>
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

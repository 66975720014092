import * as React from "react";
import { Flex, Chart, Grid, Text } from "unikit";
import { ScrollView } from "react-native";

import {
  getWoundSurface,
  getWoundSize,
  getCutIntensity,
  getWoundSurrounding,
  getDermicState,
  getMacerationEdging,
} from "../../clouddoku/lib/helper";

const cutLabels = {
  1: "leicht",
  5: "mittel",
  10: "stark",
};

export default function Screen({ anamnesen = [], woundImages = [] }) {
  console.log({ anamnesen });
  if (anamnesen.length < 2 || woundImages.length < 2)
    return (
      <Flex h={500} w="100%" flexCenter>
        <Text>Nicht genug Daten vorhanden</Text>
      </Flex>
    );

  const chartData = React.useMemo(() => {
    return {
      getWoundSurface: getWoundSurface(anamnesen || []),
      getCutIntensity: getCutIntensity(anamnesen || []),
      getWoundSize: getWoundSize(woundImages || []),
    };
  }, [anamnesen, woundImages]);

  return (
    <Grid gap={15} min={400} maxCols={2} outerGap>
      {chartData.getWoundSurface?.length > 0 ? (
        <Flex bg="surface" shadow={3} borderRadius={10} p={20}>
          <Text font="h5">{`Wundgrund/Wundfläche`}</Text>
          <ScrollView style={{ width: "100%" }} horizontal>
            <Chart
              data={chartData.getWoundSurface}
              width="100%"
              minWidth={chartData.getWoundSurface.length * 100}
              height={300}
              xAxis
              yAxis
              contentInset={{ bottom: 20, top: 20, left: 0, right: 0 }}
            >
              <Chart.Grid />
              <Chart.Indicator color="primary:setAlpha:0.5" />
              <Chart.Line
                dataKey="nekrose"
                showValue
                gradient={false}
                color="#000"
                formatValue={(v) => `${v}%`}
              />
              <Chart.Line
                dataKey="fibrin"
                showValue
                gradient={false}
                color="#fdc02f"
                formatValue={(v) => `${v}%`}
              />
              <Chart.Line
                dataKey="granulation"
                showValue
                gradient={false}
                color="#f1453d"
                formatValue={(v) => `${v}%`}
              />
              <Chart.Line
                dataKey="epithelision"
                showValue
                gradient={false}
                color="#1fbcd2"
                formatValue={(v) => `${v}%`}
              />
            </Chart>
          </ScrollView>
        </Flex>
      ) : null}

      {chartData.getCutIntensity?.length > 0 ? (
        <Flex bg="surface" shadow={3} borderRadius={10} p={20}>
          <Text font="h5">{`Subjektive Schmerzintensität`}</Text>
          <ScrollView style={{ width: "100%" }} horizontal>
            <Chart
              data={chartData.getCutIntensity}
              width="100%"
              minWidth={chartData.getCutIntensity.length * 100}
              height={300}
              yDomain={[0, 10]}
              contentInset={{ bottom: 20, top: 20, left: 0, right: 0 }}
              formatY={(value) => `${cutLabels[value] || value}`}
              xAxis
              yAxis
            >
              <Chart.Grid />
              <Chart.Indicator color="primary:setAlpha:0.5" />
              <Chart.Line
                dataKey="value"
                showValue
                gradientStartColor="#bb0000"
                gradientStopColor="#c7d961"
                color="#bb0000"
                formatValue={(v) => `${v}`}
                curve="curveNatural"
              />
            </Chart>
          </ScrollView>
        </Flex>
      ) : null}

      {chartData.getWoundSize?.length > 0 ? (
        <Flex bg="surface" shadow={3} borderRadius={10} p={20}>
          <Text font="h5">{`Wundfläche in cm²`}</Text>
          <ScrollView style={{ width: "100%" }} horizontal>
            <Chart
              data={chartData.getWoundSize}
              width="100%"
              minWidth={chartData.getWoundSize.length * 100}
              height={300}
              contentInset={{ bottom: 20, top: 20, left: 0, right: 0 }}
              xAxis
              yAxis
            >
              <Chart.Grid />
              <Chart.Indicator color="primary:setAlpha:0.5" />
              <Chart.Line
                dataKey="value"
                showValue
                formatValue={(v) => `${v}cm²`}
              />
            </Chart>
          </ScrollView>
        </Flex>
      ) : null}
      {/* 
        <LineChart
          title="Wundfläche in cm²"
          data={getWoundSize(woundImages || [])}
          formatY={(value) => `${value}`}
          gradient={true}
          legend={false}
          formatValue={(value) => `${value}`}
          scrollable
        />

        <LineChart
          title="Subjektive Schmerzintensität"
          data={getCutIntensity(anamnesen || [])}
          minBarWidth={30}
          scrollable
          tickValues={[1, 5, 10]}
          yDomain={[1, 10]}
          gradient
          formatY={(value) => `${cutLabels[value] || value}`}
          gradientStartColor="#bb0000"
          gradientStopColor="#c7d961"
          legend={false}
        />

        <LineChart
          title="Wundumgebung"
          data={getWoundSurrounding(anamnesen || [])}
          tickValues={[0, 1, 2, 3]}
          yDomain={[0, 3]}
          formatY={(value) => {
            const labels = ["none", "ease", "moderate", "strong"];
            return labels[value] || value;
          }}
          scrollable
          legend
          keys={[
            "macerationSurroundingGrad",
            "rednessSurroundingGrad",
            "xeroticSurroundingGrad",
            "lividSurroundingGrad",
          ]}
          keyProps={{
            macerationSurroundingGrad: {
              color: "#000",
            },
            rednessSurroundingGrad: {
              color: "#fdc02f",
            },
            xeroticSurroundingGrad: {
              color: "#f1453d",
            },
            lividSurroundingGrad: {
              color: "#1fbcd2",
            },
          }}
        /> */}

      {/* <LineChart
          title="Wundfläche in cm²"
          data={getWoundSize(woundImages || [])}
          minBarWidth={30}
          formatValue={(value) => `${value}cm²`}
          scrollable
        />

        <LineChart
          title="Subjektive Schmerzintensität"
          data={getCutIntensity(anamnesen || [])}
          minBarWidth={30}
          scrollable
          maxValue={10}
          tickCount={2}
        />
        <LineChart
          title="Wundumgebung"
          data={getWoundSurrounding(anamnesen || [])}
          minBarWidth={30}
          maxValue={3}
          tickCount={4}
          formatLabel={(value) => {
            const labels = ["none", "ease", "moderate", "strong"];
            return labels[value] || value;
          }}
          legend
          scrollable
        />
        <LineChart
          title="Hautzustand allgemein"
          data={getDermicState(anamnesen || [])}
          minBarWidth={30}
          maxValue={3}
          tickCount={4}
          formatLabel={(value) => {
            const labels = ["none", "ease", "moderate", "strong"];
            return labels[value] || value;
          }}
          legend
          scrollable
        />
        <LineChart
          title="Wundrand"
          data={getMacerationEdging(anamnesen || [])}
          minBarWidth={30}
          maxValue={3}
          tickCount={4}
          formatLabel={(value) => {
            const labels = ["none", "ease", "moderate", "strong"];
            return labels[value] || value;
          }}
          legend
          scrollable
        /> */}
    </Grid>
  );
}

import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";

import type { BaseTheme } from "./types";

import { deepMerge } from "../util";
import alertRef from "../components/Alert/ref";

import ThemeContext from "./context";
import DefaultTheme from "./theme";
import { useDimensions, useUpdateEffect } from "../hooks";

export function showAlert(obj: any) {
  return alertRef.current.show(obj);
}

export function hideAlert(obj: any) {
  return alertRef.current.show({ ...obj, timeout: 0 });
}

const ThemeProvider = ({
  theme,
  mode,
  children,
  onFeedback,
}: {
  theme: BaseTheme;
  mode?: string;
  children: React.ReactNode;
}) => {
  const { window } = useDimensions();
  const [themeMode, setMode] = React.useState(mode || "light");
  const mergedTheme = React.useMemo(() => {
    const merged = deepMerge(DefaultTheme, theme);
    if (themeMode && merged.colors.modes[themeMode]) {
      return {
        ...merged,
        colors: {
          ...merged.colors,
          ...merged.colors.modes[themeMode],
        },
      };
    }
    return merged;
  }, [theme, themeMode]);

  useUpdateEffect(() => {
    if (mode) setMode(mode);
  }, [mode]);

  return (
    <SafeAreaProvider>
      <ThemeContext.Provider
        value={{
          ...mergedTheme,
          mode: themeMode,
          setMode,
          width: window.width,
          height: window.height,
          alert: (a: any) => {
            alertRef.current.show(a);
          },
          showAlert: (a: any) => alertRef.current.show(a),
          hideAlert: (a: any) => alertRef.current.show({ ...a, timeout: 0 }),
          onFeedback,
        }}
      >
        {children}
      </ThemeContext.Provider>
    </SafeAreaProvider>
  );
};

export default ThemeProvider;

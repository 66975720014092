import * as React from "react";
import dayjs from "dayjs";

import { Flex, Tabs, Form, Input, Text, useTheme, Button } from "unikit";
import { Page, ListQuery, ListItem } from "components";
import { useAppContext } from "../../context";

import { PasswordForm } from "./profile";

function Team({ item, navigation }) {
  const theme = useTheme();
  const [all, setAll] = React.useState(false);
  return (
    <Flex w="100%" alignItems="center">
      <Text font="label">{`Mitarbeiter (${item?.team?.length}/${item.employeeCount})`}</Text>
      {item?.team?.map((member, index) => {
        if (!all && index > 3) return null;
        return (
          <ListItem
            key={`team-${index}`}
            title={`${member.emails?.[0]?.address}`}
            desc={`${member.lastName}, ${member.firstName} -${
              member.lastLogin
                ? ` - Letzter Login: ${dayjs(member.lastLogin).format(
                    "DD.MM.YYYY"
                  )}`
                : ""
            }`}
            pills={[
              {
                label: `${member?.roles.join(", ")}`,
              },
            ]}
            onPress={() =>
              navigation.navigate("Form", {
                id: member._id,
                title: `${member.emails?.[0]?.address}`,
                typename: "User",
                defaultDoc: member,
                mode: "update",
                roles: member.roles,
              })
            }
            actions={[
              {
                icon: "lock",
                text: `Password ändern`,
                onPress: () => {
                  theme.alert({
                    bg: "surface",
                    message: `Password ändern: ${member.emails?.[0]?.address}`,
                    timeout: false,
                    position: "center",
                    closeButton: true,
                    renderContent: ({ close }) => {
                      return (
                        <PasswordForm
                          _id={member._id}
                          onSuccess={() => close?.()}
                        />
                      );
                    },
                  });
                },
              },
            ]}
          />
        );
      })}
      {item?.team?.length > 4 ? (
        <Button size={35} mt={10} onPress={() => setAll(!all)} light rounded>
          {`${all ? "weniger" : "alle"} anzeigen`}
        </Button>
      ) : null}
    </Flex>
  );
}

function Screen({ navigation }) {
  const theme = useTheme();
  const [tab, setTab] = React.useState("Accounts");
  const [Comp, setComp] = React.useState(null);
  const { callMeteor, user } = useAppContext();

  return (
    <Page
      title="Admin"
      leftAction={Comp}
      rightAction={{
        icon: "plus",
        onPress: () =>
          navigation.navigate("Form", {
            title: "Neuer Kunde",
            typename: "User",
            mode: "insert",
            defaultValues: {},
            roles: ["admin"],
          }),
      }}
      navbarProps={{
        footerComponent: (
          <Flex p={5} w="100%">
            <Tabs
              size={35}
              value={tab}
              options={[
                {
                  value: "Accounts",
                  label: "Accounts",
                  icon: "user",
                },
                {
                  value: "Settings",
                  label: "Settings",
                  icon: "settings",
                },
              ]}
              onChange={(value) => setTab(value)}
            />
          </Flex>
        ),
      }}
      useFlatList={tab === "Accounts" ? true : false}
      scrollComponent={tab === "Accounts" ? ListQuery : null}
      scrollProps={{
        renderToSection: (c) => setComp(c),
        filterOptions: {
          "E-Mail": {
            input: "text",
            placeholder: "E-Mail",
            key: "emails.0.address",
            formatFilter: (v) => {
              return { $regex: `.*${v}.*`, $options: "i" };
            },
          },
          Praxis: {
            input: "text",
            placeholder: "Praxis",
            key: "company",
            formatFilter: (v) => {
              return { $regex: `.*${v}.*`, $options: "i" };
            },
          },
          Rolle: {
            key: "roles",
            formatFilter: (v) => {
              return {
                $in: [v],
              };
            },
            options: [
              {
                label: "Admin",
                value: "admin",
              },
              {
                label: "Kunde",
                value: "client",
              },
              {
                label: "Mitarbeiter",
                value: "employee",
              },
              {
                label: "Beobachter",
                value: "reader",
              },
            ],
          },
          Gesperrt: {
            key: "banned",
            formatFilter: (v) => {
              return v === "true" ? true : { $in: [false, null] };
            },
            options: [
              {
                label: "Gesperrt",
                value: "true",
              },
              {
                label: "Nicht gesperrt",
                value: "false",
              },
            ],
          },
        },
        defaultOptions: {
          Rolle: "client",
          Gesperrt: "false",
        },
        renderItem: ({ item }) => {
          console.log({ roles: item?.roles });
          return (
            <ListItem
              title={`${item.emails?.[0]?.address}`}
              desc={`${item.lastName}, ${item.firstName} - Praxis: ${
                item.company
              } ${
                item.joined
                  ? ` - Vertrag ab: ${dayjs(item.joined).format("DD.MM.YYYY")}`
                  : ""
              } ${
                item.lastLogin
                  ? ` - Letzter Login: ${dayjs(item.lastLogin).format(
                      "DD.MM.YYYY"
                    )}`
                  : ""
              }`}
              pills={[
                {
                  label: `${item?.roles.join(", ")}`,
                },
              ]}
              actions={[
                {
                  icon: "lock",
                  text: `Password ändern`,
                  onPress: () => {
                    theme.alert({
                      bg: "surface",
                      message: `Password ändern: ${item.emails?.[0]?.address}`,
                      timeout: false,
                      position: "center",
                      closeButton: true,
                      renderContent: ({ close }) => {
                        return (
                          <PasswordForm
                            _id={item._id}
                            onSuccess={() => close?.()}
                          />
                        );
                      },
                    });
                  },
                },
                {
                  icon: "alert-circle",
                  text: `Account sperren`,
                  onPress: () => {
                    callMeteor({
                      variables: {
                        name: "bannUser",
                        config: {
                          userId: item._id,
                        },
                      },
                    }).then(() => {
                      theme.alert({
                        type: "success",
                        message: "Account gesperrt",
                      });
                    });
                  },
                },
              ]}
              footerComponent={
                item?.team?.length > 0 && item.roles?.indexOf("client") > -1 ? (
                  <Team item={item} navigation={navigation} />
                ) : null
              }
              icons={[{ name: "user" }]}
              onPress={() =>
                navigation.navigate("Form", {
                  id: item._id,
                  title: `${item.emails?.[0]?.address}`,
                  typename: "User",
                  defaultDoc: item,
                  mode: "update",
                  roles: user.roles,
                })
              }
            />
          );
        },
        typename: "User",
        sort: { emails: 1 },
        groupBy: "emails.0.address",
        groupByFormat: ({ key, value }) => value?.toUpperCase().charAt(0),
      }}
    >
      <Flex p={15}>
        <Text font="h5">Globaler Info-Banner</Text>
        <Form
          onSubmit={(doc, reset) => {
            callMeteor({
              variables: {
                name: "setGlobalInfo",
                config: {
                  ...doc,
                },
              },
            }).then(() => {
              theme.alert({
                type: "success",
                message: "Globaler Info gespeichert",
              });
            });
          }}
          defaultDoc={{ globalInfo: user?.globalInfo || "" }}
          buttonProps={{ mt: 10 }}
          buttonLabel="Speichern"
        >
          <Input
            type="text"
            label="Nachricht"
            placeholder="Info-Banner Inhalt"
            field="globalInfo"
            clean
          />
        </Form>
        <Text font="h5" mt={20}>
          Nachricht Senden
        </Text>
        <Form
          onSubmit={(doc, reset) => {
            callMeteor({
              variables: {
                name: "sendToAll",
                config: {
                  ...doc,
                  customFilter:
                    doc.customFilter !== "all"
                      ? { roles: { $all: [doc.customFilter] } }
                      : {},
                },
              },
            }).then(() => {
              theme.alert({ type: "success", message: "Erfolgreich gesendet" });
            });
          }}
          buttonProps={{ mt: 10 }}
          buttonLabel="Senden"
        >
          <Input
            type="select"
            label="An"
            field="customFilter"
            defaultValue={"all"}
            options={[
              { value: "all", label: "Alle" },
              { value: "client", label: "Kunden" },
              {
                value: "employee",
                label: "Mitarbeiter",
              },
              {
                value: "reader",
                label: "Beobachter",
              },
            ]}
            clean
          />
          <Input
            type="text"
            label="Titel"
            placeholder="Titel der Push/Email"
            field="title"
            clean
          />
          <Input
            type="textarea"
            my={10}
            label="Nachricht"
            placeholder="Nachricht der Push/Email"
            field="message"
            clean
          />
        </Form>
      </Flex>
    </Page>
  );
}

export default Screen;

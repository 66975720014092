import * as React from "react";
import { Flex, Text, Tooltip, Button } from "unikit";

import Form from "../form";
import { ListItem } from "../list";

function update(arr, key, updatedData) {
  return arr.map((item) =>
    item.key === key ? { ...item, ...updatedData } : item
  );
}

function move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

export default ({ onChange, value = [], children, doc, ...rest }) => {
  const [state, setState] = React.useState(value);
  const schemaDoc = value.reduce((acc, item) => {
    acc[item.key] = item?.value ?? null;
    return acc;
  }, {});

  React.useEffect(() => {
    onChange(state);
  }, [state]);

  console.log({ value });

  return (
    <Flex w="100%" {...rest}>
      {doc.isDraft ? (
        <>
          <Flex pointerEvents="none" pb={5}>
            <Text font="label">Schema</Text>
          </Flex>
          {state.map((item, index) => {
            return (
              <ListItem
                key={`input-${index}`}
                title={item.label}
                mt={5}
                actionType="inline"
                actions={[
                  {
                    icon: "chevron-up",
                    opacity: index === 0 ? 0.5 : 1,
                    onPress: () => {
                      if (index > 0) {
                        setState((s) => {
                          return move([...s], index, index - 1);
                        });
                      }
                    },
                  },
                  {
                    icon: "chevron-down",
                    opacity: index === state?.length - 1 ? 0.5 : 1,
                    onPress: () => {
                      if (index < state?.length - 1) {
                        setState((s) => {
                          return move([...s], index, index - 1);
                        });
                      }
                    },
                  },
                  {
                    icon: "trash",
                    color: "error",
                    bg: "error:setAlpha:0.1",
                    onPress: () => {
                      setState((s) => {
                        return s.filter((i) => i.key !== item.key);
                      });
                    },
                  },
                ]}
                footerComponent={
                  <>
                    <Form
                      schema={[
                        {
                          key: "label",
                          label: "Label",
                          input: "text",
                        },
                        {
                          key: "readOnly",
                          label: "Nicht editierbar",
                          input: "switch",
                          defaultValue: true,
                        },
                        {
                          key: "input",
                          label: "Input",
                          input: "select",
                          options: [
                            { value: "text", label: "Text" },
                            { value: "Signature", label: "Unterschrift" },
                            { value: "switch", label: "Switch" },
                            { value: "TextEditor", label: "Freitext" },
                            { value: "date", label: "Datum" },
                          ],
                        },
                        {
                          key: "value",
                          label: "Vorgabewert",
                          input: item.input,
                        },
                      ]}
                      defaultDoc={item}
                      groupBy={null}
                      scrollEnabled={false}
                      style={{ width: "100%" }}
                      spacer={false}
                      gap={0}
                      onChangeDoc={(p) => {
                        setState((s) => {
                          return update(s, item.key, {
                            [p.key]: p.value,
                          });
                        });
                      }}
                    />
                  </>
                }
              />
            );
          })}
          <Button
            w="100%"
            mt={5}
            light
            onPress={() => {
              setState((s) => {
                return [
                  ...s,
                  {
                    key: (Math.random() + 1).toString(36).substring(7),
                    label: "text",
                    input: "text",
                    readOnly: false,
                    value: "",
                  },
                ];
              });
            }}
          >
            Feld hinzufügen
          </Button>
        </>
      ) : (
        <Form
          schema={state}
          defaultDoc={schemaDoc}
          groupBy={null}
          scrollEnabled={false}
          style={{ width: "100%" }}
          spacer={false}
          gap={0}
          onChangeDoc={(p) => {
            setState((s) => {
              return update(s, p.key, {
                value: p.value,
              });
            });
          }}
        />
      )}
    </Flex>
  );
};

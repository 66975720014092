import React from "react";
import { Flex, Text, Button, Icon, useTheme } from "unikit";

// import Lottie from "./Lottie";

const NoContent = ({
  title,
  width,
  height = 275,
  desc,
  action,
  actionTitle,
  actionIcon,
  animationData,
  horizontal = false,
  pro = false,
  noAni = false,
  ...rest
}) => {
  const theme = useTheme();

  return (
    <Flex
      w={horizontal ? theme.width : "100%"}
      px={30}
      minHeight={height}
      flexCenter
      {...rest}
    ></Flex>
  );
};

export default NoContent;

import SimpleSchema, { minWidth } from "../simple";

const schema = {
  surveyDate: {
    type: Date,
    label: "Erhebungsdatum",
    defaultValue: function () {
      return new Date();
    },
    group: "Allgemeine Erhebungsdaten",
    input: "date",
    groupOptions: {
      width: minWidth,
      mode: "grid",
    },
  },
  name: {
    type: String,
    label: "Dokumentenname",
    max: 100,
    group: "Allgemeine Erhebungsdaten",
    defaultValue: (props, state) => {
      return props.count + 1 + ". Therapieempfehlung";
    },
  },
  note: {
    type: String,
    label: "Bemerkung",
    optional: true,
    input: "textarea",
    group: "Bemerkung",
    inputProps: {
      hideLabel: true,
    },
  },
  materialIds: {
    type: Array,
    label: "MaterialId",
    optional: true,
    input: "DocList",
    group: "Materialien",
    defaultValue: function () {
      return [];
    },
    inputProps: {
      hideLabel: true,
      typename: "Material",
      allowSearch: true,
      allowCreate: false,
      allowEdit: false,
      title: (item) => {
        return `${item.producer}, ${item.name}`;
      },
      desc: (item) => {
        return `Größe: ${item.size}, Verpackungseinheit: ${item.packagingUnit}`;
      },
      table: {
        schema: [
          { label: "Kategorie", width: 100, key: "category" },
          { label: "Name", width: 100, key: "name" },
          { label: "Größe", width: 70, key: "size" },
          { label: "Anwendung", width: 250, key: "note" },
        ],
      },
      groupBy: "category",
      hideSection: true,
      groups: [
        "Wundreinigung",
        "Lokalanästhesie",
        "Wundfüllung",
        "Wundfüllung + AG",
        "Kollagene/Hyaloron",
        "Wundgaze",
        "Wundrandschutz",
        "Wundabdeckung",
        "Medizinische Hautpflege",
        "Salben",
        "Abpolsterung Druckentlastung",
        "Fixiermaterialen",
        "Kompression",
        "Zusatznahrung",
        "Sonstige",
        "Zubehör",
      ],
    },
  },
  "materialIds.$": {
    type: String,
    listed: false,
  },
  interval: {
    type: Array,
    label: "Intervall",
    optional: true,
    options: [
      { label: "Täglich", value: "daily" },
      { label: "Alle 48 Stunden", value: "every48hours" },
      { label: "Alle 72 Stunden", value: "every72hours" },
      { label: "Montag", value: "monday" },
      { label: "Dienstag", value: "tuesday" },
      { label: "Mittwoch", value: "wednesday" },
      { label: "Donnerstag", value: "thursday" },
      { label: "Freitag", value: "friday" },
      { label: "Samstag", value: "saturday" },
      { label: "Sonntag", value: "sunday" },
    ],
    inputProps: {
      multi: true,
      inline: true,
      row: true,
      hideLabel: true,
      picker: "switch",
    },
    input: "select",
    group: "Intervall",
  },
  "interval.$": {
    type: String,
    listed: false,
  },
  signature: {
    type: String,
    label: "Unterschrift",
    optional: true,
    input: "Signature",
    group: "Unterschrift",
    inputProps: {
      forceRender: true,
    },
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    listed: false,
  },
  patientId: {
    type: String,
    label: "PatientId",
    visible: "false",
    defaultValue: (props) => {
      return props.patientId;
    },
  },
  therapyId: {
    type: String,
    label: "TherapyId",
    visible: "false",
    defaultValue: (props) => {
      return props.therapyId;
    },
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  canceled: {
    type: Boolean,
    label: "Canceled",
    optional: true,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Advices = new Mongo.Collection("advices");
  Advices.schema = simpleSchema;
  Advices.attachSchema(simpleSchema);
  Advices._ensureIndex({ therapyId: 1 });
  Advices._ensureIndex({ patientId: 1 });
  Advices._ensureIndex({ companyId: 1 });
}

export { simpleSchema as AdviceSchema };

export default schema;

import React from "react";
import * as WebBrowser from "expo-web-browser";
import * as Print from "expo-print";

import { Flex, Button, isWeb, useTheme, Tooltip } from "unikit";
import { Page, PDFReader, renderAction, Chat } from "components";
import { useAppContext } from "../../context";

export default function Screen({ route, navigation }) {
  const theme = useTheme();
  const {
    typename,
    id,
    options,
    url = null,
    patientId,
    therapyId,
    withCompanyId,
    fax = false,
  } = route.params;
  const [pdf, setPdf] = React.useState(url);
  const { callMeteor, callMeteorState } = useAppContext();

  React.useEffect(() => {
    if (!url) {
      callMeteor({
        variables: {
          name: "generatePDF",
          config: { type: typename, id, options, fax },
        },
      })
        .then((result) => {
          console.log({ result });
          setPdf(result?.data?.callMeteor?.result);
        })
        .catch((e) => {
          theme.alert({
            type: "error",
            message: e.message,
          });
        });
    }
  }, []);

  // const { data } = useQuery(PDF_QUERY, {
  //   variables: {
  //     type: typename,
  //     id,
  //     options,
  //     skip: url,
  //     fetchPolicy: "network-only",
  //   },
  // });

  // const uri = url || data?.getPdf;

  const pageProps = {
    title: "PDF",
    scrollable: isWeb,
    goBack: navigation.goBack,
    loading: callMeteorState.loading,
    loadingText: "PDF wird generiert",
    rightAction: (
      <Tooltip
        width={400}
        maxHeight={600}
        popover={
          <Chat
            docId={id}
            typeName={typename}
            patientId={patientId}
            therapyId={therapyId}
            withCompanyId={withCompanyId}
            style={{ maxHeight: 500 }}
          />
        }
      >
        {renderAction({ icon: "message-circle" })}
      </Tooltip>
    ),
    navbarProps: {
      footerComponent: (
        <Flex px={10} p={5} row w="100%">
          <Button
            flex={1}
            onPress={() => {
              if (isWeb) {
                window.open(pdf, "_blank");
              } else {
                WebBrowser.openBrowserAsync(pdf);
              }
            }}
            bg="transparent"
            color="primary"
            renderLeft={renderAction({ icon: "cloud", size: 30, mr: 8 })}
          >
            Download
          </Button>
          <Button
            flex={1}
            onPress={() => {
              navigation.push("PDF", {
                ...route.params,
                fax: true,
              });
            }}
            bg="transparent"
            color="primary"
            renderLeft={renderAction({ icon: "file-text", size: 30, mr: 8 })}
          >
            Fax Version
          </Button>
          {isWeb ? null : (
            <Button
              flex={1}
              onPress={() => {
                Print.printAsync({ uri: pdf });
              }}
              bg="transparent"
              color="primary"
              renderLeft={renderAction({ icon: "printer", size: 30, mr: 8 })}
            >
              Drucken
            </Button>
          )}
        </Flex>
      ),
    },
  };

  return (
    <Page {...pageProps} modal={true}>
      {pdf ? (
        <PDFReader
          source={{
            uri: pdf,
          }}
        />
      ) : null}
    </Page>
  );
}

import React, { useCallback, useReducer } from "react";
import { Text, View, TouchableOpacity, ScrollView } from "react-native";
import { color } from "unikit";

const reducer = (state, _action) => !state;

const useForceUpdate = () => {
  const [, dispatch] = useReducer(reducer, true);

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = useCallback((): void => {
    dispatch(null);
  }, [dispatch]);
  return memoizedDispatch;
};

var colors = ["#fb2839", "#0755d4", "#7cf9ac", "#a0aab4", "#fff832"];
var text = ["#FFF", "#FFF", "#000", "#000", "#000"];
var avlon = ["A", "V", "L", "O", "N"];

export default ({ value, options, onChange }) => {
  const forceUpdate = useForceUpdate();
  return (
    <ScrollView
      style={{ width: "100%" }}
      contentContainerStyle={{ minWidth: "100%" }}
      horizontal
    >
      <View style={{ minWidth: "100%", flexDirection: "row" }}>
        <View
          style={{
            minWidth: 50,
            paddingRight: 2,
            paddingTop: 100,
            height: 400,
          }}
        >
          {avlon.map((key, index) => (
            <View
              style={{
                width: "100%",
                justifyContent: "center",
                textAlign: "center",
                paddingLeft: 5,
                paddingRight: 5,
                flex: 1,
                marginTop: 2,
                backgroundColor: colors[index],
              }}
            >
              <Text
                style={{
                  fontSize: 15,
                  color: text[index] || null,
                  textAlign: "center",
                }}
              >
                {key}
              </Text>
            </View>
          ))}
        </View>
        {options.map((data, index) => {
          return (
            <View
              key={index}
              style={{
                flex: 1,
                minWidth: 175,
                paddingRight: 2,
              }}
            >
              <TouchableOpacity
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 100,
                  paddingHorizontal: 15,
                  backgroundColor:
                    value && value[data.name] === true
                      ? color("#9A9EB2").lighten(15).toString()
                      : color("#9A9EB2").lighten(30).toString(),
                }}
                onPress={() => {
                  const newValue = value || {};
                  newValue[data.name] = newValue[data.name] ? false : true;
                  onChange(newValue);
                  forceUpdate();
                }}
              >
                <Text style={{ fontSize: 14, textAlign: "center" }}>
                  {data.label}
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    marginTop: 5,
                    textAlign: "center",
                    maxWidth: 150,
                  }}
                >
                  {data.sublabel}
                </Text>
              </TouchableOpacity>
              {data.options.map((subData, subIndex) => {
                return (
                  <TouchableOpacity
                    key={subIndex}
                    style={{
                      width: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingLeft: 5,
                      paddingRight: 5,
                      flex: 1,
                      marginTop: 2,
                      backgroundColor:
                        value && value[subData.name] === true
                          ? colors[subIndex]
                          : color(colors[subIndex]).lighten(20).toString(),
                    }}
                    onPress={() => {
                      const newValue = value || {};
                      newValue[subData.name] = newValue[subData.name]
                        ? false
                        : true;
                      onChange(newValue);
                      forceUpdate();
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 10,
                        color: text[subIndex] || null,
                        textAlign: "center",
                        maxWidth: 150,
                      }}
                    >
                      {subData.label}
                    </Text>
                  </TouchableOpacity>
                );
              })}
            </View>
          );
        })}
      </View>
    </ScrollView>
  );
};

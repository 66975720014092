import * as React from "react";
import { Flex, Icon, Animate, Tabs, useTheme, Select, Button } from "unikit";
import dayjs from "dayjs";

import {
  Page,
  List,
  ListQuery,
  ListItem,
  confirm,
  renderAction,
} from "components";

import { useHandleDoc } from "api";
import { getTherapyName, getDocLock } from "../../clouddoku/lib/helper";
import getSchemaByType from "../../clouddoku/lib/schemas";

import { useAppContext } from "../../context";
import i18n from "../../i18n";
import TherapyCharts from "./therapyCharts";

function Screen({ route, navigation }) {
  const { patientId, therapyId } = route?.params || {};
  const theme = useTheme();
  const { handleDoc } = useHandleDoc();
  const { setSharing, user } = useAppContext();
  const [tab, setTab] = React.useState("Dokumente");
  const [state, setState] = React.useState({});
  const [woundImages, setWoundImages] = React.useState([]);
  const [visible, setVisible] = React.useState({
    WoundSummary: true,
    Anamnese: true,
    WoundImage: true,
    DeliveryNote: true,
    Advice: true,
    DoctorsLetter: true,
    Document: true,
  });
  const { patient, therapy, title } = route?.params || {};

  const getName = (typename) => {
    const typeCount = state?.[typename]?.count || 0;
    if (typename === "Anamnese" && typeCount === 0) {
      return `1. Anamnese`;
    } else {
      return `${typeCount + 1}. ${i18n.t(`types.${typename.toLowerCase()}`)}`;
    }
  };

  const getListData = (typename, props) => {
    return {
      actionType: "inline",
      height: 60,
      onPress: () => setVisible((v) => ({ ...v, [typename]: !v[typename] })),
      renderLeft: (
        <Animate
          to={{ rotate: visible[typename] ? "90deg" : "0deg" }}
          w={20}
          h={40}
          ml={15}
          center
        >
          <Icon name="chevron-right" />
        </Animate>
      ),
      actions: [
        ...(typename === "WoundImage"
          ? [
              {
                icon: "printer",
                actionType: "tooltip",
                tooltipComponent: (
                  <>
                    <Select
                      value={woundImages}
                      onChange={setWoundImages}
                      options={state?.WoundImage?.data?.map((w) => ({
                        value: w._id,
                        label: w.name,
                      }))}
                      multi
                      inline
                      hideLabel
                      picker="switch"
                    />
                    <Button
                      onPress={() => {
                        navigation.navigate("PDF", {
                          typename: "WoundImage",
                          id: woundImages[0],
                          options: JSON.stringify(woundImages.slice(1)),
                          patientId: patientId,
                          therapyId: therapyId,
                        });
                      }}
                      mt={5}
                      rounded
                    >
                      Auswahl drucken
                    </Button>
                  </>
                ),
              },
            ]
          : []),
        {
          icon: "plus",
          bg: "primary",
          color: "#FFF",
          onPress: () => {
            const firstAnamnese =
              typename === "Anamnese" &&
              state?.Anamnese?.data?.length === 0 &&
              patient.anamnese;
            const newAnamnese = {};
            if (firstAnamnese) {
              let done = false;
              Object.keys(getSchemaByType("Anamnese").schema).map((key) => {
                if (done === false && patient.anamnese[key]) {
                  newAnamnese[key] = patient.anamnese[key];
                }
                if (key === "stressFactorNote") {
                  done = true;
                }
              });
              newAnamnese["age"] = new Date();
              theme.alert({
                type: "success",
                message: "Daten aus letzter Erhebung automatisch übernommen",
              });
            }
            navigation.push("Form", {
              title: `${i18n.t(`types.${typename.toLowerCase()}`)} hinzufügen`,
              mode: "insert",
              refetchQueries: [`${typename.toLowerCase()}s`],
              typename,
              defaultDoc: {
                ...(props?.defaultDoc || {}),
                ...(firstAnamnese ? newAnamnese : {}),
                name: getName(typename),
                patientId: therapy.patientId,
                therapyId: therapy._id,
              },
              queryProps: {
                filter: { therapyId: therapy._id },
                ...(props?.filter || {}),
              },
            });
          },
        },
      ],
    };
  };

  const getSubListData = (typename, r) => ({
    typename: typename,
    filter: { therapyId: therapy._id },
    sort: { surveyDate: -1 },
    name: `${typename.toLowerCase()}s`,
    scrollEnabled: false,
    renderItem: (p) => renderItem(p),
    onLoad: ({ typename, count, data }) => {
      setState((s) => ({ ...s, [typename]: { count, data } }));
    },
    ...r,
  });

  const shareDoc = (item, options) => {
    setSharing({
      patientName: `${patient.lastName}, ${patient.firstName}`,
      patientId: item.patientId,
      doc: {
        _id: item._id,
        type: item.__typename,
        name: options === "recipe" ? "Rezeptanforderung" : item.name,
        patientId: item.patientId,
        therapyId: item.therapyId,
        therapyName: getTherapyName(therapy),
        surveyDate: item.surveyDate,
        options: options,
      },
    });
  };

  const renderItem = ({ item }, rest = {}) => {
    const isLocked = getDocLock(user.docLock, item.createdAt) === false;
    return (
      <ListItem
        title={`${item.name}`}
        desc={`Erhoben am ${dayjs(item.surveyDate).format("DD. MMMM YYYY")}`}
        icons={
          item.image && !item.canceled
            ? undefined
            : [
                { name: rest.icon || "clipboard" },
                ...(isLocked ? [{ name: "lock", color: "error", ml: -5 }] : []),
              ]
        }
        onPress={() => {
          navigation.push("Form", {
            title: `${item.name} bearbeiten`,
            id: item._id,
            defaultDoc: item,
            mode: "update",
            typename: item.__typename,
          });
        }}
        image={
          item.image && !item.canceled ? { uri: item.image.url } : undefined
        }
        canceled={item.canceled}
        actions={[
          {
            icon: "copy",
            text: "Dokument duplizieren",
            onPress: () => {
              const copyItem = { ...item };
              if (item.__typename === "DeliveryNote") {
                delete copyItem?.files;
                delete copyItem?.recipe_received;
                delete copyItem?.material_send;
                delete copyItem?.recipe_requested;
              }
              navigation.push("Form", {
                defaultDoc: {
                  ...copyItem,
                  surveyDate: new Date(),
                  createdAt: new Date(),
                  updatedAt: new Date(),
                  createdBy: user._id,
                  name: getName(item.__typename),
                },
                mode: "insert",
                typename: item.__typename,
                mode: "insert",
                refetchQueries: [`${item?.__typename?.toLowerCase()}s`],
              });
            },
          },
          {
            icon: "share",
            text: "Dokument teilen",
            onPress: () => {
              shareDoc(item);
            },
          },
          {
            icon: "file-text",
            text: "PDF erstellen",
            onPress: () => {
              navigation.navigate("PDF", {
                typename: item.__typename,
                id: item._id,
                options: undefined,
                patientId: item.patientId,
                therapyId: item.therapyId,
              });
            },
          },
          ...(item.__typename === "DeliveryNote"
            ? [
                {
                  icon: "file-text",
                  text: "Zu Therapieempfehlung weiterführen",
                  onPress: () => {
                    navigation.push("Form", {
                      defaultDoc: {
                        name: getName("Advice"),
                        materialIds: item?.materials?.map((m) => m._id),
                        patientId: item.patientId,
                        therapyId: therapy._id,
                      },
                      mode: "inser",
                      typename: "Advice",
                      mode: "insert",
                      refetchQueries: ["advices"],
                    });
                  },
                },
                {
                  icon: "file-text",
                  text: "Rezeptanforderung PDF",
                  onPress: () => {
                    navigation.navigate("PDF", {
                      typename: item.__typename,
                      id: item._id,
                      options: "recipe",
                      patientId: item.patientId,
                      therapyId: item.therapyId,
                    });
                  },
                },
                {
                  icon: "file-text",
                  text: "Materialabtrag buchen",
                  onPress: () => {
                    navigation.navigate("Change", {
                      title: "Entnahme",
                      defaultDoc: {
                        type: "warehouse_out",
                        materials: item.materials,
                        warehouseFromId: "warehouse",
                      },
                    });
                  },
                },
                {
                  icon: "file-text",
                  text: "Rezeptanforderung teilen",
                  onPress: () => {
                    shareDoc(item, "recipe");
                  },
                },
              ]
            : []),
          ...(!item.canceled
            ? [
                {
                  icon: "x",
                  text: `Dokument stornieren`,
                  bg: "error:setAlpha:0.1",
                  color: "error",
                  onPress: () => {
                    confirm("Dokument wirklich stornieren?", () =>
                      handleDoc({
                        doc: { canceled: true },
                        typename: item.__typename,
                        id: item._id,
                        mode: "update",
                      })
                    );
                  },
                },
              ]
            : []),
        ]}
        {...rest}
      />
    );
  };

  return (
    <Page
      goBack={navigation.goBack}
      title={title}
      rightAction={{
        icon: "more-vertical",
        actionType: "tooltip",
        actions: [{ icon: "bar-chart", text: "Wundverlauf" }],
      }}
      useFlatList={tab === "Dokumente"}
      scrollComponent={tab === "Dokumente" ? List : undefined}
      navbarProps={{
        footerComponent: (
          <Flex p={5} w="100%">
            <Tabs
              size={35}
              value={tab}
              options={["Dokumente", "Wundverlauf"]}
              onChange={(value) => setTab(value)}
            />
          </Flex>
        ),
      }}
      scrollProps={{
        sections: true,
        data: [
          {
            title: "clouddoku Shorts",
            ...getListData("WoundSummary", {
              defaultDoc: {
                anamneseId: state?.Anamnese?.data?.[0]?._id,
                woundImageId: state?.WoundImage?.data?.[0]?._id,
                adviceId: state?.Advice?.data?.[0]?._id,
              },
            }),
            data: [
              {
                ...getSubListData("WoundSummary"),
              },
            ],
          },
          {
            title: "Anamnesen",
            ...getListData("Anamnese"),
            data: [
              {
                ...getSubListData("Anamnese"),
              },
            ],
          },
          {
            title: "Wundbilder",
            ...getListData("WoundImage"),

            data: [
              {
                ...getSubListData("WoundImage", {
                  renderItem: (p) => renderItem(p, { icon: "image" }),
                }),
              },
            ],
          },
          {
            title: "Lieferscheine",
            actions: [{ icon: "plus", bg: "primary", color: "#FFF" }],
            ...getListData("DeliveryNote", {
              filter: { patientId: patient?._id },
            }),
            data: [
              {
                ...getSubListData("DeliveryNote", {
                  renderItem: (p) => renderItem(p, { icon: "package" }),
                  filter: { patientId: patient?._id },
                }),
              },
            ],
          },
          {
            title: "Therapieempfehlung",
            actions: [{ icon: "plus", bg: "primary", color: "#FFF" }],
            ...getListData("Advice"),
            data: [
              {
                ...getSubListData("Advice"),
              },
            ],
          },
          {
            title: "Arztbrief",
            actions: [{ icon: "plus", bg: "primary", color: "#FFF" }],
            ...getListData("DoctorsLetter"),
            data: [
              {
                ...getSubListData("DoctorsLetter"),
              },
            ],
          },
          {
            title: "Weitere Dokumente",
            actions: [{ icon: "plus", bg: "primary", color: "#FFF" }],
            ...getListData("Document", {
              filter: { patientId: patient?._id },
            }),
            data: [
              {
                ...getSubListData("Document", {
                  filter: { patientId: patient?._id },
                }),
              },
            ],
          },
        ],
        renderItem: ({ item }) => {
          if (visible[item.typename] === false) return null;
          return (
            <Flex w="100%" py={2.5}>
              <ListQuery gap={0} limit={5} {...item} />
            </Flex>
          );
        },
      }}
    >
      <TherapyCharts
        anamnesen={state?.Anamnese?.data || []}
        woundImages={state?.WoundImage?.data || []}
      />
    </Page>
  );
}

export default Screen;

import * as React from "react";

import {
  Tooltip,
  Touchable,
  Flex,
  Page,
  Text,
  Icon,
  ImageBackground,
  isAndroid,
  Progress,
  BurgerMenu,
} from "unikit";
import GyroscopeAnimation from "./GyroscopeAnimation";

export const renderAction = (a, index, onPressProps = {}) => {
  if (!a) return null;
  const {
    size = 44,
    onPress,
    text,
    icon,
    iconLabel,
    color,
    bg,
    actionType,
    actions,
    toggleTooltip,
    font,
    loading = false,
    tooltipComponent = null,
    ...rest
  } = a;
  const Comp = onPress ? Touchable : Flex;
  if (actionType === "tooltip") {
    return (
      <Tooltip
        useScrollView
        popover={({ toggleTooltip }) => (
          <>
            {tooltipComponent}
            {actions?.map(({ text, ...r }, i) => (
              <>
                <Touchable
                  onPress={() => {
                    r.onPress?.(onPressProps);
                    toggleTooltip?.();
                  }}
                  alignItems="center"
                  borderBottomWidth={i === actions.length - 1 ? 0 : 1}
                  borderColor="primary:setAlpha:0.1"
                  pb={i === actions.length - 1 ? 5 : 10}
                  pt={i === 0 ? 5 : 10}
                  row
                >
                  {renderAction({ ...r, ml: 5, toggleTooltip }, i)}
                  <Text color={r?.textColor || "text"} ml={10}>
                    {text}
                  </Text>
                </Touchable>
              </>
            ))}
          </>
        )}
        key={`action-${index || "undefined"}`}
      >
        {renderAction({ size, onPress, text, icon, color, bg })}
      </Tooltip>
    );
  }
  return (
    <Comp
      key={`action-${index || "undefined"}`}
      w={text ? "auto" : size}
      h={size}
      borderRadius={22}
      bg={bg || "primary:setAlpha:0.1"}
      center
      onPress={() => {
        if (loading === false) {
          onPress?.(onPressProps);
          toggleTooltip?.();
        }
      }}
      row
      p={text ? 10 : 0}
      ml={index !== undefined && index > 0 ? 10 : 0}
      {...rest}
    >
      {loading ? (
        <Progress size={20} trackWidth={1.5} progressWidth={1.5} loading />
      ) : (
        <>
          <Flex h={size} w={size} relative center>
            <Icon name={icon} size={size * 0.5} color={color} />
            {iconLabel ? (
              <Flex
                bg={`${bg}:setAlpha:1`}
                borderRadius={10}
                px={5}
                py={2.5}
                t={-7.5}
                r={-5}
                zIndex={50}
                absolute
                center
              >
                <Text color="#FFF" font="label" bold>
                  {iconLabel}
                </Text>
              </Flex>
            ) : null}
          </Flex>
          {text ? (
            <Text pr={10} font="font" color={color || "text"}>
              {text}
            </Text>
          ) : null}
        </>
      )}
    </Comp>
  );
};

export default ({
  header = true,
  children,
  loading = false,
  loadingText,
  title,
  desc,
  goBack,
  leftAction,
  rightAction,
  scrollable = true,
  modal = false,
  navbarProps = {},
  pageRef,
  titleLeft,
  background,
  burger = true,
  ...rest
}) => {
  return (
    <>
      <Page
        bg="primary:setAlpha:0.1"
        ref={pageRef}
        titleLeft={titleLeft}
        title={title}
        subTitle={desc}
        rightAction={
          React.isValidElement(rightAction)
            ? rightAction
            : renderAction(rightAction)
        }
        leftAction={
          <Flex row>
            {isAndroid ? <BurgerMenu inline /> : null}
            {goBack
              ? renderAction({
                  icon: modal ? "arrow-down" : "arrow-left",
                  onPress: goBack,
                  mr: 5,
                })
              : null}
            {React.isValidElement(leftAction)
              ? leftAction
              : Array.isArray(leftAction)
              ? leftAction.map((action, i) => renderAction(action, i))
              : renderAction(leftAction)}
          </Flex>
        }
        disableSafeArea={modal}
        scrollable={scrollable}
        borderRadius={0}
        loading={loading}
        loadingText={loadingText}
        navbarProps={{
          offset: 10,
          gradient: false,
          bg: "surface",
          animateBg: false,
          color: "text",
          animateTitleColor: false,
          borderBottomWidth: 1,
          borderColor: "rgba(0,0,0,0.1)",
          ...navbarProps,
        }}
        {...rest}
      >
        {background ? (
          <Flex w="100%" h="100%" overflow="hidden" bg="primary" absoluteFill>
            <GyroscopeAnimation>
              <ImageBackground source={background} w="100%" h="100%" />
            </GyroscopeAnimation>
          </Flex>
        ) : null}
        {children}
      </Page>
    </>
  );
};

import SimpleSchema from "simpl-schema";

const minWidth = 400;

export { minWidth };

SimpleSchema.extendOptions([
  "typeName",
  "listed",
  "listedPDF",
  "input",
  "group",
  "visible",
  "listed",
  "steps",
  "options",
  "readonly",
  "multiple",
  "multi",
  "placeholder",
  "roles",
  "min",
  "max",
  "ticks",
  "inputOptions",
  "inputProps",
  "subKey",
  "inputGroup",
  "inputGroupWidth",
  "groupOptions",
]);

export default SimpleSchema;

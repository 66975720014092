import React, { useState, useEffect } from "react";
import { ScrollView, Platform } from "react-native";
import * as WebBrowser from "expo-web-browser";
import * as Print from "expo-print";
import * as DocumentPicker from "expo-document-picker";
import * as FileSystem from "expo-file-system";

import ImageModal from "../imagemodal";

import { Progress, Flex, Touchable, useTheme, Icon, Button } from "unikit";

import PdfPreview from "../pdf/PdfPreview";
import { useAppContext } from "../../context";
import { useMutation, UPLOAD_FILE, DELETE_FILE } from "../../apollo";
import { ListItem } from "../list";
import { renderAction } from "../page";

const File = ({ value, inputKey, onChange, multiple, navigation }) => {
  const { callMeteor } = useAppContext();
  //alert(inputKey);
  const theme = useTheme();

  const [load, setLoad] = useState([]);
  const [list, setList] = useState([]);
  const [uploadFile, { loading }] = useMutation(UPLOAD_FILE);
  const [deleteFile] = useMutation(DELETE_FILE);

  useEffect(() => {
    const getFiles = async () => {
      setLoad(true);
      if (!value) {
        setLoad(false);
      } else if (typeof value === "object") {
        await value.map(async (id) => {
          const result = await callMeteor({
            variables: {
              name: "getSignedURL",
              config: { fileId: id },
            },
          });

          setList((l) => [...l, result?.data?.callMeteor.result]);
        });
        setLoad(false);
      } else {
        const result = await callMeteor({
          variables: {
            name: "getSignedURL",
            config: { fileId: value },
          },
        });
        setLoad(false);
        if (result?.data?.callMeteor?.result !== null) {
          setList([result.data.callMeteor.result]);
        }
      }
    };
    getFiles();
  }, []);

  const upload = (base64) => {
    uploadFile({
      variables: {
        base64,
      },
    })
      .then((result) => {
        if (result.data) {
          var item = result.data.uploadFile;

          if (multiple) {
            setList((l) => [...l, { url: item.url, name: item.name }]);
            var newValue = [...(value || [])];
            newValue.push(item._id);
            onChange(newValue);
          } else {
            setList([...[{ url: item.url, name: item.name }]]);
            onChange(item._id);
            if (inputKey === "imageFileId") {
              onChange(
                {
                  _id: result.data.uploadFile._id,
                  url: result.data.uploadFile.url,
                  name: result.data.uploadFile.name,
                  key: result.data.uploadFile.key,
                },
                "image"
              );
            }
          }
        } else {
          alert("Ein Fehler ist aufgetreten");
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const size = 140;

  const actions = (item) => [
    {
      icon: "copy",
      text: "Download",
      onPress: () => {
        WebBrowser.openBrowserAsync(item.url);
      },
    },
    {
      icon: "printer",
      text: "Print",
      onPress: () => {
        Print.printAsync({ uri: item.url });
      },
    },
    {
      icon: "trash",
      text: "Delete",
      bg: "error:setAlpha:0.1",
      color: "error",
      onPress: () => {
        deleteFile({
          variables: {
            _id: item._id,
          },
        })
          .then((result) => {
            var newList = list;
            newList = newList.filter((e) => e._id !== item._id);
            setList(newList);
            if (multiple) {
              var newValue = value || [];
              newValue = newValue.filter((e) => e !== item._id);
              onChange(newValue, undefined, true);
            } else {
              onChange("");
            }
          })
          .catch((error) => {
            alert(error);
          });
      },
    },
  ];

  return (
    <>
      {(loading && multiple) || (load && multiple) ? (
        <Flex w="100%" h={100} align="center" content="center">
          <Progress size={30} trackWidth={5} progressWidth={3} loading />
        </Flex>
      ) : null}
      {!multiple ? (
        list?.[0] ? (
          <ListItem
            image={{ uri: list?.[0]?.url }}
            title={list?.[0]?.url}
            actions={actions(list?.[0])}
            borderRadius={7}
            h={55}
            wrapperProps={{ my: 0 }}
          />
        ) : (
          <Button
            onPress={() => {
              navigation.navigate("File", {
                onSelect: (base64) => {
                  upload(base64);
                },
              });
            }}
            renderLeft={renderAction({
              icon: "plus",
              bg: "surface",
              mr: 10,
              size: 35,
            })}
            size={55}
            loading={loading}
            light
          >
            Datei wählen
          </Button>
        )
      ) : (
        <ScrollView
          style={{ width: "100%" }}
          showsHorizontalScrollIndicator={false}
          horizontal
        >
          <Flex row>
            {multiple || list.length === 0 ? (
              <Touchable
                bg="primary:setAlpha:0.1"
                w={size + theme.globals.gap}
                h={size + theme.globals.gap}
                p={theme.globals.gap / 2}
                borderRadius={theme.globals.roundness}
                mr={theme.globals.gap / 2}
                flexCenter
                onPress={() =>
                  theme.alert({
                    type: "surface",
                    position: "bottom",
                    actionSheet: true,
                    component: "ActionSheet",
                    backdrop: true,
                    actions: [
                      {
                        icon: "image",
                        label: "Bild auswählen",
                        onPress: () => {
                          navigation.push("File", {
                            onSelect: (base64) => {
                              upload(base64);
                            },
                          });
                        },
                      },
                      ...(multiple
                        ? [
                            {
                              icon: "fileText",
                              label: "PDF auswählen",
                              onPress: () => {
                                async function pick() {
                                  const result =
                                    await DocumentPicker.getDocumentAsync({
                                      type: "application/pdf",
                                    });

                                  if (Platform.OS !== "web") {
                                    const base64 =
                                      await FileSystem.readAsStringAsync(
                                        result.uri,
                                        {
                                          encoding:
                                            FileSystem.EncodingType.Base64,
                                        }
                                      );
                                    upload(
                                      `data:application/pdf;base64,${base64}`
                                    );
                                  } else {
                                    const base64 = result.uri;
                                    upload(base64);
                                  }
                                }
                                pick();
                              },
                            },
                          ]
                        : []),
                    ],
                  })
                }
              >
                <Icon name="plus" color="primary" />
              </Touchable>
            ) : null}
            {list.map((item, i) => {
              if (!item) return null;
              return (
                <Flex
                  key={`file-${i}`}
                  bg="input"
                  p={theme.globals.gap / 2}
                  borderRadius={theme.globals.roundness}
                  mr={theme.globals.gap / 2}
                >
                  {item.type === "application/pdf" ? (
                    <Touchable
                      w={size}
                      h={size}
                      overflow="hidden"
                      borderRadius={theme.globals.roundness}
                      onPress={() => {
                        navigation.push("PDF", {
                          url: item.url,
                        });
                      }}
                    >
                      <PdfPreview source={{ uri: item.url }} pages={false} />
                    </Touchable>
                  ) : (
                    <Flex w={size} h={size} relative>
                      <ImageModal
                        source={{ uri: item.url }}
                        modalImageResizeMode="contain"
                        resizeMode="cover"
                        style={{
                          width: size,
                          height: size,
                          borderRadius: theme.globals.roundness,
                        }}
                      />
                      <Icon
                        name="zoom-in"
                        size={24}
                        absolute
                        l={17}
                        t={17}
                        color="#FFF"
                        pointerEvents="none"
                      />
                    </Flex>
                  )}
                  <Flex absolute t={5} r={5} zIndex={999}>
                    {renderAction({
                      actionType: "tooltip",
                      icon: "more-vertical",
                      actions: actions(item),
                    })}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </ScrollView>
      )}
    </>
  );
};

export default File;

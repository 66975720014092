import * as React from "react";
import { Page, ListQuery, ListItem } from "components";
import dayjs from "dayjs";

import { useAppContext } from "../../context";

function Screen({ navigation }) {
  const { user } = useAppContext();

  return (
    <Page
      title="Eigene Dokumente"
      rightAction={{
        icon: "plus",
        onPress: () =>
          navigation.navigate("Form", {
            typename: "OwnDoc",
            defaultDoc: {
              isDraft: true,
              schema: [
                {
                  key: "text",
                  label: "Einverständiserklärung",
                  input: "TextEditor",
                  readOnly: true,
                  value: "<h1>Überschrift</h1><p>Text</p>",
                },
                {
                  key: "signature",
                  label: "Unterschrift",
                  input: "Signature",
                  readOnly: false,
                },
              ],
            },
            queryProps: { filter: { companyId: user.companyId } },
          }),
      }}
      useFlatList={true}
      scrollComponent={ListQuery}
      scrollProps={{
        loadMoreMode: "scroll",
        renderItem: ({ item }) => (
          <ListItem
            title={`${item.name}`}
            desc={`Bearbeitet am ${dayjs(item.updatedAt).format(
              "DD. MMMM YYYY"
            )}`}
            pills={[{ bg: "primary", color: "#FFF", label: "Vorlage" }]}
            icons={[{ name: "file-text" }]}
            onPress={() =>
              navigation.navigate("Form", {
                id: item._id,
                defaultDoc: item,
                title: `${item.name} bearbeiten`,
                typename: "OwnDoc",
                mode: "update",
              })
            }
          />
        ),
        typename: "OwnDoc",
        filter: { companyId: user.companyId, isDraft: true },
        sort: { createdAt: -1 },
        name: "owndocs",
      }}
    />
  );
}

export default Screen;

import * as React from "react";
import { useColorScheme } from "react-native";
import Icon from "../Icon";
import Tabs from "../Tabs";

import { withThemeProps } from "../../style";
import { useAsyncStorage } from "../../hooks";

export function ThemeSwitch({ theme, size = 34, ...rest }) {
  const colorScheme = useColorScheme();

  const [value, setValue] = useAsyncStorage("color_scheme", colorScheme);

  React.useEffect(() => {
    if (theme.mode !== value) {
      theme?.setMode(value);
    }
  }, [value]);

  return (
    <Tabs
      value={value}
      options={[
        {
          value: "light",
          label: ({ color, active }: any) => (
            <Icon name="sun" size={size * 0.5} color={color} animate={active} />
          ),
        },
        {
          value: "dark",
          label: ({ color, active }: any) => (
            <Icon
              name="moon"
              size={size * 0.5}
              color={color}
              animate={active}
            />
          ),
        },
      ]}
      bg="primary:darken:5"
      indicatorColor="primary"
      activeColor="#FFF"
      inactiveColor="#FFF:setAlpha:0.5"
      onChange={(v: boolean) => {
        setValue(v);
      }}
      size={size}
      shadow={5}
      width={size * 2.5}
      roundness={size / 2}
      tabProps={{ ripple: true }}
      {...rest}
    />
  );
}

export default withThemeProps(ThemeSwitch, "ThemeSwitch");

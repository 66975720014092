import * as React from "react";
import { Flex, Text, Input } from "unikit";
import dayjs from "dayjs";

import { Page, List, ListItem } from "components";

import i18n from "../../i18n";

function Screen({ route, navigation }) {
  const { patientName, note, documents, companyId } = route?.params || {};

  return (
    <Page
      goBack={navigation.goBack}
      title={`${patientName}`}
      useFlatList={true}
      scrollComponent={List}
      scrollProps={{
        ListHeaderComponent: () => (
          <Flex p={10}>
            <Text font="h5" mb={10}>{`Anmerkung`}</Text>
            <Input
              type="textarea"
              value={note}
              placeholder="keine Anmerkung vorhanden"
              editable={false}
            />
          </Flex>
        ),
        data: documents,
        groupBy: "type",
        groupByFormat: ({ value }) =>
          `${i18n.t(`types.${value?.toLowerCase()}_plural`)}`,
        renderItem: ({ item }) => {
          return (
            <ListItem
              title={`${item.name}`}
              desc={`${
                item.surveyDate
                  ? `Erhebungsdatum ${dayjs(item.surveyDate).format(
                      "DD.MM.YYYY"
                    )}`
                  : ""
              }`}
              actions={[{ icon: "printer" }]}
              actionType="inline"
              onPress={() => {
                navigation.navigate("PDF", {
                  title: item.name,
                  typename: item.type,
                  id: item._id,
                  options: item.options,
                  patientId: item.patientId,
                  therapyId: item.therapyId,
                  withCompanyId: companyId,
                });
              }}
            />
          );
        },
      }}
    />
  );
}

export default Screen;

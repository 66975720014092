import * as React from "react";
import { Page, ListQuery, ListItem } from "components";
import dayjs from "dayjs";

import { useAppContext } from "../../context";
import { isWeb } from "unikit";
import { share } from "components";

function Screen({ navigation }) {
  const { user, callMeteor, callMeteorState } = useAppContext();

  return (
    <Page
      title="Kontakte"
      leftAction={{
        icon: "search",
        onPress: () =>
          navigation.navigate("Search", {
            typename: "Contact",
          }),
      }}
      rightAction={{
        icon: "more-vertical",
        actionType: "tooltip",
        loading: callMeteorState.loading,
        actions: [
          {
            icon: "plus",
            text: "Neu",
            onPress: () =>
              navigation.navigate("Form", {
                typename: "Contact",
                queryProps: { filter: { companyId: user.companyId } },
              }),
          },
          {
            icon: "save",
            text: "Export CSV",

            onPress: () => {
              callMeteor({
                variables: {
                  name: "exportCollection",
                  config: { typename: "Contact" },
                },
              }).then((result) => {
                if (isWeb) {
                  const encodedUri = encodeURI(
                    "data:text/csv;charset=utf-8," +
                      result.data.callMeteor.result
                  );
                  const link = document.createElement("a");
                  link.setAttribute("href", encodedUri);
                  link.setAttribute("download", "cd-kontakte.csv");
                  document.body.appendChild(link); // Required for FF
                  link.click();
                } else {
                  share({
                    url: result.data.callMeteor.result,
                    filename: "cd-kontakte.csv",
                    options: {
                      UTI: ".csv",
                      mimeType: "text/csv",
                    },
                  });
                }
              });
            },
          },
        ],
      }}
      useFlatList={true}
      scrollComponent={ListQuery}
      scrollProps={{
        loadMoreMode: "scroll",
        renderItem: ({ item }) => (
          <ListItem
            title={`${item.name}`}
            desc={`Bearbeitet am ${dayjs(item.updatedAt).format(
              "DD. MMMM YYYY"
            )}`}
            icons={[{ name: "user" }]}
            onPress={() =>
              navigation.navigate("Form", {
                id: item._id,
                defaultDoc: item,
                title: `${item.name} bearbeiten`,
                typename: "Contact",
                mode: "update",
              })
            }
          />
        ),
        typename: "Contact",
        filter: { companyId: user.companyId },
        sort: { name: 1 },
        name: "contacts",
        groupBy: "name",
        groupByFormat: ({ key, value }) => value.toUpperCase().charAt(0),
      }}
    />
  );
}

export default Screen;

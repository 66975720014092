import * as React from "react";
import { Page, Form, confirm } from "components";

import i18n from "../../i18n";

function Screen({ route, navigation }) {
  const formRef = React.useRef(null);
  const { title, typename, mode = "insert", customMode } = route.params || {};
  const [hasChange, setChanges] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  console.log({ hasChange });

  React.useEffect(
    () =>
      navigation.addListener("beforeRemove", (e) => {
        if (!hasChange) {
          // If we don't have unsaved changes, then we don't need to do anything
          return;
        }

        // Prevent default behavior of leaving the screen
        e.preventDefault();

        // Prompt the user before leaving the screen
        confirm("Möchtest du das Dokument verlassen ohne zu speichern?", () => {
          navigation.dispatch(e.data.action);
        });
      }),
    [navigation, hasChange]
  );

  const submitIcon = {
    Share: "send",
  };

  return (
    <Page
      title={
        title ||
        `${i18n.t(`types.${typename.toLowerCase()}`)} ${
          mode === "insert" ? "erstellen" : "bearbeiten"
        }`
      }
      goBack={navigation.goBack}
      rightAction={{
        icon: submitIcon?.[typename] || "check",
        onPress: () => formRef?.current?.submit?.(),
        loading,
      }}
      useFlatList={true}
      scrollComponent={Form}
      scrollProps={{
        mode,
        customMode,
        onChangeDoc: ({ doc }) => {
          setChanges(true);
          return doc;
        },
        onLoading: (l) => {
          if (l === true) {
            setLoading(l);
          }
        },
        typename: "Patient",
        ref: formRef,
        ...route.params,
        onSuccess: ({ navigation, item, client }) => {
          setChanges(false);
          setTimeout(() => {
            if (route.params?.onSuccess) {
              route.params?.onSuccess?.({ navigation, item, client, mode });
            } else {
              navigation.goBack();
            }
            setLoading(false);
          }, 200);
        },
        onError: () => {
          setLoading(false);
        },
      }}
    />
  );
}

export default Screen;

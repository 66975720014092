// @flow
import * as React from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Progress, Flex } from "unikit";

export default function PDF({ source, pages = true, width, ...rest }) {
  const [numPages, setNumPages] = React.useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <Document
      file={source.uri}
      loading={
        <Flex w="100%" py={50} center>
          <Progress size={30} trackWidth={3} progressWidth={3} loading />
        </Flex>
      }
      error="PDF konnte nicht geladen werden"
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(Array(numPages).keys()).map((page) => {
        return (
          <Page width={width} className="pdf-page" pageNumber={page + 1} />
        );
      })}
    </Document>
  );
}

import * as React from "react";
import { useTheme, Flex, Input } from "unikit";

import { ListItem, ListQuery, useTypeQuery } from "../list";

export default ({
  onChange,
  value = [],
  inputKey,
  title,
  desc,
  note,
  noteProps,
  typename,
  placeholder,
  getFilter,
}) => {
  const [search, setSearch] = React.useState("");
  const theme = useTheme();

  console.log("DocList", { value, inputKey, typename });

  const renderItem = ({ item, index }) => {
    return (
      <ListItem
        title={title?.(item)}
        desc={desc?.(item)}
        note={note?.(item)}
        noteProps={noteProps}
        onPress={() => {
          setSearch("");
          onChange(item._id);
        }}
      />
    );
  };

  return (
    <Flex w="100%">
      <Input
        value={search}
        placeholder={placeholder}
        onChange={(v) => setSearch(v)}
      />

      {search.length > 2 ? (
        <ListQuery
          gap={0}
          typename={typename}
          filter={getFilter?.({ search })}
          name={typename?.toLowerCase() + "s"}
          renderItem={renderItem}
          showListEmpty={false}
        />
      ) : null}
    </Flex>
  );
};

import * as React from "react";
import { Icon, Tabs, Flex } from "unikit";
import dayjs from "dayjs";

import { Page, ListItem, List, Form, renderAction } from "components";

import { useAppContext } from "../../context";
import { useQuery, gql } from "../../apollo";

export default function Screen({ navigation }) {
  const { data } = useQuery(gql`
    query getStats {
      getStats {
        warehouseReports
      }
    }
  `);
  const [tab, setTab] = React.useState("Zentrallager");
  const { user, callMeteor, callMeteorState } = useAppContext();
  const warehouseReports = data?.getStats?.warehouseReports || {};

  return (
    <Page
      title="Warenbestand"
      goBack={navigation?.goBack}
      rightAction={renderAction({
        icon: "printer",
        loading: callMeteorState.loading,
        onPress: () => {
          callMeteor({
            variables: {
              name: "analyzeWarehouse",
              config: { userId: user._id },
            },
          })
            .then((result) => {
              console.log({ result });
              navigation.navigate("PDF", {
                url: result.data.callMeteor.result.Location,
              });
            })
            .catch((error) => {
              console.log({ error });
            });
        },
      })}
      navbarProps={{
        footerComponent: (
          <Flex p={5} w="100%">
            <Tabs
              size={35}
              value={tab}
              options={["Zentrallager", "PDF generieren"]}
              onChange={(value) => setTab(value)}
            />
          </Flex>
        ),
      }}
      scrollComponent={tab === "Zentrallager" ? List : undefined}
      scrollProps={{
        groupBy: "year",
        data: Object.values(warehouseReports),
        renderItem: ({ item, index }) => {
          return (
            <ListItem
              title={`${item.month || "Report"}`}
              onPress={() => {
                navigation.navigate("PDF", {
                  url: item.Location,
                });
              }}
              actions={[
                {
                  icon: "fileText",
                  label: "PDF anzeigen",
                  onPress: () => {
                    navigation.navigate("PDF", {
                      url: item.Location,
                    });
                  },
                },
              ]}
            />
          );
        },
      }}
    >
      <Flex>
        <Form
          isLoading={callMeteorState.loading}
          buttonText="PDF generieren"
          buttonProps={{
            w: "100%",
            mt: 10,
            renderLeft: <Icon name="printer" color="#FFF" size={22} mr={7} />,
          }}
          navigation={navigation}
          defaultDoc={{
            from: dayjs().startOf("year").toDate(),
            to: dayjs().toDate(),
          }}
          onSubmit={({ doc }) => {
            callMeteor({
              variables: {
                name: "analyzePurchasing",
                config: { ...doc },
              },
            })
              .then((result) => {
                console.log({ result });
                navigation.navigate("PDF", {
                  url: result.data.callMeteor.result.Location,
                });
              })
              .catch((error) => {
                console.log({ error });
              });
          }}
          schema={[
            {
              key: "contactId",
              label: "Lieferant",
              input: "DocList",
              optional: false,
              group: "Lieferant",
              inputProps: {
                typename: "Contact",
                allowSearch: true,
                autocomplete: true,
                full: false,
                multiple: false,
                title: (item) => {
                  return `${item.name}, ${item.typ}`;
                },
                desc: (item) => {
                  return `Telefon: ${item.phone}, Fax: ${item.fax} - ${item.address}, ${item.zipCode} ${item.residence}`;
                },
              },
            },
            {
              key: "from",
              label: "Von",
              input: "date",
              group: "Zeitraum wählen",
              optional: false,
              groupOptions: {
                width: 200,
                mode: "grid",
              },
            },
            {
              key: "to",
              label: "Bis",
              input: "date",
              group: "Zeitraum wählen",
              optional: false,
              groupOptions: {
                width: 200,
                mode: "grid",
              },
            },
          ]}
        />
      </Flex>
    </Page>
  );
}

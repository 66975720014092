import dayjs from "dayjs";
import SimpleSchema from "./simple";

const schema = {
  patient: {
    type: String,
    label: "Patient/Grund",
    group: "Allgemein",
    optional: true,
  },
  patientId: {
    type: String,
    label: "Patient",
    optional: true,
    input: "DocList",
    group: "Allgemein",
    inputProps: {
      multiple: false,
      typename: "Patient",
      allowSearch: true,
      allowCreate: false,
      allowEdit: false,
      actionPosition: "bottom",
      title: (item) => `${item.lastName}, ${item.firstName}`,
      setDocKey: "patient",
      desc: (item) =>
        `Bearbeitet am ${dayjs(item.updatedAt).format("DD. MMMM YYYY")}`,
      actions: ({ doc, item, navigation, Linking, platform }) => [
        {
          icon: "user",
          text: "Zum Patienten",
          onPress: () => {
            navigation.push("PatientProfile", {
              _id: item._id,
            });
          },
        },
        {
          icon: "map",
          text: "Route",
          onPress: () => {
            const mapUrls = {
              ios: `http://maps.apple.com/?q=${item?.address}+${item?.residence}`,
              android: `https://www.google.com/maps/search/?api=1&query=${item?.address},${item?.residence}`,
            };
            Linking.openURL(mapUrls?.[platform] || mapUrls.android);
          },
        },
        {
          icon: "clipboard",
          text: "Neuer Behandlungsnachweis",
          onPress: () => {
            navigation.push("Form", {
              typename: "Evidence",
              mode: "insert",
              defaultDoc: {
                patientId: item._id,
              },
            });
          },
        },
        {
          icon: "calendar",
          text: "Folgetermin",
          onPress: () => {
            navigation.replace("Form", {
              typename: "Appointment",
              mode: "insert",
              defaultDoc: {
                ...doc,
                datum: dayjs(doc.date).add(7, "days").toDate(),
              },
            });
          },
        },
      ],
    },
  },
  groupId: {
    type: String,
    label: "Mitarbeiter",
    optional: true,
    listed: true,
    input: "UserSelect",
    group: "Allgemein",
    placeholder: "Bitte wählen",
  },
  datum: {
    type: Date,
    label: "Datum",
    defaultValue: function () {
      return new Date();
    },
    input: "datetime",
    group: "Allgemein",
  },
  time: {
    type: Number,
    label: "Dauer in Minuten",
    defaultValue: function () {
      return 30;
    },
    min: 0,
    max: 1500,
    ticks: 15,
    input: "number",
    group: "Allgemein",
  },
  daily: {
    type: Boolean,
    label: "Ganztägig",
    defaultValue: function () {
      return false;
    },
    optional: true,
    input: "switch",
    group: "Allgemein",
  },
  datum_daily: {
    type: Date,
    label: "Datum bis",
    defaultValue: function () {
      return new Date();
    },
    input: "date",
    group: "Allgemein",
    visible: "daily",
    optional: true,
  },
  reason: {
    type: String,
    label: "Grund",
    optional: true,
    group: "Allgemein",
    input: "textarea",
  },
  done: {
    type: Boolean,
    label: "Erledigt",
    defaultValue: function () {
      return false;
    },
    optional: true,
    input: "switch",
    group: "Allgemein",
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    listed: false,
    optional: true,
  },
  createdBy: {
    type: String,
    autoValue: function () {
      if (this.isInsert && this.userId) {
        return this.userId;
      } else if (this.isUpsert && this.userId) {
        return this.userId;
      }
    },
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Appointments = new Mongo.Collection("appointments");
  Appointments.schema = simpleSchema;
  Appointments.attachSchema(simpleSchema);
  Appointments._ensureIndex({ companyId: 1 });
}

export { simpleSchema as AppointmentSchema };

export default schema;

import SimpleSchema, { minWidth } from "../simple";

const schema = {
  surveyDate: {
    type: Date,
    label: "Erhebungsdatum",
    defaultValue: function () {
      return new Date();
    },
    group: "Allgemeine Erhebungsdaten",
    input: "date",
    groupOptions: {
      width: minWidth,
      mode: "grid",
    },
  },
  name: {
    type: String,
    label: "Dokumentenname",
    max: 100,
    group: "Allgemeine Erhebungsdaten",
  },
  note: {
    type: String,
    label: "Bemerkung",
    optional: true,
    input: "textarea",
    group: "Bemerkung",
    inputProps: {
      hideLabel: true,
    },
  },
  imageFileIds: {
    type: Array,
    label: "Dokumente",
    input: "File",
    defaultValue: function () {
      return [];
    },
    multiple: true,
    group: "Dokumente",
    inputProps: {
      hideLabel: true,
    },
  },
  "imageFileIds.$": {
    type: String,
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  patientId: {
    type: String,
    label: "PatientId",
    visible: "false",
    defaultValue: (props) => {
      return props.patientId;
    },
  },
  therapyId: {
    type: String,
    label: "TherapyId",
    visible: "false",
    defaultValue: (props) => {
      return props.therapyId;
    },
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  canceled: {
    type: Boolean,
    label: "Canceled",
    optional: true,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  OtherDocuments = new Mongo.Collection("otherDocuments");
  OtherDocuments.schema = simpleSchema;
  OtherDocuments.attachSchema(simpleSchema);
  OtherDocuments._ensureIndex({ therapyId: 1 });
  OtherDocuments._ensureIndex({ patientId: 1 });
  OtherDocuments._ensureIndex({ companyId: 1 });
}

export { simpleSchema as OtherDocumentSchema };

export default schema;

import SimpleSchema from "./simple";

const schema = {
  type: {
    type: String,
    label: "type of action", //patient
    options: [
      "insert_doc",
      "update_doc",
      "delete_doc",
      "order",
      "warehouse_in",
      "warehouse_out",
      "warehouse_change",
      "chat",
    ],
    max: 1000,
  },
  message: {
    type: String,
    label: "message",
    optional: true,
  },
  patientId: {
    type: String,
    label: "PatientId",
    optional: true,
  },
  therapyId: {
    type: String,
    label: "therapyId",
    optional: true,
  },
  contactId: {
    type: String,
    label: "contactId",
    optional: true,
  },
  orderId: {
    type: String,
    label: "orderId",
    optional: true,
  },
  warehouseFromId: {
    type: String,
    label: "warehouseFromId",
    optional: true,
  },
  warehouseToId: {
    type: String,
    label: "warehouseToId",
    optional: true,
  },
  materials: {
    type: Array,
    optional: true,
    blackbox: true,
    input: "DocListWithCount",
    label: "Materialien",
    group: "Materialien",
  },
  "materials.$": {
    type: Object,
    listed: false,
    blackbox: true,
    optional: true,
  },
  typeName: {
    type: String,
    label: "docTypeName",
    optional: true,
  },
  docId: {
    type: String,
    label: "docId",
    optional: true,
  },
  docDiff: {
    type: Object,
    listed: false,
    blackbox: true,
    optional: true,
  },
  seenByUser: {
    type: Array,
    label: "The User has seen this Chat",
    optional: true,
    listed: false,
  },
  "seenByUser.$": {
    type: String,
    optional: true,
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  withCompanyId: {
    type: String,
    listed: false,
    optional: true,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Actions = new Mongo.Collection("actions");
  Actions.schema = simpleSchema;
  Actions.attachSchema(simpleSchema);
  Actions._ensureIndex({ type: 1 });
  Actions._ensureIndex({ patientId: 1 });
  Actions._ensureIndex({ therapyId: 1 });
  Actions._ensureIndex({ companyId: 1 });
}

export { simpleSchema as ActionSchema };

export default schema;

import * as React from "react";
import { Tabs, Flex, Input, Tooltip } from "unikit";

import { Page, ListQuery, ListItem, renderAction } from "components";
import { ActionItem } from "../../components/inputs/Actions";
import i18n from "../../i18n";
import { useAppContext } from "../../context";

export default function Screen({ navigation }) {
  const { user, callMeteor, callMeteorState, team } = useAppContext();
  const [filter, setFilter] = React.useState(user._id);
  const [tab, setTab] = React.useState(i18n.t("materials"));

  const activeUser = team.find((t) => t._id === filter);

  const userWarehouseMaterials = activeUser?.warehouseMaterials
    ? Object.keys(activeUser?.warehouseMaterials || {})
    : [];

  console.log({ activeUser, filter });
  return (
    <Page
      title="Mein Lager"
      style={{ minHeight: "100%", position: "relative" }}
      goBack={navigation?.goBack}
      rightAction={
        <Flex row alignItems="center">
          <Tooltip
            popover={
              <>
                <Input
                  value={filter}
                  type="select"
                  label="Mitarbeiter wählen"
                  placeholder="Mitarbeiter wählen"
                  bg="transparent"
                  shadow={0}
                  inline
                  onChange={(value) => {
                    setFilter(value);
                  }}
                  options={[
                    {
                      label: `${user.lastName}, ${user.firstName}`,
                      value: user._id,
                    },
                    ...user.team.map((member) => {
                      return {
                        label: `${member.lastName}, ${member.firstName}`,
                        value: member._id,
                      };
                    }),
                  ]}
                />
              </>
            }
          >
            {renderAction({ icon: "more-horizontal" })}
          </Tooltip>
          {renderAction({
            icon: "printer",
            ml: 10,
            loading: callMeteorState.loading,
            onPress: () =>
              callMeteor({
                variables: {
                  name: "printMyWarehouse",
                  config: { userId: activeUser._id },
                },
              })
                .then((result) => {
                  console.log({ result });
                  navigation.navigate("PDF", {
                    url: result.data.callMeteor.result.Location,
                  });
                })
                .catch((error) => {
                  console.log({ error });
                }),
          })}
        </Flex>
      }
      navbarProps={{
        footerComponent: (
          <Flex p={5} w="100%">
            <Tabs
              size={35}
              value={tab}
              options={[i18n.t("materials"), i18n.t("transactions")]}
              onChange={(value) => setTab(value)}
            />
          </Flex>
        ),
      }}
    >
      {tab === i18n.t("materials") && (
        <ListQuery
          typename="Material"
          filter={{
            _id: {
              $in: userWarehouseMaterials,
            },
          }}
          sort={{ updatedAt: -1 }}
          name="materials"
          renderItem={({ item, index }) => {
            const count = activeUser.warehouseMaterials?.[item._id] || 0;
            return (
              <ListItem
                key={`doc-${index}`}
                title={`${count}x ${item.name}`}
                desc={`${item.packagingUnit} - ${item.size}`}
                style={{ width: "100%" }}
                wrapperStyle={{ width: "100%" }}
                users={[activeUser]}
                actions={[
                  {
                    icon: "refresh-ccw",
                    text: "Umbuchen",
                    onPress: () => {
                      navigation.navigate("Change", {
                        defaultDoc: {
                          type: "warehouse_change",
                          warehouseFromId: activeUser._id,
                          materials: [{ ...item, count }],
                        },
                      });
                    },
                  },
                ]}
              />
            );
          }}
        />
      )}

      {tab === i18n.t("transactions") && (
        <ListQuery
          typename="Action"
          filter={{
            createdBy: activeUser._id,
            type: {
              $in: ["warehouse_out", "warehouse_in", "warehouse_change"],
            },
          }}
          sort={{ createdAt: -1 }}
          renderItem={({ item, index }) => {
            return <ActionItem key={`action-${index}`} team={team} {...item} />;
          }}
        />
      )}
    </Page>
  );
}

import React from "react";
import { Text, Image, View, TouchableOpacity } from "react-native";

var imgWidth = { width: 30, height: 30 };

var colors = [
  "#c7d961",
  "#dfd138",
  "#ffcb4e",
  "#fdb300",
  "#f79934",
  "#ec6830",
  "#d14a1c",
  "#d1361c",
  "#db0018",
  "#bb0000",
];

export default ({ value, options, onChange }) => (
  <View style={{ width: "100%", paddingHorizontal: 15 }}>
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 10,
      }}
    >
      {/* <Icon name="smile" size={60} color="success" />
      <Icon name="meh" size={60} color="success" />
      <Icon name="frown" size={60} color="error" /> */}
      <Image
        style={imgWidth}
        source={{ uri: "https://staging.clouddoku.de/images/smile1.png" }}
      />
      <Image
        style={imgWidth}
        source={{ uri: "https://staging.clouddoku.de/images/smile5.png" }}
      />
      <Image
        style={imgWidth}
        source={{ uri: "https://staging.clouddoku.de/images/smile10.png" }}
      />
    </View>
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 5,
      }}
    >
      {options
        ? options.map((option, index) => {
            return (
              <View style={{ flexBasis: "10%", marginBottom: 10 }}>
                <TouchableOpacity
                  style={{
                    height: 44,
                    width: "100%",
                    backgroundColor: colors[index],
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onPress={() => {
                    onChange(option.value);
                  }}
                  activeOpacity={0.8}
                >
                  {option.value === value && (
                    <View
                      style={{
                        backgroundColor: "#FFF",
                        width: 20,
                        height: 20,
                        borderRadius: 10,
                      }}
                    />
                  )}
                </TouchableOpacity>
                <View style={{ paddingTop: 5 }}>
                  <Text
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: 2,
                      fontSize: 10,
                      color: "#000",
                    }}
                  >
                    {option.label}
                  </Text>
                </View>
              </View>
            );
          })
        : null}
    </View>
  </View>
);

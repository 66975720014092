import * as React from "react";
import Animated from "react-native-reanimated";
import {
  KeyboardAwareFlatList,
  KeyboardAwareSectionList,
} from "react-native-keyboard-aware-scroll-view";

import {
  Flex,
  Button,
  getValue,
  Progress,
  Text,
  useLayout,
  BlurView,
  Icon,
} from "unikit";

const AnimatedFlatList = Animated.createAnimatedComponent(
  KeyboardAwareFlatList
);
const AnimatedSectionList = Animated.createAnimatedComponent(
  KeyboardAwareSectionList
);

import NoContent from "./empty";
import ListItem from "./item";

const groupByArray = (
  array = [],
  prop,
  groupByFormat,
  hideEmptyGroup,
  defaultSections = []
) => {
  const defaultArray = defaultSections.map((section) => ({
    title: groupByFormat({ key: prop, value: section }),
    value: section,
    data: [],
  }));
  if (!array || array?.length === 0) return [];
  const groupedArray = array.reduce((groups, item) => {
    const val = groupByFormat
      ? groupByFormat({ key: prop, value: getValue(item, prop), item })
      : getValue(item, prop);
    if ((hideEmptyGroup && val) || !hideEmptyGroup) {
      if (groups.find((a) => a.title === val)) {
        groups.find((a) => a.title === val).data.push(item);
      } else {
        groups.push({
          title: val,
          value: getValue(item, prop),
          data: [item],
        });
      }
    }
    return groups;
  }, defaultArray);
  if (prop === "patient.doctor.name") {
    return groupedArray.sort(function (a, b) {
      const keyA = a.title.toLowerCase();
      const keyB = b.title.toLowerCase();
      if (keyA < keyB) {
        return -1;
      }
      if (keyA > keyB) {
        return 1;
      }
      return 0;
    });
  } else {
    return groupedArray;
  }
};

const Paging = ({ data, page = 1, maxPages = 5, onChange }) => {
  // const currentPage = Math.round(count / data);
  // const last_page = truncate(count / limit);
  const buttonProps = { mx: 2, rounded: true, size: 40 };
  const renderButton = (value, current = false) => {
    if (value === undefined) return null;
    return (
      <Button
        onPress={current ? null : () => onChange?.(value)}
        {...buttonProps}
        light={!current}
      >
        {(value + 1)?.toString()}
      </Button>
    );
  };

  return (
    <Flex w="100%" p={15} center row>
      <Button
        disabled={page === 0}
        onPress={() => onChange?.(page - 1)}
        px={15}
        {...buttonProps}
      >
        <Icon name="chevron-left" color="#FFF" />
      </Button>
      {page > 2 ? renderButton(0) : null}
      {page - 1 >= 0 ? renderButton(page - 1) : null}
      {renderButton(page, true)}
      {page + 1 < maxPages ? renderButton(page + 1) : null}
      {page !== maxPages ? renderButton(maxPages) : null}
      <Button
        disabled={page === maxPages}
        onPress={() => onChange?.(page + 1)}
        px={15}
        {...buttonProps}
      >
        <Icon name="chevron-right" color="#FFF" />
      </Button>
    </Flex>
  );
};

export default function List({
  renderItem,
  data = [],
  loading = false,
  refreshing,
  fetchMore,
  groupBy,
  groupByFormat,
  hideEmptyGroup,
  defaultSections = [],
  sections = false,
  sectionBg = "primary:setAlpha:0.05",
  sectionTitleSize = "h5",
  sectionHeight = 35,
  actions,
  actionType,
  gap = 10,
  count,
  showCount = false,
  ListFooterComponent,
  loadMore,
  loadMoreMode = "button",
  listEmptyTitle = "Keine Daten vorhanden",
  showListEmpty = true,
  min,
  page,
  maxPages,
  setPage,
  scrollTo,
  ...rest
}) {
  const { width, onLayout } = useLayout();
  let numColumns = undefined;
  if (min > 0 && width) {
    numColumns = Math.max(1, Math.floor(width / min));
  }
  const [SectionCopmonent, setComp] = React.useState(null);

  const ListComp = groupBy || sections ? AnimatedSectionList : AnimatedFlatList;

  const listData = React.useMemo(
    () =>
      groupBy
        ? groupByArray(
            data,
            groupBy,
            groupByFormat,
            hideEmptyGroup,
            defaultSections
          )
        : data,
    [data, groupBy, groupByFormat, hideEmptyGroup, defaultSections]
  );

  //console.log({ listData, count, showCount });

  const renderToSection = React.useCallback((sectionComponent) => {
    setComp(sectionComponent);
  }, []);

  const memoItem = React.useCallback(
    (props) => renderItem(props, renderToSection),
    [renderToSection, renderItem]
  );

  const renderer = (props) => (
    <Flex
      px={gap}
      {...(min
        ? {
            flex: 1 / numColumns,
            pr: (props.index + 1) % numColumns === 0 ? 0 : gap || 10,
            pb: 5,
          }
        : {})}
    >
      {memoItem(props, renderToSection)}
    </Flex>
  );

  const keyExtractor = React.useCallback(
    (item, index) => `list-item-${index}`,
    []
  );

  return (
    <>
      <ListComp
        onLayout={min ? onLayout : undefined}
        numColumns={numColumns}
        key={min ? `list-${numColumns}` : undefined}
        {...(groupBy || sections
          ? {
              sections: listData,
              stickySectionHeadersEnabled: true,
              renderSectionHeader: ({ section }) => {
                const filterOptions = section.data?.[0]?.filterOptions;
                if (section?.title === "undefined") return null;
                return (
                  <BlurView intensity={90}>
                    <Flex>
                      <ListItem
                        onPress={section.onPress}
                        title={`${section.title} ${showCount ? count : ""}`}
                        titleSize={sectionTitleSize}
                        h={section?.height || sectionHeight}
                        actions={actions || section.actions}
                        onActionPressProps={{ ...section }}
                        actionType={actionType || section.actionType}
                        renderLeft={section.renderLeft}
                        renderRight={
                          filterOptions ? (
                            <>
                              {section?.SectionRightComponent}
                              {SectionCopmonent}
                            </>
                          ) : null
                        }
                        border={false}
                        activeOpacity={1}
                      />
                    </Flex>
                  </BlurView>
                );
              },
            }
          : { data })}
        keyExtractor={keyExtractor}
        renderItem={renderer}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        ListEmptyComponent={
          loading ? (
            <Flex w="100%" h={60} center>
              <Progress
                size={30}
                trackColor="surface:setAlpha:0.5"
                trackWidth={3}
                progressWidth={3}
                loading
              />
            </Flex>
          ) : showListEmpty ? (
            <Flex w="100%" py={50} center>
              <Text>{listEmptyTitle}</Text>
            </Flex>
          ) : null
        }
        ListHeaderComponent={
          <>
            {/* {RefreshLoading}
            {ListHeaderComponent} */}
          </>
        }
        onEndReached={
          loadMoreMode === "scroll"
            ? () => {
                if (data?.length < count) {
                  loadMore();
                }
              }
            : undefined
        }
        removeClippedSubviews={false}
        //maxToRenderPerBatch={20}
        initialNumToRender={22}
        // updateCellsBatchingPeriod={100}
        ListFooterComponent={
          <Flex>
            {ListFooterComponent}
            {loadMoreMode === "paging" && data?.length > 0 && maxPages > 0 ? (
              <Paging
                data={data?.length}
                maxPages={maxPages}
                page={page}
                onChange={(page) => {
                  setPage(page);
                  scrollTo?.({
                    animated: true,
                    offset: 0,
                  });
                }}
              />
            ) : null}
            {data?.length < count && loadMoreMode === "button" ? (
              <Flex flex={1} flexCenter px={25} my={10}>
                <Button
                  onPress={() => {
                    loadMore?.();
                  }}
                  loading={loading}
                  light
                  size={40}
                  rounded
                >
                  {`Mehr laden ${count ? `${data.length}/${count}` : ``}`}
                </Button>
              </Flex>
            ) : loading && data?.length > 0 ? (
              <Button loading light size={40} my={20} rounded>
                ""
              </Button>
            ) : null}
            {loadMoreMode === "scroll" ? <Flex w="100%" h={50} /> : null}
          </Flex>
        }
        {...rest}
        enableOnAndroid={true}
        extraHeight={400}
        // onEndReachedThreshold={0.66}
      />
    </>
  );
}

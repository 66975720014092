import * as react from "react";
import { Alert, Platform } from "react-native";

export default (message, func, onClose) => {
  if (Platform.OS === "web") {
    if (window.confirm(message)) {
      func();
    } else {
      onClose?.();
    }
  } else {
    Alert.alert(
      message,
      "", //"Sie können das löschen unter Aktionen rückgängig machen",
      [
        {
          text: "Abbrechen",
          onPress: () => {
            onClose?.();
            console.log("Cancel Pressed");
          },
          style: "cancel",
        },
        {
          text: "Ja",
          onPress: () => {
            func();
          },
        },
      ],
      { cancelable: false }
    );
  }
};

import * as React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Alert, Dimensions } from "react-native";
import gql from "graphql-tag";
import { useApolloClient } from "@apollo/client";
import * as SecureStore from "expo-secure-store";
import * as Linking from "expo-linking";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import {
  Flex,
  Progress,
  Button,
  useTheme,
  Input,
  Touchable,
  Text,
  CodeField,
  Form,
  Icon,
  isWeb,
  BlurView,
  AppIcon,
} from "unikit";

import { Page } from "components";
import { version } from "../../version";
import { useAppContext } from "../../context";

const LOGIN_MUTATION = gql`
  mutation login(
    $email: String!
    $password: String!
    $code: String
    $localAuth: Boolean
  ) {
    login(
      email: $email
      password: $password
      code: $code
      localAuth: $localAuth
    )
  }
`;

const LoginScreen = ({ authRef }) => {
  const { userLoading, callMeteor, callMeteorState } = useAppContext();
  const client = useApolloClient();
  const formRef = React.useRef(null);
  const [step, setStep] = React.useState("login");
  const [token, setToken] = React.useState(null);
  const [lastAttempt, setLastAttempt] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();

  React.useEffect(() => {
    if (window && window.location) {
      const url = Linking.parse(window.location.href);
      if (url.queryParams.token) {
        setToken(url.queryParams.token);
        setStep("reset");
      }
      if (url.queryParams.verified) {
        theme.alert({
          type: "success",
          message: "E-Mail erfolgreich verifiziert",
        });
      }
    }
  }, []);

  const buttonLabels = {
    login: "Login",
    code: "Warte auf Code",
    reset: "Passwort zurücksetzen",
  };

  const labels = {
    login: "Bitte melden Sie sich an",
    code: "Ihr Code ist auf dem Weg",
    reset: "Bitte geben Sie Ihre E-Mail an",
  };

  const localAuth = async (email, password) => {
    let result = await authRef.current.authenticateAsync();
    setLoading(true);
    console.log("Scan Result:", result);
    if (result.success === true) {
      if ((email, password)) {
        AsyncStorage.setItem("useLocalAuth", "true");
        SecureStore.setItemAsync(
          "UserLogin",
          JSON.stringify({
            email: email,
            password: password,
          })
        );
      } else {
        SecureStore.getItemAsync("UserLogin").then((val) => {
          console.log("SecureStore", val);
          if (val) {
            const UserLogin = JSON.parse(val);
            loginUser({ ...UserLogin, localAuth: true });
          } else {
            AsyncStorage.setItem("useLocalAuth", "false");
            theme.alert({ type: "error", message: "Abgelaufen" });
            setLoading(false);
          }
        });
      }
    } else {
      setLoading(false);
    }
  };

  const askForLocalAuth = () => {
    Alert.alert(
      "Möchten Sie sich in Zukunft mit FaceId oder Fingerabdruck einloggen?",
      "",
      [
        {
          text: "Ja",
          onPress: () => {
            authRef.current.useAuth(true);
            // this.localAuth(this.state.email, this.state.password);
          },
        },
        { text: "Nein", onPress: () => console.log("Cancel"), style: "cancel" },
      ]
    );
  };

  const loginUser = ({ email, password, code, localAuth }) => {
    setLoading(true);
    client
      .mutate({
        mutation: LOGIN_MUTATION,
        variables: {
          email: email ? email.toLowerCase() : undefined,
          password: password,
          code: code,
          localAuth: localAuth,
        },
      })
      .then((result) => {
        if (result.errors && result.errors.length > 0) {
          theme.alert({
            type: "error",
            message: result.errors[0].message.replace("GraphQL error: ", ""),
          });
          setLoading(false);
        } else {
          console.log("Login", result);
          if (!code && !localAuth) {
            setStep("code");
            setLastAttempt(new Date());
            setLoading(false);
            theme.alert({
              type: "success",
              message: `Ihr Code ist auf dem Weg`,
            });
          } else {
            AsyncStorage.setItem("token", result.data.login).then((x) =>
              AsyncStorage.getItem("token").then((val) => {
                console.log(val, client);
                client.resetStore().then(() => {
                  console.log("reset store");
                });
              })
            );
            AsyncStorage.setItem("userEmail", email);
            if (
              authRef.current &&
              !authRef.current.active &&
              authRef.current.ready
            ) {
              askForLocalAuth();
            }
          }
        }
      })
      .catch((error) => {
        theme.alert({
          type: "error",
          message: error.message.replace("GraphQL error: ", ""),
        });
        setLoading(false);
      });
  };

  // React.useEffect(() => {
  //   if (authRef.current.active && authRef.current.ready) {
  //     localAuth();
  //   }
  // }, [authRef]);

  const BlurComp = isWeb ? Flex : BlurView;

  return (
    <Page
      background={require("../../assets/images/bg_blur.jpg")}
      withNavbar={false}
      scrollProps={{ bounces: false }}
      scrollComponent={KeyboardAwareScrollView}
      scrollProps={{
        bounces: false,
        contentContainerStyle: { height: Dimensions.get("window").height },
      }}
    >
      <Flex center h="100%">
        <Flex flexCenter w="100%" px={30} maxWidth={600}>
          <AppIcon
            source={require(`../../assets/images/bg.jpg`)}
            image={require(`../../assets/images/logo.png`)}
            size={100}
            mb={30}
          />
          {userLoading ? (
            <Flex flexCenter w="100%">
              <Progress
                progressColor="#FFF"
                size={60}
                trackWidth={5}
                progressWidth={5}
                loading
              />
            </Flex>
          ) : (
            <>
              <Text color="#FFF" mb={15}>
                {labels[step]}
              </Text>
              <Form
                defaultDoc={{
                  email: "",
                  password: "",
                }}
                button={false}
                ref={formRef}
                onChange={(doc) => {
                  if (doc.code && doc.code.length === 6) {
                    formRef.current.submit();
                  }
                }}
                onSubmit={(doc) => {
                  const { email, password, code } = doc;
                  if (step === "reset") {
                    callMeteor({
                      variables: {
                        name: "resetUserPassword",
                        config: { email },
                      },
                    })
                      .then(() => {
                        theme.alert({
                          type: "success",
                          message: "Ihr neues Password ist auf dem Weg",
                        });
                        setStep("login");
                      })
                      .catch((e) => {
                        theme.alert({
                          type: "error",
                          message: e.message,
                        });
                      });
                  } else if (step === "code" && code?.length === 6) {
                    loginUser({
                      email,
                      password,
                      code,
                    });
                  } else if (step === "login") {
                    if (!email || (email && email.length === 0)) {
                      theme.alert({
                        type: "error",
                        message: "Bitte geben Sie eine valide E-Mail an",
                      });
                    } else {
                      loginUser({
                        email,
                        password,
                      });
                    }
                  }
                }}
              >
                {step === "login" || step === "reset" ? (
                  <Input
                    type="text"
                    placeholder="E-Mail"
                    label="E-Mail"
                    labelProps={{ color: "#FFF" }}
                    field="email"
                    keyboardType="email-address"
                    textContentType="emailAddress"
                    autoCapitalize="none"
                  />
                ) : null}
                {step === "login" ? (
                  <Input
                    type="password"
                    placeholder="Passwort"
                    label="Passwort"
                    labelProps={{ color: "#FFF" }}
                    field="password"
                    mt={10}
                  />
                ) : null}
                {step === "code" ? (
                  <>
                    <CodeField field="code" autoFocus />
                  </>
                ) : null}
              </Form>
              <Button
                w="100%"
                size={50}
                mt={20}
                onPress={() => {
                  if (formRef.current) formRef.current.submit();
                }}
                loading={loading || callMeteorState.loading}
              >
                {buttonLabels[step]}
              </Button>
              <Button
                w="100%"
                size={50}
                mt={20}
                bg="transparent"
                onPress={() => {
                  setStep(step === "reset" ? "login" : "reset");
                }}
              >
                {step === "reset" ? "Zum Login" : "Passwort zurücksetzen"}
              </Button>
              {authRef.current &&
              authRef.current.active &&
              authRef.current.ready ? (
                <Touchable
                  onPress={() => {
                    localAuth();
                  }}
                  center
                >
                  <Flex
                    borderRadius={30}
                    w={60}
                    h={60}
                    mt={20}
                    bg="surface"
                    shadow={5}
                    center
                  >
                    <Icon name="face-id" size={38} strokeWidth={1} />
                  </Flex>
                  <Text mt={10} font="label" color="#FFF">
                    Login mit Face-Id
                  </Text>
                </Touchable>
              ) : null}
            </>
          )}
        </Flex>
      </Flex>
      <Flex flexCenter r={0} b={10} l={0} h={50} px={15} absolute>
        <Text color="#FFF" font="label">{`Version: ${version}`}</Text>
      </Flex>
    </Page>
  );
};

export default LoginScreen;

import SimpleSchema, { minWidth } from "../simple";

const schema = {
  surveyDate: {
    type: Date,
    label: "Erhebungsdatum",
    defaultValue: function () {
      return new Date();
    },
    group: "Allgemeine Erhebungsdaten",
    input: "date",
    groupOptions: {
      width: minWidth,
      mode: "grid",
    },
  },
  name: {
    type: String,
    label: "Dokumentenname",
    max: 100,
    group: "Allgemeine Erhebungsdaten",
    defaultValue: (props) => {
      if (props.count === 0) {
        return "1. Lieferschein";
      } else {
        return props.count + 1 + ". Lieferschein";
      }
    },
  },
  number: {
    type: Number,
    label: "Lieferscheinnummer",
    group: "Allgemeine Erhebungsdaten",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        const field = this.siblingField("companyId");
        var latest = DeliveryNotes.findOne(
          {
            companyId: field.value,
          },
          { sort: { number: -1 } }
        );
        var count = (latest?.number || 0) + 1;
        console.log({ count });
        return count;
      }
    },
    optional: true,
    listed: false,
  },
  note: {
    type: String,
    label: "Bemerkung",
    optional: true,
    input: "textarea",
    group: "Bemerkung",
    inputProps: {
      hideLabel: true,
    },
  },
  listNote: {
    type: String,
    label: "Bemerkung Liste",
    optional: true,
    input: "textarea",
    listed: false,
  },
  recipe_requested: {
    type: Boolean,
    label: "Rezept angefordert",
    optional: true,
    input: "switch",
    group: "Allgemeine Erhebungsdaten",
    listedPDF: false,
  },
  recipe_received: {
    type: Boolean,
    label: "Rezept erhalten",
    optional: true,
    input: "switch",
    group: "Allgemeine Erhebungsdaten",
    listedPDF: false,
  },
  material_send: {
    type: Boolean,
    label: "Material geliefert",
    optional: true,
    input: "switch",
    group: "Allgemeine Erhebungsdaten",
    listedPDF: false,
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  materials: {
    type: Array,
    optional: true,
    blackbox: true,
    input: "DocList",
    label: "Materialien",
    group: "Materialien",
    defaultValue: function () {
      return [];
    },
    inputProps: {
      hideLabel: true,
      typename: "Material",
      allowSearch: true,
      allowRemove: true,
      queryData: false,
      prompt: {
        title: "Wähle eine Anzahl",
        schema: [
          { key: "count", input: "number", label: "Anzahl", defaultValue: 1 },
        ],
      },
      actions: ({ showPrompt, item }) => [
        {
          icon: "refresh-ccw",
          text: "Anzahl ändern",
          onPress: () => {
            showPrompt({
              item,
              title: "Wähle eine Anzahl",
              force: true,
              schema: [
                {
                  key: "count",
                  input: "number",
                  label: "Anzahl",
                  defaultValue: item.count || 1,
                },
              ],
            });
          },
        },
      ],
      title: (item) => {
        return `${item.count}x ${item.producer}, ${item.name}`;
      },
      desc: (item) => {
        return `Größe: ${item.size}, Verpackungseinheit: ${item.packagingUnit}`;
      },
      table: {
        schema: [
          { label: "PZN", width: "75", key: "materialNumber" },
          { label: "Name", width: "145", key: "name" },
          { label: "Hersteller", width: "145", key: "producer" },
          { label: "Größe", width: 75, key: "size" },
          {
            label: "Menge",
            width: 80,
            key: "count",
            formatValue: ({ value, item }) => `${value}x ${item.packagingUnit}`,
            addon: "x",
            subKey: "packagingUnit",
          },
        ],
      },
    },
  },
  "materials.$": {
    type: Object,
    listed: false,
    blackbox: true,
  },
  signature: {
    type: String,
    label: "Unterschrift",
    optional: true,
    input: "Signature",
    group: "Unterschrift",
    inputProps: {
      forceRender: true,
      manuelDate: "Lieferdatum __________________",
    },
  },
  files: {
    type: Array,
    label: "Dateien",
    input: "File",
    optional: true,
    defaultValue: function () {
      return [];
    },
    multiple: true,
    group: "Weiteres",
    listed: "update",
    listedPDF: false,
  },
  "files.$": {
    type: String,
  },
  patientId: {
    type: String,
    label: "PatientId",
    visible: "false",
    defaultValue: (props) => {
      return props.patientId;
    },
  },
  therapyId: {
    type: String,
    label: "TherapyId",
    visible: "false",
    defaultValue: (props) => {
      return props.therapyId;
    },
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  canceled: {
    type: Boolean,
    label: "Canceled",
    optional: true,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  DeliveryNotes = new Mongo.Collection("deliveryNotes");
  DeliveryNotes.schema = simpleSchema;
  DeliveryNotes.attachSchema(simpleSchema);
  DeliveryNotes._ensureIndex({ therapyId: 1 });
  DeliveryNotes._ensureIndex({ patientId: 1 });
  DeliveryNotes._ensureIndex({ companyId: 1 });
}

export { simpleSchema as DeliveryNoteSchema };

export default schema;

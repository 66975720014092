import "react-native-gesture-handler";
import * as React from "react";
import { LogBox } from "react-native";
import { ApolloProvider } from "@apollo/client";
import dayjs from "dayjs";
import "dayjs/locale/de";
import * as Sentry from "sentry-expo";
import * as Haptics from "expo-haptics";

Sentry.init({
  dsn: "https://5bd5e4473336464096026d28426d4c4f@sentry.io/1808156",
  enableInExpoDevelopment: false,
  debug: __DEV__, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

dayjs.locale("de");

import { ThemeProvider, isWeb } from "unikit";
import { LocalAuth, Updates } from "./expo";

import { useClient } from "./apollo";
import { Provider } from "./Api";

import AppNavigation from "./AppNavigation";
import { AppContextProvider, useAppContext } from "./context";

LogBox.ignoreLogs([""]);

const AppLayout = () => {
  const { user } = useAppContext();
  const authRef = React.useRef(null);
  return (
    <ThemeProvider
      theme={{
        colors: {
          primary: user?.color || "#7D4CDB",
          background: "primary:setAlpha:0.1",
        },
      }}
      onFeedback={(type) => {
        if (!isWeb) {
          if (type === "selection") {
            Haptics.selectionAsync();
          } else if (["heavy", "medium", "light"].indexOf(type) > -1) {
            Haptics.impactAsync(type);
          } else {
            Haptics.notificationAsync(type);
          }
        }
      }}
    >
      <AppNavigation authRef={authRef} />
      {!isWeb ? (
        <LocalAuth
          ref={authRef}
          isUser={user ? true : false}
          onError={() => {
            logout();
          }}
        />
      ) : null}
      <Updates />
    </ThemeProvider>
  );
};

export default function App() {
  console.log("IN APP");
  const { client } = useClient();
  console.log("IN APP AFTER CLIENT");
  if (!client) return null;
  return (
    <Provider>
      <ApolloProvider client={client}>
        <AppContextProvider>
          <AppLayout />
        </AppContextProvider>
      </ApolloProvider>
    </Provider>
  );
}

import React from "react";
import { Flex, Text, Icon } from "unikit";
import dayjs from "dayjs";

import { ListQuery } from "../list";
import { useAppContext } from "../../context";

export const ActionItem = ({
  warehouseToId,
  warehouseFromId,
  createdAt,
  materials = [],
  team = [],
}) => {
  const getUser = (id) => {
    const user = team.find((user) => user._id === id);
    return user ? `${user.lastName}, ${user.firstName}` : "";
  };
  const to =
    warehouseToId === "warehouse"
      ? "Zentrallager"
      : getUser(warehouseToId) || "Ausgang";
  const from =
    warehouseFromId === "warehouse"
      ? "Zentrallager"
      : getUser(warehouseFromId) || "Eingang";
  return (
    <Flex bg="surface" shadow={3} borderRadius={10} mb={5} p={15}>
      <Flex row>
        <Text font="h5">{from}</Text>
        <Icon name="arrow-right" size={20} mx={5} />
        <Text font="h5">{to}</Text>
      </Flex>
      <Flex py={10}>
        {materials?.map((mat, index) => {
          return (
            <Flex key={index}>
              <Text numberOfLines={1}>
                {`${mat.count}x ${mat.name}, ${mat.packagingUnit} - ${mat.size}`}
              </Text>
            </Flex>
          );
        })}
      </Flex>
      <Text opacity={0.5} font="label">{`${dayjs(createdAt).format(
        "DD.MM.YYYY"
      )}`}</Text>
    </Flex>
  );
};

export default ({ filter }) => {
  const { user, team } = useAppContext();

  return (
    <ListQuery
      typename="Action"
      filter={filter}
      sort={{ createdAt: -1 }}
      groupBy="createdAt"
      groupByFormat={({ value }) => dayjs(value).format("DD.MMM")}
      renderItem={({ item }) => {
        return <ActionItem team={team} {...item} />;
      }}
    />
  );

  return (
    <Flex py={5}>
      {groupedActions.length === 0 ? (
        <Flex w="100%" flexCenter py={30}>
          <Text font="label">Keine Aktionen vorhanden</Text>
        </Flex>
      ) : null}
      {groupedActions.map((group, i) => {
        return (
          <Flex mb={10} row key={`${i}-action-group`}>
            <Flex flex={1} shadow={4}>
              {group.data.map((item, index) => {
                return (
                  <ActionItem
                    key={index}
                    team={[...[user], ...user.team]}
                    {...item}
                  />
                );
              })}
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

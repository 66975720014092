import SimpleSchema, { minWidth } from "../simple";

const schema = {
  surveyDate: {
    type: Date,
    label: "Erhebungsdatum",
    defaultValue: function () {
      return new Date();
    },
    input: "date",
    group: "Allgemeine Erhebungsdaten",
    groupOptions: {
      width: minWidth,
      mode: "grid",
    },
  },
  name: {
    type: String,
    label: "Dokumentenname",
    group: "Allgemeine Erhebungsdaten",
    defaultValue: (props, state) => {
      return props.count + 1 + ". Therapie Zusammenfassung";
    },
  },
  anamneseId: {
    type: String,
    label: "Anamnese",
    optional: true,
    input: "DocList",
    inputProps: {
      multiple: false,
      typename: "Anamnese",
      skipSearch: false,
      getFilter: ({ doc }) => ({ therapyId: doc.therapyId }),
      title: (item) => `${item.name}`,
    },
    group: "Wund - und Therapieinformationen",
    groupOptions: {
      width: minWidth,
      mode: "grid",
    },
    listedPDF: false,
  },
  woundImageId: {
    type: String,
    label: "Wundbild",
    optional: true,
    input: "DocList",
    inputProps: {
      multiple: false,
      typename: "WoundImage",
      skipSearch: false,
      getFilter: ({ doc }) => ({ therapyId: doc.therapyId }),
      title: (item) => `${item.name}`,
    },
    group: "Wund - und Therapieinformationen",
    listedPDF: false,
  },
  adviceId: {
    type: String,
    label: "Therapieempfehlung",
    optional: true,
    input: "DocList",
    inputProps: {
      multiple: false,
      typename: "Advice",
      skipSearch: false,
      getFilter: ({ doc }) => ({ therapyId: doc.therapyId }),
      title: (item) => `${item.name}`,
    },
    group: "Wund - und Therapieinformationen",
    listedPDF: false,
  },
  note: {
    type: String,
    label: "Anmerkung",
    optional: true,
    input: "textarea",
    group: "Anmerkung",
    inputProps: { hideLabel: true },
    listedPDF: false,
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  patientId: {
    type: String,
    label: "PatientId",
    visible: "false",
    defaultValue: (props) => {
      return props.patientId;
    },
  },
  therapyId: {
    type: String,
    label: "TherapyId",
    visible: "false",
    defaultValue: (props) => {
      return props.therapyId;
    },
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  WoundSummary = new Mongo.Collection("woundSummary");
  WoundSummary.schema = simpleSchema;
  WoundSummary.attachSchema(simpleSchema);
  WoundSummary._ensureIndex({ therapyId: 1 });
  WoundSummary._ensureIndex({ patientId: 1 });
  WoundSummary._ensureIndex({ companyId: 1 });
}

export { simpleSchema as WoundSummarySchema };

export default schema;

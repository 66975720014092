import * as React from "react";
import { Platform, Image, ScrollView, Dimensions } from "react-native";
import { styled, Flex, Button, useTheme, Draw } from "unikit";
import { captureRef } from "react-native-view-shot";
import d2i from "dom-to-image";
import HTML from "react-native-render-html";

const SignatureWrap = styled.View(({ width, height }) => ({
  position: "relative",
  width: width,
  maxWidth: 700,
  height: height ? height : "0px",
  borderWidth: 3,
  bg: "#FFF",
  borderColor: "primary:setAlpha:0.2",
  borderStyle: "dashed",
  borderRadius: 10,
}));

const Scroller = styled(ScrollView)({
  borderWidth: 3,
  borderColor: "primary:setAlpha:0.2",
  borderStyle: "dashed",
  borderRadius: 10,
  width: "100%",
  height: Dimensions.get("window").height / 2.5,
  mb: 10,
  p: 15,
  maxWidth: 700,
});

export default ({
  width = "100%",
  aspect = 0.5,
  onChange,
  onLoad,
  value,
  inputKey,
  user,
}) => {
  const theme = useTheme();
  const drawRef = React.useRef();
  const [height, setHeight] = React.useState(0);
  const shotRef = React.useRef(null);

  const save = async () => {
    if (onLoad) onLoad(true);
    if (Platform.OS === "web") {
      var result = await d2i.toPng(shotRef.current);
    } else {
      var result = await captureRef(shotRef.current, {
        format: "png",
        result: "base64",
        quality: 0.8,
      });
    }
    if (onChange) {
      onChange(
        `${
          result.indexOf("data:") > -1 ? "" : "data:image/png;base64,"
        }${result}`
      );
      if (onLoad) onLoad(false);
    }
  };

  return (
    <Flex w="100%" center>
      {inputKey === "informedConsentImage" ? (
        <Scroller contentContainerStyle={{ paddingBottom: 50 }}>
          <HTML
            source={{ html: user.informedConsentText }}
            ignoredStyles={["fontFamily"]}
            tagsStyles={{
              p: { color: theme.colors.text },
              h1: { color: theme.colors.text },
              h2: { color: theme.colors.text },
              h3: { color: theme.colors.text },
              h4: { color: theme.colors.text },
              h5: { color: theme.colors.text },
              ul: { color: theme.colors.text },
              li: { color: theme.colors.text },
              span: { color: theme.colors.text },
            }}
          />
        </Scroller>
      ) : null}
      <SignatureWrap
        width={width}
        height={height || 300}
        onLayout={({ nativeEvent: { layout } }) => {
          setHeight(layout.width * aspect);
        }}
      >
        {value ? (
          <Image
            source={{ uri: value }}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              resizeMode: "contain",
            }}
            pointerEvents="none"
          />
        ) : null}

        <Flex
          ref={shotRef}
          collapsable={Platform.OS === "android" ? false : true}
          absoluteFill
        >
          <Draw
            ref={drawRef}
            stroke="#000"
            onDraw={(v) => {
              setTimeout(() => {
                save();
              }, 50);
            }}
          />
        </Flex>
      </SignatureWrap>
      <Button
        mt={10}
        onPress={() => {
          drawRef?.current?.clear();
          onChange(null);
        }}
      >
        Zurücksetzen
      </Button>
    </Flex>
  );
};

import * as Sharing from "expo-sharing";
import * as FileSystem from "expo-file-system";

export default async ({ url, options, filename }) => {
  const isAvailable = await Sharing.isAvailableAsync();
  if (isAvailable) {
    try {
      let filepath = `${FileSystem.cacheDirectory}${filename}`;
      await FileSystem.writeAsStringAsync(filepath, url, {
        encoding: FileSystem.EncodingType.UTF8,
      });

      //   const info = await FileSystem.getInfoAsync(filepath);
      //   alert(info.uri);
      console.log({ filepath });
      await Sharing.shareAsync(filepath, options);
    } catch (error) {
      alert(error.message);
    }
  }
};

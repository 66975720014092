import React, { forwardRef } from "react";
import {
  Input,
  useTheme,
  isWeb,
  getValue,
  setValue,
  Flex,
  Grid,
  MasonryList,
  Button,
} from "unikit";
import { useNavigation } from "@react-navigation/core";

import { useAppContext } from "../context";
import { List } from "./list";
import { inputComponents } from "./inputs";
import { confirm } from "./dialogs";
import { useHandleDoc } from "api";

import getSchemaByType, { SimpleSchema } from "../clouddoku/lib/schemas";
import i18n from "../i18n";

function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

function calcBMI(size, weight) {
  var bmiState = undefined;
  var bmi =
    parseInt(weight) / (((parseInt(size) / 100) * parseInt(size)) / 100);
  if (bmi < 20) {
    bmiState = "untergewicht";
  } else if (bmi > 20 && bmi < 25) {
    bmiState = "normalgewicht";
  } else if (bmi > 25 && bmi < 29) {
    bmiState = "leichtes_übergewicht";
  } else if (bmi > 29 && bmi < 38) {
    bmiState = "adipositas";
  } else if (bmi > 38) {
    bmiState = "schwere_adipositas";
  }
  return bmiState;
}

export default forwardRef(
  (
    {
      schema,
      typename,
      variables = {},
      hiddenFields = [],
      onSuccess,
      onError,
      onValidationError,
      onChangeDoc,
      onSubmit,
      onCancel,
      isLoading,
      onLoading,
      id,
      mode = "insert",
      customMode,
      groupBy = "group",
      defaultDoc = {},
      roles,
      spacer = true,
      queryVariables,
      refetchQueries,
      buttonText = "Speichern",
      footerComponent = null,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme();
    const navigation = useNavigation();
    const { handleDoc, loading } = useHandleDoc();
    const { user, inRole } = useAppContext();
    const [doc, setDoc] = React.useState(defaultDoc);
    const [errors, setErrors] = React.useState([]);

    var simple = React.useMemo(
      () => (typename ? getSchemaByType(typename) : undefined),
      [typename]
    );
    var simpleSchema = React.useMemo(
      () => (simple ? new SimpleSchema(simple.schema) : undefined),
      [simple]
    );

    React.useEffect(() => {
      onLoading?.(loading);
    }, [loading]);

    console.log({ simple });

    const formSchema = React.useMemo(() => {
      if (schema && !typename) {
        if (mode === "insert") {
          const defaultValues = {};
          schema.map((input) => {
            if (input.defaultValue) {
              defaultValues[input.key] = isFunction(input.defaultValue)
                ? input.defaultValue?.(doc)
                : input.defaultValue;
            }
          });
          setDoc((d) => ({ ...d, ...defaultValues, ...defaultDoc }));
        }
        return schema;
      }
      if (!typename || !simple?.schema) return [];
      const newSchema = [];
      const defaultValues = {};

      if (mode === "insert") {
        const defaultObj = simpleSchema.clean(
          {},
          { getAutovalues: true, getDefaultvalues: true }
        );
        Object.keys(defaultObj).forEach((key) => {
          defaultValues[key] = isFunction(defaultObj[key])
            ? defaultObj[key](doc)
            : defaultObj[key];
        });
        setDoc((d) => ({ ...d, ...defaultValues, ...defaultDoc }));
      }

      Object.keys(simple.schema).forEach((key) => {
        let valid = true;
        const item = simple.schema[key];
        let options = item.options;

        if (item.roles && roles) {
          valid = inRole(item.roles, roles) ? true : false;
        }

        if (hiddenFields?.indexOf(key) > -1) {
          valid = false;
        }

        if (key.indexOf("$") > -1) {
          valid = false;
        }

        if (newSchema.find((item) => item.key === key.split(".")[0])) {
          valid = false;
        }

        if (item.listed === false) {
          valid = false;
        }

        if (valid == true) {
          const newItem = {
            ...item,
            key: key === "emails" ? "emails.0.address" : key,
            inputKey: key === "emails" ? "emails.0.address" : key,
            label:
              item.label ||
              i18n.t(`form.${key === "emails[0].address" ? "email" : key}`),
            placeholder:
              item.placeholder ||
              item.label ||
              i18n.t(`form.${key === "emails[0].address" ? "email" : key}`),
            required: !item.optional,
            input: item.input || "text",
            listed: item.listed,
            options: options,
          };
          const found = newSchema.find((i) => i.inputGroupKey === item.group);
          if (item.groupOptions || found) {
            if (found?.inputs) {
              found.inputs.push(newItem);
            } else if (item.group) {
              newSchema.push({
                inputGroupKey: item.group,
                inputGroupOptions: item.groupOptions,
                inputs: [newItem],
                group: item.group,
              });
            }
          } else {
            newSchema.push(newItem);
          }
        }
      });

      return newSchema;
    }, [schema, typename, simple]);

    React.useImperativeHandle(ref, () => ({
      getDoc: () => {
        return doc;
      },
      submit: () => {
        const cleanedDoc = simpleSchema.clean(
          Object.assign(
            {},
            doc,
            typename !== "User" && mode === "insert"
              ? {
                  createdBy: user._id,
                  companyId: user.companyId,
                }
              : {}
          ),
          { removeEmptyStrings: false }
        );

        console.log({ cleanedDoc });

        if (mode === "insert") {
          const validationContext = simpleSchema.newContext();

          validationContext.validate(cleanedDoc);

          const errors = validationContext
            .validationErrors()
            .filter((e) => e.dataType !== "date" && e.type !== "expectedType");
          console.log({ errors });
          if (errors.length > 0) {
            setErrors(errors);
            const label = simple.schema[errors[0].name]
              ? simple.schema[errors[0].name].label
              : errors[0].name;
            theme.alert({
              type: "error",
              message: `${label} ist ein Pflichfeld`,
            });
            onValidationError?.();
            return false;
          }
        }

        // setData.mutate({
        //   doc: cleanedDoc,
        //   id,
        //   mode,
        // });

        handleDoc({
          doc: cleanedDoc,
          typename,
          id,
          mode,
          customMode,
          onSuccess,
          onError,
          queryVariables,
          refetchQueries,
        });
      },
    }));

    const onChange = ({ value, key }) => {
      setDoc((doc) => {
        let d = Object.assign({}, doc);
        d = setValue(d, key, value);
        console.log({ d, key, value });
        if (key === "size" && d.weight) {
          d["BMI"] = calcBMI(value, d.weight);
        }
        if (key === "weight" && d.size) {
          d["BMI"] = calcBMI(d.size, value);
        }
        if (key === "emails.0.address") {
          d["emails"] = [{ address: value }];
        }
        if (onChangeDoc) {
          d = onChangeDoc({ doc: d, value, key });
        }
        console.log({ value, key });
        return { ...d };
      });
    };

    const renderInput = (item) => {
      if (
        item.listed === false ||
        (item.listed && item.listed !== true && item.listed !== mode)
      )
        return null;

      if (item.visible) {
        if (isFunction(item.visible) && item.visible({ doc }) !== true) {
          return null;
        } else if (
          !isFunction(item.visible) &&
          item.visible &&
          doc[item.visible] !== true
        ) {
          return null;
        }
      }
      const Comp = inputComponents[item.input];
      const inputProps = {
        ...item,
        ...(isFunction(item?.inputProps)
          ? item?.inputProps?.({ doc, navigation })
          : item?.inputProps || {}),
        navigation,
        doc,
        mode,
        user,
        editable: item.readonly ? false : true,
        onChange: (value, key) => onChange({ value, key: key || item.key }),
        value: getValue(doc, item.key),
      };
      if (Comp) {
        return (
          <Flex w="100%" px={5} py={5}>
            <Input
              label={inputProps?.hideLabel ? undefined : inputProps?.label}
            >
              <Comp {...inputProps} />
            </Input>
          </Flex>
        );
      }
      return (
        <Flex w="100%" pr={5} py={5} pl={item.visible ? 50 : 5}>
          <Input
            {...inputProps}
            label={inputProps?.hideLabel ? undefined : inputProps?.label}
            placeholder={
              item.input === "select"
                ? `${item.placeholder} wählen`
                : item.placeholder
            }
            type={item.input}
          />
        </Flex>
      );
    };

    return (
      <List
        data={formSchema}
        groupBy={groupBy}
        disableVirtualization={isWeb}
        sectionHeight={50}
        renderItem={({ item, index }) => {
          if (item.inputGroupKey) {
            if (item.groupOptions?.type === "inline") {
              return (
                <Flex row wrap key={`inline-${index}`}>
                  {item.inputs.map((input) => renderInput(input))}
                </Flex>
              );
            } else if (item.groupOptions?.type === "grid") {
              return (
                <Grid min={item.inputGroupOptions?.width} key={`grid-${index}`}>
                  {item.inputs.map((input) => renderInput(input))}
                </Grid>
              );
            }
            return (
              <MasonryList
                min={item.inputGroupOptions?.width}
                key={`masonry-${index}`}
              >
                {item.inputs.map((input) => renderInput(input))}
              </MasonryList>
            );
          }
          return renderInput(item);
        }}
        ListFooterComponent={
          <>
            {onSubmit ? (
              <Flex w="100%" py={5} px={10}>
                <Button
                  rounded
                  loading={isLoading}
                  onPress={() => onSubmit?.({ doc })}
                >
                  {buttonText}
                </Button>
              </Flex>
            ) : null}
            {onCancel ? (
              <Flex w="100%" py={5} px={10}>
                <Button rounded light onPress={() => onCancel?.({ doc })}>
                  Abbrechen
                </Button>
              </Flex>
            ) : null}
            {footerComponent}
            {mode === "update" &&
            [
              "Appointment",
              "MaterialGroup",
              "Material",
              "Contact",
              "Order",
            ].indexOf(typename) > -1 ? (
              (typename === "Material" && doc?.copyId) ||
              (typename === "MaterialGroup" && doc?.copyId) ? null : (
                <Button
                  bg="error"
                  light
                  my={10}
                  onPress={() => {
                    confirm("Dokument wirklich löschen?", () => {
                      handleDoc({
                        doc: doc,
                        typename: typename,
                        id,
                        mode: "delete",
                        onSuccess,
                      });
                    });
                  }}
                >
                  Löschen
                </Button>
              )
            ) : null}
            {spacer ? <Flex w="100%" h={300} /> : null}
          </>
        }
        {...rest}
      />
    );
  }
);

import * as React from "react";
import { Linking } from "react-native";
import {
  Flex,
  Text,
  Icon,
  Animate,
  Touchable,
  Button,
  Input,
  Switch,
  isIos,
  useTheme,
} from "unikit";
import dayjs from "dayjs";

import { UserSelect } from "components/inputs";
import {
  Page,
  List,
  ListItem,
  ListQuery,
  renderAction,
  confirm,
} from "components";
import { useHandleDoc, useData } from "api";

import { getTherapyName } from "../../clouddoku/lib/helper";
import { useAppContext } from "../../context";
import i18n from "../../i18n";

function Screen({ route, navigation }) {
  const theme = useTheme();
  const { handleDoc } = useHandleDoc();
  const [visible, setVisible] = React.useState({
    Therapy: true,
    Share: false,
    Evidence: false,
    Action: false,
    OwnDoc: false,
  });
  const { _id } = route?.params || {};
  const { user, inRole, callMeteor, callMeteorState } = useAppContext();
  const [warnings, setWarnings] = React.useState([]);
  const { data: patient, loading } = useData({
    typename: "Patient",
    single: true,
    variables: {
      filter: {
        _id,
      },
    },
  });

  const ownDocs = useData({
    typename: "OwnDoc",
    variables: {
      filter: {
        isDraft: true,
      },
    },
  });

  console.log({ ownDocs });

  const getListData = (typename) => {
    return {
      actionType: "inline",
      height: 60,
      onPress: () => setVisible((v) => ({ ...v, [typename]: !v[typename] })),
      renderLeft: (
        <Animate
          to={{ rotate: visible[typename] ? "90deg" : "0deg" }}
          w={20}
          h={40}
          ml={15}
          center
        >
          <Icon name="chevron-right" />
        </Animate>
      ),
    };
  };

  React.useEffect(() => {
    if (patient && warnings.length === 0) {
      const w = [];
      if (patient.anamnese) {
        if (patient.anamnese.hiv === true) {
          w.push("HIV");
        }
        if (patient.anamnese.swabMRSA === true) {
          w.push("MRSA");
        }
        if (patient.anamnese.hepatitis === true) {
          w.push("Hepatitis");
        }
      }
      setWarnings([...w]);
    }
  }, [patient]);

  const myPatient = patient ? patient.myPatient || [] : [];
  const askForPrescriptionCharge =
    dayjs(patient?.updatedAt).format("YYYY") !== dayjs().format("YYYY") ||
    __DEV__;

  return (
    <Page
      loading={loading}
      goBack={navigation.goBack}
      title={`${patient?.lastName}, ${patient?.firstName}`}
      rightAction={{
        icon: "more-vertical",
        actionType: "tooltip",
        actions: [
          {
            icon: "printer",
            text: "Einverständiserklärung drucken",
            onPress: () => {
              navigation.push("PDF", {
                typename: "Patient",
                id: patient?._id,
              });
            },
          },
          {
            icon: "edit",
            text: "Patient bearbeiten",
            onPress: () => {
              navigation.push("Form", {
                id: patient?._id,
                defaultDoc: patient,
                typename: "Patient",
                mode: "update",
              });
            },
          },
        ],
      }}
      useFlatList={true}
      scrollComponent={List}
      scrollProps={{
        sections: true,
        ListHeaderComponent: () => (
          <Flex p={10}>
            {askForPrescriptionCharge &&
            patient?.prescriptionCharge === true ? (
              <Flex
                bg="warning:darken:10"
                justifyContent="space-between"
                row
                wrap
                borderRadius={10}
                p={20}
                mb={10}
              >
                <Text font="h5" color="#FFF">
                  Ist der Patient weiterhin Rezeptgebührenbefreit?
                </Text>
                <Flex row>
                  <Button
                    bg="warning:darken:10"
                    color="#FFF"
                    onPress={() => {
                      handleDoc({
                        doc: { prescriptionCharge: true },
                        typename: "Patient",
                        id: patient?._id,
                        mode: "update",
                      });
                    }}
                  >
                    Ja
                  </Button>
                  <Button
                    bg="warning"
                    color="#FFF"
                    onPress={() => {
                      handleDoc({
                        doc: { prescriptionCharge: false },
                        typename: "Patient",
                        id: patient?._id,
                        mode: "update",
                      });
                    }}
                  >
                    Nein
                  </Button>
                </Flex>
              </Flex>
            ) : null}
            {patient?.profileNote ? (
              <Flex
                bg="primary:setAlpha:0.1"
                borderWidth={1}
                borderColor="primary:setAlpha:0.25"
                borderRadius={10}
                p={20}
                mb={10}
              >
                <Text font="label" color="primary">
                  Hinweis zum Patienten
                </Text>
                <Text font="p" color="text">
                  {patient?.profileNote}
                </Text>
              </Flex>
            ) : null}
            <Touchable
              onPress={() => {
                navigation.push("Form", {
                  id: patient?._id,
                  defaultDoc: patient,
                  title: `${patient?.lastName}, ${patient?.firstName} bearbeiten`,
                  typename: "Patient",
                  mode: "update",
                });
              }}
              bg="surface"
              borderRadius={10}
              p={15}
              alignItems="center"
              justifyContent="space-between"
              mb={1}
              row
              wrap
            >
              <Flex>
                <Text>{`${patient?.address || "-"}\n${`${patient?.zipcode} `}${
                  patient?.residence || ""
                }\nRezeptgebührenbefreit: ${
                  patient?.prescriptionCharge ? "Ja" : "Nein"
                }`}</Text>
                {warnings?.length > 0
                  ? warnings.map((w, i) => (
                      <Button
                        size={44}
                        mr={10}
                        mt={10}
                        renderLeft={
                          <Icon name="zap" mr={7} size={22} color="error" />
                        }
                        bg="error"
                        light
                        rounded
                      >
                        {w}
                      </Button>
                    ))
                  : null}
              </Flex>
              <Flex mt={20} row>
                {patient?.address && patient.residence
                  ? renderAction({
                      icon: "map",
                      size: 38,
                      mr: 10,
                      onPress: () => {
                        const platform = isIos ? "ios" : "android";
                        const mapUrls = {
                          ios: `http://maps.apple.com/?q=${patient?.address}+${patient?.residence}`,
                          android: `https://www.google.com/maps/search/?api=1&query=${patient?.address},${patient?.residence}`,
                        };
                        Linking.openURL(mapUrls?.[platform] || mapUrls.android);
                      },
                    })
                  : null}
                {patient?.phone
                  ? renderAction({
                      icon: "phone",
                      size: 38,
                      mr: 10,
                      onPress: () => {
                        Linking.openURL(`tel:${patient?.phone}`);
                      },
                    })
                  : null}
                {renderAction({
                  icon: "edit",
                  size: 38,
                  bg: "primary",
                  color: "#FFF",
                  onPress: () => {
                    navigation.push("Form", {
                      id: patient?._id,
                      defaultDoc: patient,
                      title: `${patient?.lastName}, ${patient?.firstName} bearbeiten`,
                      typename: "Patient",
                      mode: "update",
                    });
                  },
                })}
              </Flex>
            </Touchable>
            <Input
              label="Mein Patient"
              type="switch"
              inline
              roundness={8}
              my={10}
              renderRight={
                inRole(["admin", "client"]) ? (
                  <UserSelect
                    onPress={({ member }) => {
                      let newMyPatient = [...myPatient];
                      if (myPatient.indexOf(member?._id) > -1) {
                        newMyPatient = newMyPatient.filter(
                          (e) => e !== member._id
                        );
                      } else {
                        newMyPatient.push(member?._id);
                      }
                      handleDoc({
                        doc: { myPatient: newMyPatient },
                        typename: "Patient",
                        id: patient?._id,
                        mode: "update",
                      });
                    }}
                    renderRight={({ item }) => {
                      return (
                        <Flex pointerEvents="none">
                          <Switch
                            size={25}
                            value={myPatient.indexOf(item?._id) > -1}
                            activeTrackColor={item?.color}
                          />
                        </Flex>
                      );
                    }}
                    w={50}
                  >
                    {renderAction({ icon: "settings", bg: "surface" })}
                  </UserSelect>
                ) : null
              }
              value={myPatient.indexOf(user?._id) > -1}
              onChange={() => {
                let newMyPatient = [...myPatient];
                if (myPatient.indexOf(user?._id) > -1) {
                  newMyPatient = newMyPatient.filter((e) => e !== user?._id);
                } else {
                  newMyPatient.push(user?._id);
                }
                handleDoc({
                  doc: { myPatient: newMyPatient },
                  typename: "Patient",
                  id: patient?._id,
                  mode: "update",
                });
              }}
              shadow={3}
            />
          </Flex>
        ),
        data: [
          {
            title: "Wunden",
            actions: [
              {
                icon: "plus",
                bg: "primary",
                color: "#FFF",
                onPress: () => {
                  navigation.push("Form", {
                    defaultDoc: { patientId: patient?._id },
                    title: `Neue Wunde`,
                    typename: "Therapy",
                    mode: "insert",
                    queryVariables: {
                      filter: { patientId: patient?._id, healed: false },
                      sort: { updatedAt: -1 },
                    },
                  });
                },
              },
            ],
            ...getListData("Therapy"),
            data: [
              {
                loadMore: "pages",
                typename: "Therapy",
                filter: { patientId: patient?._id },
                sort: { updatedAt: -1 },
                name: "therapys",
                scrollEnabled: false,
                filterOptions: {
                  Geheilt: {
                    key: "healed",
                    formatFilter: (v) => {
                      return v === "true" ? true : { $in: [false, null] };
                    },
                    options: [
                      {
                        label: "Ja",
                        value: "true",
                      },
                      {
                        label: "Nein",
                        value: "false",
                      },
                    ],
                  },
                },
                defaultOptions: {
                  Geheilt: "false",
                },
                renderItem: ({ item, index }) => {
                  return (
                    <ListItem
                      title={`${getTherapyName(item)}`}
                      desc={`Bearbeitet am ${dayjs(item.updatedAt).format(
                        "DD. MMMM YYYY"
                      )}`}
                      icons={
                        item.healed
                          ? [{ name: "check", color: "success" }]
                          : [{ name: "clipboard" }]
                      }
                      actionType="inline"
                      actions={[
                        {
                          icon: "edit",
                          onPress: () => {
                            navigation.push("Form", {
                              id: item?._id,
                              defaultDoc: item,
                              title: `${getTherapyName(item)}bearbeiten`,
                              typename: "Therapy",
                              mode: "update",
                            });
                          },
                        },
                      ]}
                      onPress={() => {
                        navigation.navigate("PatientTherapy", {
                          title: `${patient?.lastName}, ${
                            patient?.firstName
                          } - ${getTherapyName(item)}`,
                          patient: patient,
                          therapy: item,
                        });
                      }}
                    />
                  );
                },
              },
            ],
          },
          {
            title: "Nachweise",
            actions: [
              {
                icon: "plus",
                bg: "primary",
                color: "#FFF",
                onPress: () => {
                  navigation.push("Form", {
                    defaultDoc: { patientId: patient?._id },
                    title: `Neuer Nachweis`,
                    typename: "Evidence",
                    mode: "insert",
                    refetchQueries: ["evidences"],
                  });
                },
              },
            ],
            actionType: "inline",
            ...getListData("Evidence"),
            data: [
              {
                typename: "Evidence",
                sectionTitleSize: "default",
                filter: { patientId: patient?._id },
                sort: { surveyDate: -1 },
                actions: [
                  {
                    icon: "printer",
                    text: "PDF",
                    size: 28,
                    bg: "primary",
                    color: "#FFF",
                    onPress: ({ data }) => {
                      const doc = data?.[0];
                      var start = dayjs(doc.surveyDate)
                        .startOf("month")
                        .toDate();
                      var end = dayjs(doc.surveyDate).endOf("month").toDate();
                      navigation.navigate("PDF", {
                        typename: "Evidence",
                        id: doc?._id,
                        patientId: doc.patientId,
                        options: JSON.stringify({
                          list: true,
                          name: `Monatsnachweise ${dayjs(doc.surveyDate).format(
                            "MMM YYYY"
                          )}`,
                          filter: {
                            patientId: doc.patientId,
                            from: start,
                            to: end,
                            canceled: { $ne: true },
                          },
                        }),
                      });
                    },
                  },
                ],
                actionType: "inline",
                sectionBg: "primary:setAlpha:0.05",
                name: "evidences",
                groupBy: "surveyDate",
                groupByFormat: ({ key, value }) =>
                  dayjs(value).format("MMMM YYYY"),
                scrollEnabled: false,
                renderItem: ({ item, index }) => {
                  return (
                    <ListItem
                      title={dayjs(item.surveyDate).format("DD. MMMM YYYY")}
                      desc={`Bearbeitet am ${dayjs(item.updatedAt).format(
                        "DD. MMMM YYYY"
                      )}`}
                      users={[item.user]}
                      icons={[{ name: "clipboard" }]}
                      iconColor={item.healed ? "success" : "primary"}
                      actions={[
                        {
                          icon: "more-vertical",
                          bg: "primary:setAlpha:0.1",
                          color: "primary",
                        },
                      ]}
                      actionType="inline"
                      onPress={() => {
                        navigation.push("Form", {
                          id: item?._id,
                          defaultDoc: item,
                          typename: "Evidence",
                          mode: "update",
                        });
                      }}
                    />
                  );
                },
              },
            ],
          },

          {
            title: "Dokumente aus Vorlagen",
            actions: ownDocs?.data?.map((doc) => ({
              icon: "plus",
              color: "#FFF",
              text: doc.name,
              bg: "primary",
              onPress: () => {
                navigation.push("Form", {
                  defaultDoc: {
                    ...doc,
                    isDraft: false,
                    patientId: patient?._id,
                  },
                  title: `${doc.name} hinzufügen`,
                  typename: "OwnDoc",
                  mode: "insert",
                  refetchQueries: ["evidences"],
                });
              },
            })),
            ...getListData("OwnDoc"),
            actionType: "tooltip",
            data: [
              {
                loadMore: "pages",
                typename: "OwnDoc",
                filter: { patientId: patient?._id, deleted: { $ne: true } },
                sort: { updatedAt: -1 },
                name: "owndocs",
                scrollEnabled: false,
                renderItem: ({ item, index }) => {
                  return (
                    <ListItem
                      title={`${item.name}`}
                      desc={`Bearbeitet am ${dayjs(item.updatedAt).format(
                        "DD. MMMM YYYY"
                      )}`}
                      icons={[{ name: "file-text" }]}
                      actionType="tooltip"
                      actions={[
                        {
                          icon: "printer",
                          text: "Drucken",
                          onPress: () => {
                            navigation.navigate("PDF", {
                              typename: "OwnDoc",
                              id: item._id,
                              patientId: item.patientId,
                            });
                          },
                        },
                        {
                          icon: "edit",
                          text: "Bearbeiten",
                          onPress: () => {
                            navigation.push("Form", {
                              id: item?._id,
                              defaultDoc: item,
                              title: `${item.name} bearbeiten`,
                              typename: "OwnDoc",
                              mode: "update",
                            });
                          },
                        },
                        {
                          icon: "trash",
                          bg: "error:setAlpha:0.1",
                          color: "error",
                          text: "Löschen",
                          onPress: () => {
                            confirm("Dokument wirklich löschen?", () =>
                              handleDoc({
                                doc: { deleted: true },
                                typename: "OwnDoc",
                                id: item._id,
                                mode: "update",
                              })
                            );
                          },
                        },
                      ]}
                      onPress={() => {
                        navigation.push("Form", {
                          id: item?._id,
                          defaultDoc: item,
                          title: `${item.name} bearbeiten`,
                          typename: "OwnDoc",
                          mode: "update",
                        });
                      }}
                    />
                  );
                },
              },
            ],
          },

          {
            title: "Geteilt mit",
            ...getListData("Share"),
            data: [
              {
                typename: "Share",
                filter: {
                  patientId: patient?._id,
                },
                sort: { createdAt: -1 },
                name: "shares",
                scrollEnabled: false,
                renderItem: ({ item, index }) => {
                  return (
                    <ListItem
                      users={[item.user]}
                      icons={[{ name: "share" }]}
                      title={`${item.shareUserEmail}`}
                      desc={`Geteilt am ${dayjs(item.createdAt).format(
                        "DD. MMMM YYYY"
                      )}`}
                      actions={[
                        ...(item.seenByUser
                          ? [
                              {
                                icon: "eye-check",
                                bg: "transparent",
                                color: "success",
                              },
                            ]
                          : []),
                        ...(item.emailSend
                          ? [
                              {
                                icon: "send",
                                bg: "transparent",
                                color: "success",
                              },
                            ]
                          : []),
                        {
                          icon: "trash",
                          bg: "error:setAlpha:0.1",
                          color: "error",
                          onPress: () => {
                            callMeteor({
                              variables: {
                                name: "deleteShare",
                                config: { shareId: item?._id },
                              },
                              refetchQueries: ["shares"],
                            })
                              .then((result) => {
                                console.log({ result });
                                theme.alert({
                                  type: "success",
                                  message:
                                    "Dokumente werden nicht mehr geteilt",
                                });
                              })
                              .catch((e) => {
                                theme.alert({
                                  type: "error",
                                  message: e.message,
                                });
                              });
                          },
                        },
                      ]}
                      actionType="inline"
                    />
                  );
                },
              },
            ],
          },
          {
            title: "Neuste Aktionen",
            ...getListData("Action"),
            data: [
              {
                typename: "Action",
                filter: {
                  patientId: patient?._id,
                },
                sort: { createdAt: -1 },
                name: "actions",
                scrollEnabled: false,
                renderItem: ({ item, index }) => {
                  return (
                    <ListItem
                      title={`${i18n.t(
                        `types.${item.typeName.toLowerCase()}`
                      )} ${i18n.t(`actions.${item.type}`)}`}
                      desc={dayjs(item.createdAt).format(
                        "HH:MM [Uhr] DD MMMM YYYY"
                      )}
                      users={item.user ? [item.user] : undefined}
                    />
                  );
                },
              },
            ],
          },
        ],
        renderItem: ({ item }, renderToSection) => {
          if (visible[item.typename] === false) return null;
          return (
            <ListQuery
              {...item}
              limit={5}
              gap={0}
              renderToSection={renderToSection}
            />
          );
        },
      }}
    />
  );
}

export default Screen;

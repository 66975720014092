import * as React from "react";
import { useApolloClient } from "@apollo/client";
import { Page, Chat, renderAction } from "components";

import { useAppContext } from "../../context";

function Screen({ navigation }) {
  const client = useApolloClient();
  const { user, refetchUser, callMeteor } = useAppContext();

  React.useEffect(() => {
    if (user?.unseenChats > 0) {
      callMeteor({ variables: { name: "resetUnseenChats", config: {} } }).then(
        () => {
          refetchUser();
        }
      );
    }
  }, []);

  return (
    <Page
      title="Chat"
      leftAction={renderAction({
        icon: "refresh-ccw",
        ml: 10,
        onPress: () => {
          client.refetchQueries({
            include: [`actions`],
          });
        },
      })}
      rightAction={{ icon: "filter", onPress: () => {} }}
      useFlatList={true}
      scrollComponent={Chat}
    />
  );
}

export default Screen;

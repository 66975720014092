import DocList from "./doclist";
import Signature from "./signature";
import File from "./file";
import UserSelect from "./userselect";
import ImageMeasure from "./imageMeasure";
import CutIntensity from "./cutintensity";
import Dekubitus from "./dekubitus";
import DiabeticFoot from "./diabeticfoot";
import LifeAndTreatment from "./LifeAndTreatment";
import Localisation from "./Localisation";
import TextEditor from "./TextEditor";
import WoundGraduation from "./WoundGraduation";
import WoundStatus from "./WoundStatus";
import BarCode from "./BarCode";
import Autocomplete from "./Autocomplete";
import Actions from "./Actions";
import Schema from "./schema";

const inputComponents = {
  DocList,
  Signature,
  File,
  UserSelect,
  ImageMeasure,
  CutIntensity,
  Dekubitus,
  DiabeticFoot,
  LifeAndTreatment,
  Localisation,
  TextEditor,
  WoundGraduation,
  WoundStatus,
  BarCode,
  Autocomplete,
  Actions,
  Schema,
};

export {
  inputComponents,
  DocList,
  Signature,
  File,
  UserSelect,
  ImageMeasure,
  CutIntensity,
  Dekubitus,
  DiabeticFoot,
  LifeAndTreatment,
  Localisation,
  TextEditor,
  WoundGraduation,
  WoundStatus,
  BarCode,
  Autocomplete,
  Actions,
  Schema,
};

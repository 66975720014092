import * as React from "react";
import { View, ScrollView, Dimensions } from "react-native";
import { styled, Button, useTheme, Flex } from "unikit";

import HTML from "react-native-render-html";

const Scroller = styled(ScrollView)({
  borderWidth: 3,
  borderColor: "primary:setAlpha:0.2",
  borderStyle: "dashed",
  borderRadius: 10,
  width: "100%",
  height: Dimensions.get("window").height / 2.5,
  mb: 10,
  p: 15,
  maxWidth: 700,
});

export default ({ navigation, onChange, value, label, readOnly = false }) => {
  const theme = useTheme();
  return (
    <View>
      {readOnly ? (
        <Flex w="100%" center>
          <Scroller contentContainerStyle={{ paddingBottom: 50 }}>
            <HTML
              source={{ html: value }}
              ignoredStyles={["fontFamily"]}
              tagsStyles={{
                p: { color: theme.colors.text },
                h1: { color: theme.colors.text },
                h2: { color: theme.colors.text },
                h3: { color: theme.colors.text },
                h4: { color: theme.colors.text },
                h5: { color: theme.colors.text },
                ul: { color: theme.colors.text },
                li: { color: theme.colors.text },
                span: { color: theme.colors.text },
              }}
            />
          </Scroller>
        </Flex>
      ) : (
        <Button
          width="100%"
          onPress={() => {
            navigation.navigate("RichText", {
              title: label,
              value: value,
              onSave: (newValue, goBack) => {
                onChange(newValue);

                goBack();
              },
            });
          }}
        >
          {`Text bearbeiten`}
        </Button>
      )}
    </View>
  );
};

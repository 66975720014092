import * as React from "react";
import { Grid, Flex, Touchable, Icon, Text, isWeb } from "unikit";

import { Page } from "components";

export default function Stats({ navigation }) {
  const items = [
    {
      title: "Bestellungen",
      icon: "shopping-cart",
      onPress: () => {
        navigation.navigate("Orders", {});
      },
    },
    {
      title: "Wareneingang/\nBestellmanagement",
      icon: "package",
      onPress: () => {
        navigation.navigate("Receipt", {});
      },
    },
    {
      title: "Warenausgang / Umbuchung",
      icon: "refresh-ccw",
      onPress: () => {
        navigation.navigate("Change", {});
      },
    },
    {
      title: "Mein Lager verwalten",
      icon: "user",
      onPress: () => {
        navigation.navigate("UserInventory", {});
      },
    },
    {
      title: "Warenbestand",
      icon: "bar-chart",
      onPress: () => {
        navigation.navigate("Inventory", {});
      },
    },
  ];
  return (
    <Page title="Warenwirtschaft" scrollView>
      <Grid gap={10} min={300} px={10} maxCols={2}>
        {items.map((item, index) => (
          <Touchable
            bg="surface"
            borderRadius={15}
            borderWidth={1}
            borderColor="primary:setAlpha:0.1"
            onPress={item?.onPress}
            key={`item-${index}`}
            h={150}
            shadow={5}
            mb={isWeb ? 5 : 10}
            center
          >
            <Flex mb={15}>
              <Icon name={item?.icon} size={30} />
            </Flex>
            <Text textAlign="center">{item?.title}</Text>
          </Touchable>
        ))}
      </Grid>
    </Page>
  );
}

import * as React from "react";
import { Flex, Tabs } from "unikit";
import { Page, ListQuery, ListItem } from "components";

import i18n from "../../i18n";
import { useAppContext } from "../../context";

function PatientList({ navigation }) {
  const [tab, setTab] = React.useState(i18n.t("my"));
  const { user } = useAppContext();

  const filters = React.useMemo(
    () => ({
      [i18n.t("my")]: {
        archived: false,
        myPatient: { $in: [user?._id] },
      },
      [i18n.t("all")]: {
        archived: false,
      },
      [i18n.t("archiv")]: {
        archived: true,
      },
    }),
    [user?._id]
  );
  const filter = React.useMemo(() => filters[tab], [tab]);
  const renderItem = React.useCallback(({ item }) => (
    <ListItem
      title={`${item.lastName}, ${item.firstName}`}
      desc={`${item.address}, ${item.zipcode} ${item.residence}`}
      users={item.users}
      pills={item.archived ? [{ label: "Archiv" }] : null}
      onPress={() =>
        navigation.navigate("PatientProfile", {
          _id: item._id,
          title: `${item.lastName}, ${item.firstName}`,
        })
      }
    />
  ));

  return (
    <Page
      title="Patienten"
      leftAction={{
        icon: "search",
        onPress: () =>
          navigation.navigate("Search", {
            typename: "Patient",
          }),
      }}
      rightAction={{
        icon: "plus",
        onPress: () =>
          navigation.navigate("Form", {
            title: "Neuer Patient",
            typename: "Patient",
            defaultDoc: {
              myPatient: [user?._id],
              users: [user],
            },
            queryProps: {
              filter: {
                archived: false,
                myPatient: { $in: [user?._id] },
              },
            },
            onSuccess: ({ navigation, item }) => {
              console.log("onSuccess", { item });
              navigation.replace("PatientProfile", {
                _id: item._id,
                title: `${item.lastName}, ${item.firstName}`,
              });
            },
          }),
      }}
      navbarProps={{
        footerComponent: (
          <Flex px={10} py={5} w="100%">
            <Tabs
              size={35}
              value={tab}
              options={[i18n.t("my"), i18n.t("all"), i18n.t("archiv")]}
              onChange={(value) => setTab(value)}
            />
          </Flex>
        ),
      }}
      useFlatList={true}
      scrollComponent={ListQuery}
      scrollProps={{
        loadMoreMode: "scroll",
        renderItem,
        typename: "Patient",
        filter,
        sort: { lastName: 1 },
        groupBy: "lastName",
        groupByFormat: ({ key, value }) => value.toUpperCase().charAt(0),
      }}
    />
  );
}

export default PatientList;

import * as React from "react";
import { Page, ListQuery, ListItem } from "components";
import dayjs from "dayjs";

import { useAppContext } from "../../context";

function Screen({ navigation }) {
  const { user } = useAppContext();

  return (
    <Page
      title="Materialien"
      leftAction={{
        icon: "search",
        onPress: () =>
          navigation.navigate("Search", {
            typename: "MaterialGroup",
          }),
      }}
      rightAction={{
        icon: "plus",
        onPress: () =>
          navigation.navigate("Form", {
            typename: "MaterialGroup",
            queryProps: { filter: { companyId: user.companyId } },
          }),
      }}
      useFlatList={true}
      scrollComponent={ListQuery}
      scrollProps={{
        //loadMoreMode: "scroll",
        renderItem: ({ item }) => (
          <ListItem
            title={`${item.producer}, ${item.name}`}
            desc={`Aktualisiert am: ${dayjs(item.updatedAt).format(
              "DD.MM.YYYY"
            )}`}
            icons={[{ name: "package" }]}
            pills={[
              ...(item.copyId
                ? [{ bg: "primary", color: "#FFF", label: "Sync" }]
                : []),
              ...(item.companyId === "global"
                ? [{ bg: "primary", color: "#FFF", label: "Global" }]
                : []),
            ]}
            onPress={() =>
              navigation.navigate("Form", {
                id: item._id,
                defaultDoc: item,
                title: `${item.name} bearbeiten`,
                typename: "MaterialGroup",
                mode: "update",
              })
            }
          />
        ),
        typename: "MaterialGroup",
        filter: { companyId: user.companyId },
        sort: { category: 1 },
        groupBy: "category",
      }}
    />
  );
}

export default Screen;

import * as React from "react";
import dayjs from "dayjs";

import { useData } from "api";
import { Page, useTypeQuery, Calendar } from "components";

import { useAppContext } from "../../context";

function Screen({ navigation }) {
  const { user, getUser, team } = useAppContext();
  const [date, setDate] = React.useState(new Date());
  const [activeGroups, setGroups] = React.useState([user._id]);
  const [filter, setFilter] = React.useState({
    ...(user ? { groupId: { $in: [user._id] } } : {}),
    from: dayjs(date).startOf("week").toDate(),
    to: dayjs(date).endOf("week").toDate(),
  });

  const { data, refetch, loading } = useData({
    typename: "Appointment",
    variables: {
      filter,
      sort: { datum: 1 },
      limit: 1000,
    },
  });

  React.useEffect(() => {
    if (activeGroups.length > 0) {
      setFilter({ ...filter, groupId: { $in: activeGroups } });
    } else {
      const newFilter = Object.assign({}, filter);
      delete newFilter["groupId"];
      setFilter(newFilter);
    }
  }, [activeGroups]);

  React.useEffect(() => {
    setFilter({
      ...filter,
      from: dayjs(date).startOf("week").toDate(),
      to: dayjs(date).endOf("week").toDate(),
    });
  }, [date]);

  return (
    <Page
      title="Kalender"
      scrollable={false}
      leftAction={[
        {
          icon: "search",
          onPress: () =>
            navigation.navigate("Search", {
              typename: "Appointment",
            }),
        },
        {
          icon: "refresh-ccw",
          loading,
          onPress: () => {
            refetch();
          },
        },
      ]}
      rightAction={{
        icon: "plus",
        onPress: () =>
          navigation.navigate("Form", {
            title: "Neuer Termin",
            typename: "Appointment",
            mode: "insert",
            queryProps: { filter },
            onSuccess: ({ navigation }) => {
              navigation.goBack();
            },
          }),
      }}
    >
      <Calendar
        date={date}
        timeSteps={0.25}
        endTime={20}
        onChangeDate={(d) => setDate(d)}
        filter={activeGroups}
        onChangeFilter={(filter) => setGroups(filter)}
        groups={team.map((member) => ({
          image: member?.image?.url,
          name: `${member?.firstName} ${member?.lastName}`,
          char: `${member?.firstName?.charAt(0)}${member?.lastName?.charAt(0)}`,
          id: member?._id,
          bg: member?.color,
        }))}
        filter={activeGroups}
        items={
          data
            ? data
                .filter((a) => a.deleted !== true)
                .map((item) => ({
                  id: item._id,
                  title: item.patient,
                  desc: item.reason,
                  date: item.datum,
                  done: item.done,
                  duration: item.time,
                  groupId: item.groupId,
                  allDay: item.daily,
                  allDayEnd: item.datum_daily,
                  color: getUser(item.groupId)
                    ? getUser(item.groupId).color
                    : "primary",
                  data: item,
                }))
            : []
        }
        onSlotPress={(date) => {
          navigation.navigate("Form", {
            title: "Neuer Termin",
            typename: "Appointment",
            mode: "insert",
            defaultDoc: {
              datum: date,
              groupId: activeGroups.length === 1 ? activeGroups[0] : user._id,
              time: 30,
              user: activeGroups.length === 1 ? getUser(activeGroups[0]) : user,
            },
            queryProps: { filter },
            onSuccess: ({ navigation }) => {
              navigation.goBack();
            },
          });
        }}
        onItemPress={(event) => {
          navigation.navigate("Form", {
            title: event?.title || null,
            defaultDoc: event.data,
            id: event.data?._id,
            typename: "Appointment",
            mode: "update",
            onSuccess: ({ navigation }) => {
              navigation.goBack();
            },
          });
        }}
      />
    </Page>
  );
}

export default Screen;

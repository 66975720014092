import * as React from "react";
import { StatusBar, Platform } from "react-native";
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from "@react-navigation/native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { createStackNavigator } from "@react-navigation/stack";

import { useTheme, Drawer as CustomDrawer, Alert, isAndroid } from "unikit";
import { PatientList, PatientProfile, PatientTherapy } from "screens/patient";
import {
  Chat,
  Events,
  Materials,
  Contacts,
  Stats,
  OwnDocs,
} from "screens/company";
import { Form, Search, PDF, DocTable } from "screens/general";
import { UserProfile, Admin, Auth } from "screens/user";
import { ShareList, ShareProfile } from "screens/share";
import { RichText, File } from "screens/input";
import {
  WhOverview,
  Orders,
  Receipt,
  Change,
  Inventory,
  UserInventory,
} from "screens/warehouse";

import { ShareButton, GlobalInfo, Chatra } from "components";
import { Push } from "./expo";
import { version } from "./version";

import { useAppContext, formatUserName } from "./context";

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

const screenOptions = {
  headerShown: false,
  safeAreaInset: "never",
  animationEnabled: true,
  presentation: "card",
};

const modalOptions = {
  headerShown: false,
  safeAreaInset: "never",
  animationEnabled: true,
  cardStyle: {
    paddingTop: isAndroid ? 20 : 0,
  },
};

const AppNavigation = ({ authRef }) => {
  const theme = useTheme();

  const { user, drawerType, drawerSize, inRole, logout, set } = useAppContext();

  const routes = React.useMemo(
    () => [
      {
        name: "Patienten",
        roles: ["client", "employee"],
        options: {
          icon: "user",
        },
        component: PatientList,
      },
      {
        name: "Geteilt",
        options: {
          icon: "share",
        },
        component: ShareList,
      },
      {
        name: "Kalender",
        roles: ["client", "employee"],
        options: {
          icon: "calendar",
        },
        component: Events,
      },
      {
        name: "Team Chat",
        options: {
          icon: "message-circle",
          pill: user?.unseenChats > 0 ? user?.unseenChats : undefined,
        },
        component: Chat,
      },
      ...(user?.warehouse === true
        ? [
            {
              name: "Warenwirtschaft",
              roles: ["client", "employee"],
              options: {
                icon: "package",
              },
              component: WhOverview,
            },
          ]
        : []),
      {
        name: "Dokumente",
        roles: ["client", "employee"],
        options: {
          icon: "file-text",
        },
        component: DocTable,
      },
      {
        name: "Eigene Dokumente",
        roles: ["client"],
        options: {
          icon: "file-text",
        },
        component: OwnDocs,
      },
      {
        name: "Materialien",
        roles: ["client", "employee"],
        options: {
          icon: "bandage",
        },
        component: Materials,
      },
      {
        name: "Kontakte",
        roles: ["client", "employee"],
        options: {
          icon: "paperclip",
        },
        component: Contacts,
      },
      {
        name: "Analytics",
        roles: ["client"],
        options: {
          icon: "pie-chart",
        },
        component: Stats,
      },
      {
        name: "Admin",
        roles: ["admin"],
        options: {
          icon: "settings",
        },
        component: Admin,
      },
      {
        name: "Account",
        options: {
          hidden: true,
        },
        component: UserProfile,
      },
    ],
    [user?.unseenChats]
  );

  const screens = React.useMemo(() => {
    return routes.map((route, i) => {
      if (route?.roles && !inRole(route.roles)) return null;
      return (
        <Drawer.Screen
          key={`drawer-${i}`}
          name={`${route.name}`}
          options={route.options}
        >
          {() => (
            <Stack.Navigator>
              <Stack.Group>
                <Stack.Screen
                  name={`${route.name}`}
                  component={route.component}
                  options={{
                    ...screenOptions,
                  }}
                />
                <Stack.Screen
                  name="PatientProfile"
                  component={PatientProfile}
                  options={{
                    ...screenOptions,
                  }}
                />
                <Stack.Screen
                  name="PatientTherapy"
                  component={PatientTherapy}
                  options={{
                    ...screenOptions,
                  }}
                />
                <Stack.Screen
                  name="Form"
                  component={Form}
                  options={{
                    ...screenOptions,
                    gestureEnabled: false,
                  }}
                />

                <Stack.Screen
                  name="File"
                  component={File}
                  options={{
                    ...screenOptions,
                  }}
                />

                <Stack.Screen
                  name="ShareProfile"
                  component={ShareProfile}
                  options={{
                    ...screenOptions,
                  }}
                />
                <Stack.Screen
                  name="Orders"
                  component={Orders}
                  options={{
                    ...screenOptions,
                  }}
                />
                <Stack.Screen
                  name="Receipt"
                  component={Receipt}
                  options={{
                    ...screenOptions,
                  }}
                />
                <Stack.Screen
                  name="Change"
                  component={Change}
                  options={{
                    ...screenOptions,
                  }}
                />
                <Stack.Screen
                  name="Inventory"
                  component={Inventory}
                  options={{
                    ...screenOptions,
                  }}
                />
                <Stack.Screen
                  name="UserInventory"
                  component={UserInventory}
                  options={{
                    ...screenOptions,
                  }}
                />
              </Stack.Group>
              <Stack.Group screenOptions={{ presentation: "modal" }}>
                <Stack.Screen
                  name="Search"
                  component={Search}
                  options={{
                    ...modalOptions,
                  }}
                />
                <Stack.Screen
                  name="PDF"
                  component={PDF}
                  options={{
                    ...modalOptions,
                  }}
                />
                <Stack.Screen
                  name="RichText"
                  component={RichText}
                  options={{
                    ...modalOptions,
                  }}
                />
              </Stack.Group>
            </Stack.Navigator>
          )}
        </Drawer.Screen>
      );
    });
  }, [user?.roles]);

  const screensLinking = routes.reduce((acc, route) => {
    acc[route.name] = {
      initialRouteName: route.name,
      screens: {
        [route.name]: "",
      },
      path: route.name.toLowerCase(),
    };
    return acc;
  }, {});

  const linking = {
    prefixes: ["https://app.clouddoku.de", "clouddoku://"],
    config: {
      screens: {
        ...screensLinking,
        NoMatch: "*",
      },
    },
  };

  return (
    <NavigationContainer
      linking={user ? linking : undefined}
      fallback={null}
      theme={
        theme.mode === "dark"
          ? {
              ...DarkTheme,
              colors: {
                ...DarkTheme.colors,
                background: "#000",
              },
            }
          : {
              ...DefaultTheme,
              colors: {
                ...DefaultTheme.colors,
                background: "#FFF",
              },
            }
      }
    >
      {user ? (
        <Drawer.Navigator
          initialRouteName="Home"
          drawerContent={(props) => (
            <CustomDrawer
              small={drawerSize === 60}
              avatar={{
                source: require(`./assets/images/bg.jpg`),
                image: require(`./assets/images/logo.png`),
              }}
              version={`v${version}`}
              user={{
                name: formatUserName({ user }),
                source: { uri: user?.image?.url },
                route: "Account",
              }}
              FooterComponent={<Chatra label="Hilfe/Support" user={user} />}
              onBurgerPress={({ toggleDrawer }) => {
                if (drawerType === "permanent") {
                  set({ drawerSize: drawerSize === 260 ? 60 : 260 });
                } else {
                  toggleDrawer();
                }
              }}
              {...props}
            />
          )}
          screenOptions={{
            headerShown: false,
            drawerStyle: {
              backgroundColor: "transparent",
              width: drawerSize,
            },
            drawerLabelStyle: { color: "#FFF" },
            overlayColor: "transparent",
            drawerType,
            swipeEdgeWidth: 10,
          }}
        >
          {screens}
        </Drawer.Navigator>
      ) : (
        <Auth authRef={authRef || {}} />
      )}

      {Platform.OS === "ios" && (
        <StatusBar
          barStyle={theme.mode === "dark" ? "light-content" : "dark-content"}
        />
      )}
      <ShareButton />
      <Alert />
      {user ? <Push /> : null}
      {user ? <GlobalInfo user={user} /> : null}
    </NavigationContainer>
  );
};

export default AppNavigation;

import SimpleSchema, { minWidth } from "./simple";

const schema = {
  admissionDate: {
    type: Date,
    label: "Erhebungsdatum",
    defaultValue: function () {
      return new Date();
    },
    input: "date",
    group: "Allgemeine Erhebungsdaten",
    groupOptions: {
      width: minWidth,
      mode: "grid",
    },
  },
  firstName: {
    type: String,
    label: "Vorname",
    max: 150,
    group: "Allgemeine Erhebungsdaten",
  },
  lastName: {
    type: String,
    label: "Nachname",
    max: 150,
    group: "Allgemeine Erhebungsdaten",
  },
  birthday: {
    type: Date,
    label: "Geburtsdatum",
    defaultValue: function () {
      return new Date();
    },
    input: "date",
    group: "Allgemeine Erhebungsdaten",
  },
  address: {
    type: String,
    label: "Adresse",
    max: 500,
    group: "Allgemeine Erhebungsdaten",
  },
  zipcode: {
    type: String,
    label: "PLZ",
    max: 150,
    group: "Allgemeine Erhebungsdaten",
  },
  residence: {
    type: String,
    label: "Ort",
    max: 150,
    group: "Allgemeine Erhebungsdaten",
  },
  phone: {
    type: String,
    label: "Telefon",
    max: 150,
    optional: true,
    group: "Allgemeine Erhebungsdaten",
  },
  email: {
    type: String,
    label: "E-Mail",
    max: 150,
    optional: true,
    group: "Allgemeine Erhebungsdaten",
  },
  note: {
    type: String,
    label: "Bemerkung",
    max: 1000,
    optional: true,
    input: "textarea",
    group: "Bemerkung",
    inputProps: {
      hideLabel: true,
    },
  },
  profileNote: {
    type: String,
    label: "Bemerkung Profil",
    max: 1000,
    optional: true,
    input: "textarea",
    group: "Bemerkung Übersicht",
    inputProps: {
      hideLabel: true,
    },
  },
  insurer: {
    type: String,
    label: "Versicherer",
    max: 150,
    optional: true,
    group: "Versicherung",
    groupOptions: {
      width: minWidth,
      mode: "grid",
    },
  },
  insuranceNumber: {
    type: String,
    label: "Versicherungsnummer",
    max: 100,
    optional: true,
    group: "Versicherung",
  },
  insuranceState: {
    type: String,
    label: "Versicherungsart",
    optional: true,
    options: [
      {
        label: "Pflichtversichert",
        value: "compulsorilyInsured",
      },
      {
        label: "Privatversichert",
        value: "privateInsured",
      },
    ],
    input: "select",
    group: "Versicherung",
  },
  AHVNumber: {
    type: String,
    label: "AHV Nummer",
    max: 100,
    optional: true,
    group: "Versicherung",
  },
  prescriptionCharge: {
    type: Boolean,
    label: "Rezeptgebührenbefreit",
    optional: true,
    input: "switch",
    inputGroup: "Versicherung",
    group: "Versicherung",
  },
  archivedReason: {
    type: String,
    label: "Grund für Archivierung",
    options: [
      {
        label: "Verstorben",
        value: "deceased",
      },
      {
        label: "Geheilt",
        value: "cured",
      },
      {
        label: "Entlassen",
        value: "released",
      },
      {
        label: "Anderer Grund",
        value: "other",
      },
    ],
    optional: true,
    input: "select",
    group: "Archiv",
    groupOptions: {
      width: minWidth,
      mode: "grid",
    },
  },
  archived: {
    type: Boolean,
    label: "Archiviert",
    defaultValue: function () {
      return false;
    },
    input: "switch",
    inputGroup: "switch",
    group: "Archiv",
  },
  contactIDs: {
    type: Array,
    label: "Kontakte",
    input: "DocList",
    optional: true,
    group: "Kontakte",
    defaultValue: function () {
      return [];
    },
    inputProps: {
      hideLabel: true,
      allowSearch: true,
      allowCreate: true,
      allowEdit: true,
      typename: "Contact",
      title: (item) => {
        return `${item.name}`;
      },
      desc: (item) => {
        return `${item.typ}`;
      },
      note: (item) => {
        return `${item.address}\n${item.zipCode}, ${item.residence}\n\nTelefon: ${item.phone}\nFax: ${item.fax}\nE-Mail: ${item.email}`;
      },
      actions: ({ item, Linking, platform }) => [
        ...(item?.phone
          ? [
              {
                icon: "phone",
                text: "Anrufen",
                onPress: () => {
                  Linking.openURL(`tel:${item?.phone}`);
                },
              },
            ]
          : []),
        ...(item?.mobile
          ? [
              {
                icon: "phone",
                text: "Mobil anrufen",
                onPress: () => {
                  Linking.openURL(`tel:${item?.mobile}`);
                },
              },
            ]
          : []),
        ...(item?.email
          ? [
              {
                icon: "mail",
                text: "Neue E-Mail",
                onPress: () => {
                  Linking.openURL(`mailto:${item?.email}`);
                },
              },
            ]
          : []),
        {
          icon: "map",
          text: "Route",
          onPress: () => {
            const mapUrls = {
              ios: `http://maps.apple.com/?q=${item?.address}+${item?.residence}`,
              android: `https://www.google.com/maps/search/?api=1&query=${item?.address},${item?.residence}`,
            };
            Linking.openURL(mapUrls?.[platform] || mapUrls.android);
          },
        },
      ],
      noteProps: {
        px: 20,
        pb: 20,
        pt: 26,
        labelProps: {
          lineHeight: 17,
        },
      },
      min: 400,
    },
    listedPDF: false,
  },
  "contactIDs.$": {
    type: String,
    listed: false,
  },
  sharedCompanyIds: {
    type: [String],
    label: "sharedCompanyIds",
    optional: true,
    listed: false,
  },
  oldData: {
    type: Boolean,
    label: "Alte Daten vorhanden",
    defaultValue: function () {
      return false;
    },
    optional: true,
    group: "Allgemeine Erhebungsdaten",

    input: "switch",
  },
  informedConsentImage: {
    type: String,
    label: "Unterschrift",
    group: "Einverständnisserklärung",
    listed: true,
    input: "Signature",
    optional: true,
  },
  informedConsentDate: {
    type: Date,
    label: "informedConsentDate",
    group: "Einverständnisserklärung",
    input: "date",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    listed: false,
  },
  createdBy: {
    type: String,
    listed: false,
    label: "Erstellt von",
  },
  companyId: {
    type: String,
    listed: false,
  },
  sharedCompanyIds: {
    type: Array,
    label: "companyIds",
    optional: true,
    listed: false,
  },
  "sharedCompanyIds.$": {
    type: String,
    listed: false,
  },
  myPatient: {
    type: Array,
    optional: true,
    listed: false,
    label: "Betreut von",
    autoValue: function () {
      if (this.isInsert && this.userId) {
        return [this.userId];
      } else if (this.isUpsert && this.userId) {
        return [this.userId];
      }
    },
  },
  "myPatient.$": {
    type: String,
    listed: false,
  },
  sharedCompanys: {
    type: Array,
    label: "sharedCompanysId",
    optional: true,
    listed: false,
  },
  "sharedCompanys.$": {
    type: String,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Patients = new Mongo.Collection("patients");
  Patients.schema = simpleSchema;
  Patients.attachSchema(simpleSchema);
  Patients._ensureIndex({ companyId: 1 });
}

export { simpleSchema as PatientSchema };

export default schema;

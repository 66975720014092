import * as React from "react";
import { Dimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useMutation } from "@apollo/client";

import { CALL_METEOR, useClient, useData } from "api";
import contextRef from "./contextRef";

export const AppState = React.createContext();

export { useNavigation };

export function setAppContext(obj) {
  return contextRef.current.setContext(obj);
}

export function formatUserName({ user, short = false }) {
  if (user?.lastName === "-" && user?.firstName === "-" && !short) {
    return user.emails?.[0]?.address;
  } else if (short === true) {
    return `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`;
  } else {
    return `${user?.lastName}, ${user?.firstName}`;
  }
}

const isLarge = Dimensions.get("window").width > 1024;

export const AppContextProvider = ({ children }) => {
  const [state, setState] = React.useState({
    user: null,
    drawerType: isLarge ? "permanent" : "slide",
    drawerSize: 260,
  });
  const set = (obj) => {
    setState({ ...state, ...obj });
  };

  const { logout } = useClient();
  const [callMeteor, callMeteorState] = useMutation(CALL_METEOR);
  const { loading, error, data, refetch } = useData({
    typename: "CurrentUser",
  });

  const currentUser = state.user;

  React.useEffect(() => {
    if (data) {
      set({ user: data });
    }
  }, [data, error]);

  console.log({
    currentUser: `${currentUser?.emails?.[0].address} ${currentUser?._id}`,
    loading,
    error,
  });

  React.useImperativeHandle(contextRef, () => ({
    setContext: (obj) => {
      set(obj);
    },
  }));

  const [sharing, setShare] = React.useState({
    email: "",
    title: `${currentUser?.lastName}, ${currentUser?.firstName} möchte folgende Dokumente mit Ihnen teilen.`,
    note: "",
    patientWithDocs: [],
    count: 0,
  });

  const setSharing = (shareObj, removeId) => {
    if (removeId) {
      setShare((sObj) => {
        const s = { ...sObj };
        const newPatients = s.patientWithDocs.filter(
          (p) => p.patientId !== removeId
        );
        return {
          ...s,
          patientWithDocs: [...newPatients],
          count: s.count - 1,
        };
      });
    } else {
      setShare((sObj) => {
        const s = { ...sObj };
        const found = s.patientWithDocs.find(
          (p) => p.patientId === shareObj.patientId
        );
        if (found) {
          const foundDoc = found.documents.find(
            (d) => d._id === shareObj.doc._id
          );
          if (!foundDoc) {
            found.documents.push(shareObj.doc);
          }
        } else {
          s.patientWithDocs.push({
            patientName: shareObj.patientName,
            patientId: shareObj.patientId,
            documents: [shareObj.doc],
          });
          s.count = s.count + 1;
        }
        return s;
      });
    }
  };

  const inRole = (roles, userRoles) => {
    if (!userRoles) userRoles = currentUser?.roles || [];
    if (userRoles && userRoles.length > 0 && typeof userRoles === "object") {
      return userRoles?.filter((element) => roles.includes(element)).length > 0;
    } else {
      return false;
    }
  };

  const team = currentUser ? [currentUser, ...(currentUser?.team || [])] : [];

  const getUser = (userId) => {
    return team.find((t) => t._id === userId);
  };

  return (
    <AppState.Provider
      value={{
        ...state,
        set,
        userLoading: loading,
        refetchUser: () => {
          refetch?.();
        },
        logout,
        inRole,
        sharing,
        setSharing,
        getUser,
        callMeteor,
        callMeteorState,
        team,
      }}
    >
      {children}
    </AppState.Provider>
  );
};

export const useAppContext = () => {
  const appState = React.useContext(AppState);
  return appState;
};

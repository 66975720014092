import React, { useCallback, useReducer } from "react";
import { Text, View, TouchableOpacity, ScrollView } from "react-native";
import { color } from "unikit";

const reducer = (state, _action) => !state;

const useForceUpdate = () => {
  const [, dispatch] = useReducer(reducer, true);

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = useCallback((): void => {
    dispatch(null);
  }, [dispatch]);
  return memoizedDispatch;
};

export default ({ value, options, onChange }) => {
  const forceUpdate = useForceUpdate();
  return (
    <ScrollView
      style={{ width: "100%" }}
      contentContainerStyle={{ minWidth: "100%" }}
      horizontal
    >
      <View style={{ flexDirection: "row", minWidth: "100%" }}>
        {options.map((data, index) => {
          return (
            <View
              key={index}
              style={{
                flex: 1,
                minWidth: 200,
                paddingRight: 2,
              }}
            >
              <TouchableOpacity
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 100,
                  paddingHorizontal: 15,
                  backgroundColor:
                    value && value[data.name] === true
                      ? color("#9A9EB2").setAlpha(0.5).toRgbString()
                      : color("#9A9EB2").setAlpha(0.15).toRgbString(),
                }}
                onPress={() => {
                  const newValue = value || {};
                  newValue[data.name] = newValue[data.name] ? false : true;
                  onChange(newValue);
                  forceUpdate();
                }}
              >
                <Text style={[{ fontSize: 14, textAlign: "center" }]}>
                  {data.label}
                </Text>
                <Text
                  style={[
                    {
                      fontSize: 10,
                      marginTop: 5,
                      textAlign: "center",
                      maxWidth: 200,
                    },
                  ]}
                >
                  {data.sublabel}
                </Text>
              </TouchableOpacity>
              <View
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 300,
                  marginTop: 2,
                  paddingHorizontal: 15,
                  backgroundColor: "#79b23e",
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                    textAlign: "center",
                    color: "#FFF",
                    maxWidth: 200,
                  }}
                >
                  {data.desc}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
    </ScrollView>
  );
};

import React, { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform, ScrollView, Image as RnImage } from "react-native";
import {
  Animate,
  Flex,
  Group,
  Button,
  styled,
  Text,
  Image,
  Icon,
  Touchable,
} from "unikit";
import * as ImageManipulator from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import * as Permissions from "expo-permissions";
import { Page, renderAction } from "components";
import dayjs from "dayjs";

const Scroller = styled(ScrollView)({ flex: 1 });

export default function Screen({ route, navigation: { goBack } }) {
  const [lastFiles, setLastFiles] = useState([]);
  const [uri, setUri] = useState(null);
  const [image, setImage] = useState(null);
  const [add, setAdd] = useState(true);
  const onSelect = route?.params?.onSelect;

  useEffect(() => {
    async function askPermissionsAsync() {
      await Permissions.askAsync(Permissions.CAMERA);
      await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
    }
    askPermissionsAsync();
    if (Platform.OS === "ios") {
      AsyncStorage.getItem("CdLastFiles", (err, result) => {
        if (result !== null) {
          setLastFiles(JSON.parse(result));
        } else {
          console.log("Data Not Found");
        }
      });
    } else {
      AsyncStorage.setItem("CdLastFiles", null);
    }
  }, []);

  const setFile = (result) => {
    var file = result.base64 || result.uri;
    var uri = result.uri;
    if (file.indexOf("data:") === -1) {
      file = `data:image/jpeg;base64,${file}`;
    }
    setImage(file);
    setUri(uri);
    //setLastFiles((f) => [...f, { base64: file, selectedAt: new Date() }]);
  };

  const pickImage = async (cam) => {
    if (!cam) {
      var result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: "Images",
        allowsEditing: false,
        base64: true,
        quality: 0.8,
        exif: true,
      });
    } else {
      var result = await ImagePicker.launchCameraAsync({
        allowsEditing: false,
        base64: true,
        quality: 0.8,
        exif: true,
      });
    }
    console.log({ result });
    const resizeResult = await ImageManipulator.manipulateAsync(
      Platform.OS === "ios"
        ? result.uri || result.base64
        : result.uri || result.base64,
      [{ resize: { width: 2000 } }],
      { compress: 0.8, format: ImageManipulator.SaveFormat.JPEG, base64: true }
    );

    console.log({ resizeResult });

    if (resizeResult.base64 || resizeResult.uri) {
      setFile(resizeResult);
      setAdd(true);
    }
  };

  const rotate = async (deg) => {
    const result = await ImageManipulator.manipulateAsync(
      Platform.OS === "ios" ? uri || image : image,
      [{ rotate: deg }],
      { compress: 0.8, format: ImageManipulator.SaveFormat.JPEG, base64: true }
    );

    if (result.base64 || result.uri) {
      setFile(result);
    }
  };

  return (
    <Page
      title="Datei wählen"
      style={{ minHeight: "100%", position: "relative" }}
      scrollable={false}
      goBack={goBack}
      navbarProps={{
        footerComponent: (
          <Flex px={10} p={5} row w="100%">
            <Button
              flex={1}
              onPress={() => {
                pickImage(true);
              }}
              bg="transparent"
              color="primary"
              renderLeft={renderAction({ icon: "camera", size: 30, mr: 8 })}
            >
              Kamera öffnen
            </Button>
            <Button
              flex={1}
              onPress={() => {
                pickImage();
              }}
              bg="transparent"
              color="primary"
              renderLeft={renderAction({ icon: "image", size: 30, mr: 8 })}
            >
              Foto auswählen
            </Button>
          </Flex>
        ),
      }}
    >
      {lastFiles?.length > 0 ? (
        <Flex w="100%" h={150} bg="surface">
          <Text level={5} ml={25} mt={15}>
            Zuletzt gewählte Bilder
          </Text>

          <Scroller horizontal w="100%" bg="surface" p={25} h={175} pt={7}>
            {lastFiles.map((item, index) => {
              return (
                <Touchable
                  onPress={() => {
                    setImage(item.base64);
                    setAdd(false);
                  }}
                >
                  <RnImage
                    key={index}
                    style={{
                      width: 150,
                      height: 75,
                      resizeMode: "contain",
                      backgroundColor: "rgba(0,0,0,0.1)",
                    }}
                    source={{ uri: item.base64 }}
                  />
                  <Text mt={5} font="label">
                    {dayjs(item.selectedAt).format("DD.MM.YYYY")}
                  </Text>
                </Touchable>
              );
            })}
          </Scroller>
        </Flex>
      ) : null}
      <Scroller>
        <Flex w="100%" p={30} flexCenter>
          {image ? (
            <Image
              source={{ uri: image }}
              width="100%"
              style={{ maxWidth: 800 }}
            />
          ) : null}
        </Flex>
      </Scroller>
      {image ? (
        <Animate absolute l="50%" w={370} shadow={30} b={50} ml={-185}>
          <Group style={{ borderRadius: 25 }} gap={2}>
            <Button
              onPress={() => {
                rotate(90);
              }}
              w={60}
            >
              <Icon name="refresh-cw" color="#FFF" />
            </Button>

            <Button
              onPress={() => {
                if (onSelect) {
                  onSelect(image);
                  goBack();
                  if (add === true) {
                    const fileObj = {
                      base64: image,
                      selectedAt: new Date(),
                    };
                    var LastFiles = lastFiles;
                    LastFiles.unshift(fileObj);
                    if (LastFiles.length > 10) {
                      LastFiles = LastFiles.slice(9, -1);
                    }
                    AsyncStorage.setItem(
                      "CdLastFiles",
                      JSON.stringify(LastFiles)
                    );
                  }
                }
              }}
            >
              Verwenden
            </Button>

            <Button
              onPress={() => {
                rotate(-90);
              }}
              w={60}
            >
              <Icon name="refresh-ccw" color="#FFF" />
            </Button>
          </Group>
        </Animate>
      ) : null}
    </Page>
  );
}

import * as React from "react";
import { Flex, useTheme } from "unikit";
import dayjs from "dayjs";

import { Page, List, ListItem, ListQuery } from "components";
import { useHandleDoc } from "api";

import i18n from "../../i18n";
import { useAppContext } from "../../context";

function Screen({ route, navigation }) {
  const theme = useTheme();
  const { handleDoc } = useHandleDoc();
  const { callMeteor } = useAppContext();
  const getSubListData = (typename, r) => ({
    typename: typename,
    sort: { surveyDate: -1 },
    name: `${typename.toLowerCase()}s`,
    scrollEnabled: false,
    renderItem: (p) => renderItem(p),
    ...r,
  });

  const renderItem = ({ item }, rest = {}) => {
    return (
      <ListItem
        title={`${item.name}`}
        desc={`Bei ${item.contact?.name || ""} vom ${dayjs(
          item.surveyDate
        ).format("DD. MMMM YYYY")}`}
        icons={[
          { name: rest.icon || "shopping-cart" },
          ...(item.emailSend
            ? [
                {
                  name: "send",
                  color: "success",
                  bg: "transparent",
                  color: "success",
                },
              ]
            : []),
        ]}
        onPress={() => {
          navigation.navigate("Form", {
            title: `${item.name} bearbeiten`,
            id: item._id,
            defaultDoc: item,
            mode: "update",
            typename: item.__typename,
            onSuccess: ({ navigation, client }) => {
              navigation?.goBack();
              client.refetchQueries({
                include: ["orders"],
              });
            },
          });
        }}
        image={
          item.image && !item.canceled ? { uri: item.image.url } : undefined
        }
        pills={[...(item.canceled ? [{ bg: "error", label: "cancled" }] : [])]}
        actions={[
          {
            icon: "file-text",
            text: "PDF erstellen",
            onPress: () => {
              navigation.navigate("PDF", {
                typename: item.__typename,
                id: item._id,
                options: undefined,
              });
            },
          },
          {
            icon: "send",
            text: `Bestellung ${item.send ? "erneut " : ""}per E-Mail senden`,
            onPress: () => {
              callMeteor({
                variables: {
                  name: "sendOrder",
                  config: { orderId: item._id },
                },
                refetchQueries: ["orders"],
              })
                .then(() => {
                  theme.alert({
                    type: "success",
                    message:
                      "Ihre Bestellung wurde erfolgreich an den Lieferanten übermittelt",
                  });
                })
                .catch((e) => {
                  theme.alert({
                    type: "error",
                    message: e.message,
                  });
                });
            },
          },
          {
            icon: "x",
            text: `Bestellung stornieren`,
            onPress: () => {
              handleDoc({
                id: item._id,
                doc: {
                  canceled: true,
                },
                typename: "Order",
                mode: "update",
              });
            },
          },
        ]}
        {...rest}
      />
    );
  };

  return (
    <Page
      goBack={navigation.goBack}
      title="Bestellungen"
      rightAction={{
        icon: "search",
        onPress: () =>
          navigation.navigate("Search", {
            typename: "Order",
          }),
      }}
      useFlatList={true}
      scrollComponent={List}
      scrollProps={{
        sections: true,
        data: [
          {
            title: "Offene Bestellungen",
            actionType: "inline",
            height: 60,
            actions: [
              {
                icon: "plus",
                bg: "primary",
                color: "#FFF",
                onPress: () => {
                  navigation.navigate("Form", {
                    title: `${i18n.t(`types.order`)} hinzufügen`,
                    mode: "insert",
                    typename: "Order",
                    defaultDoc: {},
                    refetchQueries: ["orders"],
                    onSuccess: ({ navigation, client }) => {
                      navigation?.goBack();
                      // client.refetchQueries({
                      //   include: ["orders"],
                      // });
                    },
                  });
                },
              },
            ],
            data: [
              {
                ...getSubListData("Order", {
                  filter: { send: false, canceled: { $ne: true } },
                }),
              },
            ],
          },
          {
            title: "Artikel im Zulauf",
            height: 60,
            data: [
              {
                ...getSubListData("Order", {
                  filter: {
                    send: true,
                    booked: { $ne: true },
                    canceled: { $ne: true },
                  },
                  renderItem: ({ item, index }) => {
                    return item.materials.map((mat, i) => {
                      if (mat.count - (mat.bookedCount || 0) < 1) return null;
                      return (
                        <ListItem
                          key={`mat-${i}`}
                          title={`${mat.count - (mat.bookedCount || 0)}x ${
                            mat.name
                          }, Größe: ${mat.size}`}
                          desc={`Bestellt am ${dayjs(item.createdAt).format(
                            "DD. MMMM YYYY"
                          )}`}
                        />
                      );
                    });
                  },
                }),
              },
            ],
          },
          {
            title: "Bestellhistorie",
            height: 60,
            data: [
              {
                ...getSubListData("Order", {
                  filter: {
                    send: true,
                  },
                }),
              },
            ],
          },
        ],
        renderItem: ({ item }) => {
          return (
            <Flex w="100%" py={2.5}>
              <ListQuery {...item} gap={0} limit={5} />
            </Flex>
          );
        },
      }}
    />
  );
}

export default Screen;

import * as React from "react";
import { withThemeProps, Pressable, transformColor } from "../../style";

import Flex from "../Flex";
import Animate from "../Animate";
import { isNumber } from "../../util";
import { useUpdateEffect } from "../../hooks";

interface Props {
  theme: object;
  value: boolean;
  onChange: void;
  size?: number;
  roundness?: number;
  gap?: number;
  trackColor?: string;
  activeTrackColor?: string;
  handleShow?: number;
  renderHandleContent: React.ReactNode;
  disabled?: boolean;
  [key: string]: any;
}

const Switch = ({
  theme,
  value,
  onChange,
  size = 35,
  roundness,
  gap = 5,
  trackColor = "primary:setAlpha:0.1",
  circleColor = "#FFF",
  activeTrackColor = "primary",
  handleShow,
  renderHandleContent,
  disabled,
  renderRight = null,
  ...rest
}: Props) => {
  const TRACK_WIDTH = size * 2 - gap;
  const circleSize = size - gap * 2;

  const changeValue = (v: boolean) => {
    if (onChange) onChange(v);
  };

  useUpdateEffect(() => {
    if (theme.onFeedback) theme.onFeedback("selection");
  }, [value]);

  return (
    <Flex row>
      <Pressable
        position="relative"
        activeOpacity={0.8}
        height={size}
        borderRadius={isNumber(roundness) ? roundness : size}
        onPress={() => {
          changeValue(!value);
        }}
        webStyle={{
          cursor: "pointer",
        }}
        {...rest}
        w={TRACK_WIDTH}
      >
        <Flex
          bg={trackColor}
          borderRadius={circleSize}
          p={gap}
          height="100%"
          relative
        >
          <Animate
            bg={activeTrackColor}
            borderRadius={circleSize}
            to={{ opacity: value === true ? 1 : 0 }}
            absoluteFill
          />
          <Animate to={{ x: value === true ? circleSize + gap : 1 }}>
            <Flex
              shadow={handleShow || theme.globals.shadow}
              width={circleSize}
              height={circleSize}
              borderRadius={isNumber(roundness) ? roundness - gap / 2 : size}
              borderWidth={1}
              borderColor={`text:setAlpha:0.05`}
              bg={circleColor}
            >
              {renderHandleContent ? renderHandleContent({ value }) : null}
            </Flex>
          </Animate>
        </Flex>
      </Pressable>
      {renderRight}
    </Flex>
  );
};

export default withThemeProps(Switch, "Switch");

import * as React from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import NetInfo from "@react-native-community/netinfo";
import {
  useQuery,
  useMutation,
  useInfiniteQuery,
  onlineManager,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { GraphQLClient, gql } from "graphql-request";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { createAsyncStoragePersistor } from "react-query/createAsyncStoragePersistor-experimental";

import getSchemaByType from "./clouddoku/lib/schemas";
import { showAlert } from "unikit";

const endpoint = __DEV__
  ? "http://192.168.2.122:4000/graphql"
  : "https://staging.clouddoku.de/graphql";

const client = new GraphQLClient(endpoint);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const asyncStoragePersistor = createAsyncStoragePersistor({
  storage: AsyncStorage,
});

persistQueryClient({
  queryClient,
  persistor: asyncStoragePersistor,
  maxAge: Infinity,
});

onlineManager.setEventListener((setOnline) => {
  return NetInfo.addEventListener((state) => {
    setOnline(state.isConnected);
  });
});

export const Provider = ({ children }) => {
  const [ready, setReady] = React.useState(false);
  React.useEffect(() => {
    const init = async () => {
      const token = await AsyncStorage.getItem("token");
      console.log("Provider", { token });
      if (token) {
        client.setHeader("authorization", token);
      }
      setReady(true);
    };
    init();
  }, []);
  if (!ready) return null;
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

const USER_QUERY = gql`
  query currentUser {
    currentUser {
      ${getSchemaByType("User").typeDef}
      warehouseMaterials
      image {
        _id
        url
        name
      }
      logo {
        _id
        url
        name
      }
      team {
        _id
        emails
        firstName
        lastName
        color
        image {
          _id
          url
          name
        }
      }
    }
  }
`;

export const USERS_QUERY = gql`
  query users($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    users(filter: $filter, sort: $sort, page: $page, limit: $limit) {
        data {
          ${getSchemaByType("User").typeDef}
          team(filter: "employee") {
            _id
            emails
            banned
            roles
          }
        }
        count
      
    }
  }
`;

export const PATIENTS_QUERY = gql`
  query patients(
    $filter: Object
    $sort: Object
    $page: Int
    $limit: Int
    $withDeleted: Boolean
  ) {
    patients(
      filter: $filter
      sort: $sort
      page: $page
      limit: $limit
      withDeleted: $withDeleted
    ) {
      data {
        _id
        firstName
        lastName
        zipcode
        residence
        address
        archived
        updatedAt
        myPatient
        users {
          _id
          firstName
          lastName
          userPictureFileId
          color
          image {
            _id
            url
            name
          }
        }
      }
      count
    }
  }
`;

export const PATIENT_QUERY = gql`
  query patient($filter: Object, $sort: Object) {
    patient(filter: $filter, sort: $sort) {
      ${getSchemaByType("Patient").typeDef}
      anamnese {
        ${getSchemaByType("Anamnese").typeDef}
      }
    }
  }
`;

export const THERAPY_QUERY = gql`
  query therapy($filter: Object, $sort: Object) {
    therapy(filter: $filter, sort: $sort) {
      ${getSchemaByType("Therapy").typeDef}
      anamnesen {
        ${getSchemaByType("Anamnese").typeDef}
      }
      woundImages {
        ${getSchemaByType("WoundImage").typeDef}
      }
    }
  }
`;

export const MATERIALS_QUERY = gql`
  query materials($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    materials(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted)
       {
        data {
          ${getSchemaByType("Material").typeDef}
        }
        count
    }
  }
`;

export const CONTACTS_QUERY = gql`
  query contacts($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    contacts(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted)
      {
        data {
          ${getSchemaByType("Contact").typeDef}
        }
        count
    }
  }
`;

export const THERAPIES_QUERY = gql`
  query therapys($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    therapys(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted) {
      data {
        ${getSchemaByType("Therapy").typeDef}
      }
      count
    }
  }
`;

export const SHARES_QUERY = gql`
  query shares($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    shares(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted) 
      {
        data {
          ${getSchemaByType("Share").typeDef}
          user {
            _id
            firstName
            lastName
            company
          }
        }
        count
    }
  }
`;

export const EVIDENCES_QUERY = gql`
  query evidences($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    evidences(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted)  {
      data {
      ${getSchemaByType("Evidence").typeDef}
      patient {
        firstName
        lastName
        contactIDs
        doctor {
          name
        }
      }
      user {
        _id
        firstName
        lastName
        userPictureFileId
        color
        image {
          _id
          url
          name
        }
      }
      }
      count
    }
  }
`;

export const ANAMNESES_QUERY = gql`
  query anamneses($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    anamneses(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted)  {
      data {
        ${getSchemaByType("Anamnese").typeDef}
      }
      count
    }
  }
`;

export const WOUNDIMAGES_QUERY = gql`
  query woundimages($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    woundimages(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted) {
      data {
        ${getSchemaByType("WoundImage").typeDef}
        image {
          _id
          url
          name
          key
        }
      }
      count 
    }
  }
`;

export const DELIVERYNOTES_QUERY = gql`
  query deliverynotes($filter: Object, $sort: Object, $page: Int, $limit: Int, $withDeleted: Boolean) {
    deliverynotes(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted) {
      data {
        ${getSchemaByType("DeliveryNote").typeDef}
        patient {
          _id
          firstName
          lastName
          contactIDs
          doctor {
            name
          }
        }
        user {
          _id
          firstName
          lastName
          userPictureFileId
          color
          image {
            _id
            url
            name
          }
        }
      }
      count
    }
  }
`;

export const MATERIALSGROUP_QUERY = gql`
  query materialgroups(
    $filter: Object
    $sort: Object
    $page: Int
    $limit: Int
    $withDeleted: Boolean
  ) {
    materialgroups(filter: $filter, sort: $sort, page: $page, limit: $limit, withDeleted: $withDeleted) {
        data {
          ${getSchemaByType("MaterialGroup").typeDef}
        }
        count
    }
  }
`;

export const APPOINTMENTS_QUERY = gql`
  query appointments($filter: Object, $sort: Object, $limit: Int, $withDeleted: Boolean) {
    appointments(filter: $filter, sort: $sort, limit: $limit, withDeleted: $withDeleted) {
      data {
        ${getSchemaByType("Appointment").typeDef}
      }
      count
    }
  }
`;

export const DOCUMENTS_QUERY = gql`
  query documents($filter: Object, $sort: Object, $limit: Int, $withDeleted: Boolean) {
    documents(filter: $filter, sort: $sort, limit: $limit, withDeleted: $withDeleted) {
      data {
        ${getSchemaByType("Document").typeDef}
      }
      count
    }
  }
`;

export const ADVICES_QUERY = gql`
  query advices($filter: Object, $sort: Object, $limit: Int, $withDeleted: Boolean) {
    advices(filter: $filter, sort: $sort, limit: $limit, withDeleted: $withDeleted) {
      data {
        ${getSchemaByType("Advice").typeDef}
      }
      count
    }
  }
`;

export const ORDERS_QUERY = gql`
  query orders($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    orders(filter: $filter, sort: $sort, page: $page, limit: $limit) {
      data {
        ${getSchemaByType("Order").typeDef}
        contact {
          name
          discount
        }
      }
      count
      }
  }
`;

export const WOUNDSUMMARYS_QUERY = gql`
query woundsummarys($filter: Object, $sort: Object, $page: Int, $limit: Int) {
  woundsummarys(filter: $filter, sort: $sort, page: $page, limit: $limit) {
    data {
      ${getSchemaByType("WoundSummary").typeDef}
    }
    count
  }
}
`;

export const DOCTORSLETTERS_QUERY = gql`
query doctorsletters($filter: Object, $sort: Object, $page: Int, $limit: Int) {
  doctorsletters(filter: $filter, sort: $sort, page: $page, limit: $limit) {
    data {
      ${getSchemaByType("DoctorsLetter").typeDef}
    }
    count
  }
}
`;

export const ACTIONS_QUERY = gql`
  query actions($filter: Object, $sort: Object, $page: Int, $limit: Int) {
    actions(filter: $filter, sort: $sort, page: $page, limit: $limit) {
      data {
        ${getSchemaByType("Action").typeDef}
        patient {
          firstName
          lastName
        }
        doc
        user {
          _id
          firstName
          lastName
          color
          image {
            _id
            url
            name
          }
        }
      }
      count
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($base64: String!) {
    uploadFile(base64: $base64)
  }
`;

export const DELETE_FILE = gql`
  mutation deleteFile($_id: String!) {
    deleteFile(_id: $_id)
  }
`;

export const CALL_METEOR = gql`
  mutation callMeteor($name: String!, $config: Object!) {
    callMeteor(name: $name, config: $config)
  }
`;

export const QUERIES = {
  CurrentUser: { query: USER_QUERY, name: "currentUser" },
  User: { query: USERS_QUERY, name: "users" },
  Patient: { query: PATIENTS_QUERY, name: "patients" },
  Contact: { query: CONTACTS_QUERY, name: "contacts" },
  Material: { query: MATERIALS_QUERY, name: "materials" },
  Therapy: { query: THERAPIES_QUERY, name: "therapys" },
  Share: { query: SHARES_QUERY, name: "shares" },
  Evidence: { query: EVIDENCES_QUERY, name: "evidences" },
  Anamnese: { query: ANAMNESES_QUERY, name: "anamneses" },
  WoundImage: { query: WOUNDIMAGES_QUERY, name: "woundimages" },
  DeliveryNote: { query: DELIVERYNOTES_QUERY, name: "deliverynotes" },
  MaterialGroup: { query: MATERIALSGROUP_QUERY, name: "materialgroups" },
  Appointment: { query: APPOINTMENTS_QUERY, name: "appointments" },
  DoctorsLetter: { query: DOCTORSLETTERS_QUERY, name: "doctorsletters" },
  Advice: { query: ADVICES_QUERY, name: "advices" },
  Order: { query: ORDERS_QUERY, name: "orders" },
  WoundSummary: { query: WOUNDSUMMARYS_QUERY, name: "woundsummarys" },
  Action: { query: ACTIONS_QUERY, name: "actions" },
  Document: { query: DOCUMENTS_QUERY, name: "documents" },
};

export const setLoginToken = ({ token }) => {
  console.log("setToken", { token });
  client.setHeader("authorization", token);

  queryClient.invalidateQueries("currentUser");
  AsyncStorage.setItem("token", token);
};

export const logout = () => {
  AsyncStorage.removeItem("token");
  queryClient.invalidateQueries();
};

export function useSetData({ typename, onSuccess, navigation }) {
  const query = QUERIES[typename];
  var simple = getSchemaByType(typename);
  return useMutation(
    async ({ id, doc, mode }) => {
      const data = await client.request(
        gql`
        mutation handleDoc($_id: ID, $doc: Object, $type: String!, $mode: String!) {
          handleDoc(_id: $_id, doc: $doc, type: $type, mode: $mode) {
            ... on ${typename} {
              ${simple.typeDef}
            }
          }
        }
      `,
        {
          _id: id,
          doc: doc,
          type: typename,
          mode: mode,
        }
      );
      return data?.handleDoc;
    },
    {
      onSuccess: (data, variables) => {
        const successMessages = {
          Share: "erfolgreich gesendet 😊",
        };
        showAlert({
          type: "success",
          message: successMessages?.[typename] || "Gespeichert 😊",
          timeout: 2000,
        });
        if (variables?.mode === "update") {
          alert(`setQueryData, ${query.name}`);
          queryClient.setQueryData([query.name, { _id: variables?.id }], data);
        }
        if (onSuccess) {
          onSuccess({ item: variables.doc, navigation });
        }
      },
      //   onMutate: async (data, variables) => {
      //     await queryClient.cancelQueries(query.name);

      //     const previousValue = queryClient.getQueryData(query.name);

      //     queryClient.setQueryData(query.name, (old) => ({
      //       ...old,
      //       data: [...old.data, data],
      //     }));

      //     return previousValue;
      //   },
    }
  );
}

export function useData({ typename, variables = {}, options = {} }) {
  const query = QUERIES[typename];

  const queryFunc = options?.list ? useInfiniteQuery : useQuery;

  return queryFunc(
    [query.name, variables],
    async ({ pageParam }) => {
      console.log("useData", { typename, variables, options, pageParam });
      const data = await client.request(query.query, {
        ...variables,
        page: pageParam,
      });
      return data[query.name];
    },
    { retry: 0, getNextPageParam: (lastPage) => lastPage.count, ...options }
  );
}

import React, { useState } from "react";
import ReactQuill from "react-quill";
import { useTheme, Flex } from "unikit";
import "react-quill/dist/quill.bubble.css";

import { Page, renderAction } from "components";

export default function Screen({ navigation, route }) {
  const [value, setValue] = useState(route?.params?.value);

  return (
    <Page
      title="Editor"
      goBack={navigation?.goBack}
      modal
      rightAction={renderAction({
        icon: "check",
        onPress: () => {
          route.params.onSave(value, navigation.goBack);
        },
      })}
    >
      <Flex flex={1} bg="#FFF">
        <ReactQuill
          theme="bubble"
          value={value}
          onChange={(html) => setValue(html)}
          modules={{
            toolbar: [
              ["bold", "italic", "underline"],
              [{ header: 1 }, { header: 2 }],
            ],
          }}
        />
      </Flex>
    </Page>
  );
}

import SimpleSchema, { minWidth } from "../simple";

const schema = {
  isDraft: {
    type: Boolean,
    label: "isDraft",
    listed: false,
    defaultValue: true,
  },
  name: {
    type: String,
    label: "Name",
    group: "undefined",
    listedPDF: false,
  },
  countUp: {
    type: Boolean,
    label: "Dokument hochzählen?",
    defaultValue: true,
    group: "undefined",
    input: "switch",
    optional: true,
    visible: ({ doc }) => doc.isDraft,
    listedPDF: false,
  },
  schema: {
    type: Array,
    label: "Eingabefelder",
    optional: true,
    input: "Schema",
    group: "undefined",
    defaultValue: {},
    inputProps: ({ doc }) => ({
      hideLabel: true,
    }),
  },
  "schema.$": {
    type: Object,
    listed: false,
    blackbox: true,
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  patientId: {
    type: String,
    optional: true,
    label: "PatientId",
    listed: false,
    optional: true,
  },
  therapyId: {
    type: String,
    optional: true,
    label: "TherapyId",
    listed: false,
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  OwnDoc = new Mongo.Collection("owndoc");
  OwnDoc.schema = simpleSchema;
  OwnDoc.attachSchema(simpleSchema);
  OwnDoc._ensureIndex({ patientId: 1 });
  OwnDoc._ensureIndex({ companyId: 1 });
}

export { simpleSchema as OwnDocSchema };

export default schema;

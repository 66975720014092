import * as React from "react";
import { Tabs, Flex, useTheme } from "unikit";
import { Page, ListQuery, ListItem } from "components";
import dayjs from "dayjs";

import { useAppContext } from "../../context";

function Screen({ navigation }) {
  const theme = useTheme();
  const [tab, setTab] = React.useState("withcompany");
  const { user, inRole, callMeteor, callMeteorState } = useAppContext();

  const filterByTab = {
    withcompany: { shareCompanyId: user.companyId },
    fromcompany: { companyId: user.companyId },
  };

  return (
    <Page
      title="Geteilte Patienten"
      leftAction={{
        icon: "search",
        onPress: () =>
          navigation.navigate("Search", {
            typename: "Share",
            filter: { shareCompanyId: user.companyId },
          }),
      }}
      navbarProps={
        inRole(["client", "employee"])
          ? {
              footerComponent: (
                <Flex p={5} w="100%">
                  <Tabs
                    size={35}
                    value={tab}
                    options={[
                      {
                        value: "withcompany",
                        label: `Mit ${user.company} geteilte Patienten`,
                      },
                      {
                        value: "fromcompany",
                        label: `Von ${user.company} geteilte Patienten`,
                      },
                    ]}
                    onChange={(value) => setTab(value)}
                  />
                </Flex>
              ),
            }
          : undefined
      }
      useFlatList={true}
      scrollComponent={ListQuery}
      scrollProps={{
        renderItem: ({ item }) => (
          <ListItem
            title={`${item.documents?.length} Dokumente von ${item?.patientName}`}
            desc={`Geteilt am ${dayjs(item.createdAt).format(
              "DD.MM.YYYY"
            )} von ${item?.user?.lastName}, ${item?.user?.firstName} (${
              item?.user?.company
            }) mit ${item?.shareUserEmail} ${
              item.shareEnd
                ? `- Geteilt bis ${dayjs(item.shareEnd).format("DD.MM.YYYY")}`
                : ""
            }`}
            actions={
              tab === "fromcompany"
                ? [
                    ...(item.seenByUser
                      ? [
                          {
                            icon: "eye-check",
                            bg: "transparent",
                            color: "success",
                          },
                        ]
                      : []),
                    ...(item.emailSend
                      ? [
                          {
                            icon: "send",
                            bg: "transparent",
                            color: "success",
                          },
                        ]
                      : []),
                    {
                      icon: "trash",
                      bg: "error:setAlpha:0.1",
                      color: "error",
                      loading: callMeteorState?.loading,
                      onPress: () => {
                        callMeteor({
                          variables: {
                            name: "deleteShare",
                            config: { shareId: item._id },
                          },
                          refetchQueries: ["shares"],
                        })
                          .then((result) => {
                            console.log({ result });
                            theme.alert({
                              type: "success",
                              message: "Dokumente werden nicht mehr geteilt",
                            });
                          })
                          .catch((e) => {
                            theme.alert({
                              type: "error",
                              message: e.message,
                            });
                          });
                      },
                    },
                  ]
                : undefined
            }
            actionType="inline"
            icons={[{ name: "share" }]}
            onPress={() =>
              navigation.navigate("ShareProfile", {
                ...item,
              })
            }
          />
        ),
        typename: "Share",
        filter: filterByTab[tab],
        sort: { createdAt: -1 },
        groupBy: "createdAt",
        groupByFormat: ({ key, value, item }) =>
          `${dayjs(value).format("MMM YYYY")} `,
      }}
    />
  );
}

export default Screen;

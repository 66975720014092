import React, { useState, useEffect } from "react";
import { StyleSheet, Text } from "react-native";
import { Flex, useTheme, isAndroid, Button, Icon } from "unikit";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { BarCodeScanner } from "expo-barcode-scanner";

import Form from "../form";
import { useHandleDoc } from "api";
import { confirm } from "../dialogs";

const SEARCH_MUTATION = gql`
  mutation getMaterial($ean: String!) {
    getMaterial(ean: $ean)
  }
`;

const BarCode = ({ value, onChange, doc, navigation }) => {
  const theme = useTheme();
  const { handleDoc } = useHandleDoc();
  const [item, setItem] = useState(undefined);
  const [scanned, setScanned] = useState(false);
  const [hasPermission, setHasPermission] = useState(null);
  const [getMaterial] = useMutation(SEARCH_MUTATION);
  value = doc.materials || [];

  async function getPermissionsAsync() {
    const { status } = await BarCodeScanner.requestPermissionsAsync();
    setHasPermission(status === "granted");
  }

  useEffect(() => {
    getPermissionsAsync();
  }, []);

  if (hasPermission === null) {
    return <Text>Requesting for camera permission</Text>;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

  const setValueWithCount = (item) => {
    theme.alert({
      bg: "surface",
      message: "Anzahl wählen",
      timeout: false,
      position: "center",
      renderContent: ({ close }) => {
        return (
          <Flex w="100%">
            <Form
              schema={[
                {
                  key: "number",
                  input: "number",
                  label: "Anzahl",
                },
              ]}
              defaultDoc={{ number: 1 }}
              groupBy={null}
              scrollEnabled={false}
              style={{ width: "100%" }}
              spacer={false}
              onSubmit={({ doc }) => {
                const count = doc.number;
                close?.();

                if (count !== null) {
                  const newValue = value || [];
                  const found = newValue.find((v) => v._id === item._id);
                  if (found) {
                    found.count = count;
                  } else {
                    newValue.push(
                      Object.assign({}, item, {
                        count: parseInt(count),
                        id: item._id,
                      })
                    );
                  }

                  onChange(newValue, "materials");
                }
              }}
              footerComponent={
                <Button
                  bg="transparent"
                  color="primary"
                  onPress={close}
                  light
                  rounded
                >
                  Abbrechen
                </Button>
              }
            />
          </Flex>
        );
      },
    });
  };

  const handleBarCodeScanned = ({ type, data }) => {
    setScanned(true);
    //alert(`Bar code with type ${type} and data ${data} has been scanned!`);
    getMaterial({
      variables: {
        ean: data,
      },
    })
      .then((result) => {
        const item = result.data?.getMaterial;
        if (item) {
          setValueWithCount(item);
        } else {
          confirm(
            `Material mit Barcode ${data} nicht gefunden, wollen Sie ein Material verknüpfen?`,
            () => {
              navigation.push("Search", {
                typename: "Material",
                filter: {},
                onSelect: (item, goBack) => {
                  const EANNumber = item.EANNumber
                    ? [...item.EANNumber, data]
                    : [data];
                  handleDoc({
                    id: item._id,
                    doc: { EANNumber },
                    typename: "Material",
                    mode: "update",
                  });
                  setValueWithCount(item);
                  goBack();
                },
              });
            }
          );
        }
      })
      .catch((error) => {
        console.log({ error });
        setScanned(false);
      });
  };
  return (
    <Flex
      relative
      w="100%"
      borderRadius={15}
      overflow="hidden"
      h={isAndroid ? 300 : 150}
    >
      <BarCodeScanner
        onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
        style={StyleSheet.absoluteFill}
      />
      <Flex absolute l="10%" w="80%" h={1} bg="error" b="50%" />
      {scanned ? (
        <Flex center absoluteFill>
          <Button onPress={() => setScanned(false)}>Erneut scannen</Button>
        </Flex>
      ) : null}
    </Flex>
  );
};

export default BarCode;

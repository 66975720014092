import dayjs from "dayjs";
import SimpleSchema from "./simple";

const schema = {
  shareUserEmail: {
    type: String,
    label: "E-Mail des Empfängers",
    group: "Allgemein",
    inputProps: {
      keyboardType: "email-address",
      autoCapitalize: "none",
      autoCompleteType: "email",
    },
  },
  shareUser: {
    type: String,
    label: "Kontakt suchen",
    input: "DocList",
    group: "Allgemein",
    optional: true,
    inputProps: {
      multiple: false,
      typename: "Contact",
      allowSearch: true,
      allowCreate: false,
      allowEdit: false,
      title: (item) => `${item.email}`,
      setDocKey: "shareUserEmail",
      desc: (item) => `${item.name}`,
    },
  },
  shareCompanyId: {
    type: String,
    label: "Share CompanyId",
    listed: false,
    optional: true,
  },
  patientId: {
    type: String,
    label: "patientId",
    listed: false,
  },
  patientName: {
    type: String,
    label: "Patient",
    group: "Allgemein",
  },
  note: {
    type: String,
    label: "Bemerkung",
    optional: true,
    input: "textarea",
    group: "Allgemein",
  },
  shareEnd: {
    type: Date,
    label: "Autmatisch löschen am",
    optional: true,
    input: "date",
    group: "Allgemein",
  },
  documents: {
    type: Array,
    label: "Dokumente",
    blackbox: true,
    group: "Dokumente",
    input: "DocList",
    inputProps: {
      hideLabel: true,
      queryData: false,
      allowSearch: false,
      allowCreate: false,
      allowEdit: false,
      allowRemove: true,
      title: (item) => {
        return `${item.name}`;
      },
      desc: (item) => {
        return `vom ${dayjs(item.surveyDate).format("DD.MM.YYYY")}`;
      },
    },
  },
  "documents.$": {
    type: Object,
    blackbox: true,
  },
  emailSend: {
    type: String,
    label: "emailSend",
    optional: true,
    listed: false,
  },
  seenByUser: {
    type: Boolean,
    label: "seenByUser",
    optional: true,
    listed: false,
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Shares = new Mongo.Collection("shares");
  Shares.schema = simpleSchema;
  Shares.attachSchema(simpleSchema);
  Shares._ensureIndex({ companyId: 1 });
}

export { simpleSchema as ShareSchema };

export default schema;

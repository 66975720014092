import * as React from "react";
import { Flex, useTheme, Button } from "unikit";
import dayjs from "dayjs";

import { Page, List, ListItem, ListQuery } from "components";
import { useHandleDoc } from "api";

import { useAppContext } from "../../context";

function Screen({ route, navigation }) {
  const theme = useTheme();
  const { handleDoc } = useHandleDoc();
  const { callMeteor } = useAppContext();

  const getSubListData = (typename, r) => ({
    typename: typename,
    sort: { surveyDate: -1 },
    name: `${typename.toLowerCase()}s`,
    scrollEnabled: false,
    renderItem: (p) => renderItem(p),
    ...r,
  });

  const renderItem = ({ item }, rest = {}) => {
    const totalCount = item.materials.reduce((acc, material) => {
      return acc + parseInt(material.count);
    }, 0);
    return (
      <ListItem
        title={`${item.name}`}
        desc={`Bei ${item.contact?.name || ""} vom ${dayjs(
          item.surveyDate
        ).format("DD. MMMM YYYY")}`}
        icons={[
          {
            name: rest.icon || "shopping-cart",
            color: item.booked ? "success" : "primary",
            iconLabel: `${item.bookedCount || 0}/${totalCount}`,
          },
          ...(item.emailSend
            ? [
                {
                  name: "send",
                  color: "success",
                  bg: "transparent",
                  color: "success",
                },
              ]
            : []),
        ]}
        onPress={() => {
          navigation.navigate("Form", {
            title: `${item.name} bearbeiten`,
            id: item._id,
            defaultDoc: item,
            mode: "update",
            typename: item.__typename,
            refetchQueries: ["orders"],
            onSuccess: ({ navigation, client }) => {
              navigation?.goBack();
              // client.refetchQueries({
              //   include: ["orders"],
              // });
            },
          });
        }}
        image={
          item.image && !item.canceled ? { uri: item.image.url } : undefined
        }
        canceled={item.canceled}
        actions={[
          ...(item.send && !item.booked
            ? [
                {
                  icon: "refresh-ccw",
                  text: item.bookedCount > 0 ? "Rest buchen" : "Buchen",
                  onPress: () => {
                    navigation.navigate("Change", {
                      title: "Wareneingang",
                      orderId: item._id,
                      defaultDoc: {
                        materials: item.materials.reduce((acc, m) => {
                          const count = m.count - (m.bookedCount || 0);
                          if (count > 0) {
                            acc.push({
                              ...m,
                              count,
                            });
                          }
                          return acc;
                        }, []),
                        warehouseToId: "warehouse",
                      },
                    });
                  },
                },
              ]
            : []),
          {
            icon: "file-text",
            text: "PDF erstellen",
            onPress: () => {
              navigation.navigate("PDF", {
                typename: item.__typename,
                id: item._id,
                options: undefined,
              });
            },
          },
          {
            icon: "check",
            text: `Bestellung abschließen`,
            onPress: () => {
              handleDoc({
                id: item._id,
                doc: {
                  booked: true,
                },
                typename: "Order",
                mode: "update",
                refetchQueries: ["orders"],
              });
            },
          },
          {
            icon: "send",
            text: `Bestellung ${item.send ? "erneut " : ""}per E-Mail senden`,
            onPress: () => {
              callMeteor({
                variables: {
                  name: "sendOrder",
                  config: { orderId: item._id },
                },
                refetchQueries: ["orders"],
              })
                .then(() => {
                  theme.alert({
                    type: "success",
                    message:
                      "Ihre Bestellung wurde erfolgreich an den Lieferanten übermittelt",
                  });
                })
                .catch((e) => {
                  theme.alert({
                    type: "error",
                    message: e.message,
                  });
                });
            },
          },
          {
            icon: "x",
            text: `Bestellung stornieren`,
            onPress: () => {
              handleDoc({
                id: item._id,
                doc: {
                  canceled: true,
                },
                typename: "Order",
                mode: "update",
                refetchQueries: ["orders"],
              });
            },
          },
        ]}
        {...rest}
      />
    );
  };

  return (
    <Page
      goBack={navigation?.goBack}
      title="Wareneingang/Bestellmanagement"
      rightAction={{
        icon: "search",
        onPress: () =>
          navigation.navigate("Search", {
            typename: "Order",
          }),
      }}
      useFlatList={true}
      scrollComponent={List}
      scrollProps={{
        ListHeaderComponent: (
          <Button
            onPress={() => {
              navigation.navigate("Change", {
                title: "Wareneingang",
                defaultDoc: {
                  type: "warehouse_in",
                  warehouseToId: "warehouse",
                },
              });
            }}
            light
            mx={10}
            mt={15}
          >
            Wareneingang ohne Bestellung buchen
          </Button>
        ),
        sections: true,
        data: [
          {
            title: "Wareneingang aus Bestellung",
            actionType: "inline",
            height: 60,
            data: [
              {
                ...getSubListData("Order", {
                  filter: {
                    send: true,
                    booked: { $ne: true },
                    canceled: { $ne: true },
                  },
                }),
              },
            ],
          },
          {
            title: "Lieferschein/Rechnung fehlt oder unvollständig",
            height: 60,
            data: [
              {
                ...getSubListData("Order", {
                  filter: {
                    send: true,
                    canceled: { $ne: true },
                    $or: [
                      { incomplete: true },
                      {
                        deliveryNoteImageIds: null,
                        booked: true,
                        canceled: { $ne: true },
                      },
                      { deliveryNoteImageIds: [], booked: true },
                      { invoiceImageIds: null, booked: true },
                      { invoiceImageIds: [], booked: true },
                    ],
                  },
                }),
              },
            ],
          },
          {
            title: "Abgeschlossene Bestellungen",
            height: 60,
            data: [
              {
                ...getSubListData("Order", {
                  filter: {
                    $or: [
                      {
                        send: true,
                        deliveryNoteImageIds: { $exists: true, $ne: [] },
                        invoiceImageIds: { $exists: true, $ne: [] },
                        incomplete: { $ne: true },
                        booked: true,
                      },
                      { canceled: true },
                    ],
                  },
                }),
              },
            ],
          },
        ],
        renderItem: ({ item }) => {
          return (
            <Flex w="100%" py={2.5}>
              <ListQuery {...item} gap={0} limit={5} />
            </Flex>
          );
        },
      }}
    />
  );
}

export default Screen;

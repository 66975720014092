import * as React from "react";

import Animate from "../Animate";
import Button from "../Button";
import Icon from "../Icon";
import Flex from "../Flex";

import { withThemeProps } from "../../style";
import { useLayout, useUpdateEffect } from "../../hooks";
import { isFunction, isNumber } from "../../util";

const getIndexByValue = (options, value) => {
  if (value === undefined) return 0;
  const findIndex = options.findIndex(
    (option) => option === value || option.value === value
  );
  return findIndex;
};

export function Tabs(
  {
    value,
    onChange,
    options = [],
    vertical,
    size = 55,
    bg = "primary:setAlpha:0.1",
    inactiveColor = "text",
    activeColor = "primary",
    indicatorColor = "surface",
    indicatorOffset,
    tabProps = {},
    indicatorSize,
    indicatorProps = {},
    springConfig = {},
    theme,
    roundness = 10,
    gap = 3,
    ...rest
  },
  ref
) {
  const [active, setActive] = React.useState(
    () => getIndexByValue(options, value) || 0
  );
  const { onLayout, width, height } = useLayout();
  const totalSize = vertical ? height : width;
  const to = Math.round(active * (totalSize / options.length)) + 0.0001;

  useUpdateEffect(() => {
    const newIndex = getIndexByValue(options, value);
    if (newIndex !== active) {
      setActive(newIndex);
    }
  }, [value]);

  return (
    <Flex
      bg={bg}
      p={gap}
      borderRadius={isNumber(roundness) ? roundness : theme.globals.roundness}
      {...rest}
    >
      <Flex h={size} row={!vertical} onLayout={onLayout} relative>
        {totalSize > 0 && (
          <Animate
            bg={indicatorColor}
            absolute
            left={0}
            top={indicatorOffset || 0}
            w={`${100 / options.length}%`}
            pointerEvents="none"
            from={{ x: to }}
            to={{ x: to }}
            zIndex={0}
            h={indicatorSize || size}
            borderRadius={roundness || theme.globals.roundness}
            shadow={1}
            {...indicatorProps}
          />
        )}
        {options.map((item, index) => {
          const isActive = index === active;
          const color = isActive ? activeColor : inactiveColor;
          return (
            <Button
              flex={1}
              bg="transparent"
              color={color}
              key={index}
              size={size}
              renderLeft={
                item.icon ? (
                  <Icon name={item.icon} size={size * 0.5} mr={size * 0.15} />
                ) : null
              }
              onPress={() => {
                setActive(index);
                setTimeout(() => {
                  if (theme.onFeedback) theme.onFeedback("selection");
                  if (onChange)
                    onChange(item.value !== undefined ? item.value : item);
                }, 10);
              }}
              borderRadius={roundness || theme.globals.roundness}
              py={0}
              {...tabProps}
              labelProps={{
                fontSize: size * 0.4,
                ...tabProps.labelProps,
              }}
              zIndex={100}
            >
              {item.label
                ? isFunction(item.label)
                  ? item.label({ index, active: isActive, color })
                  : item.label
                : item}
            </Button>
          );
        })}
      </Flex>
    </Flex>
  );
}

export default withThemeProps(Tabs, "Tabs");

import * as React from "react";
import { Flex } from "unikit";
import { Tooltip } from "../../unikit";

import { ListItem } from "../list";
import { useAppContext } from "../../context";

export default ({
  onChange,
  value,
  children,
  renderRight,
  onPress,
  tooltipProps = {},
  ...rest
}) => {
  const { team } = useAppContext();

  const activeUser = team.find((t) => t._id === value);

  return (
    <Flex w="100%" center {...rest}>
      <Tooltip
        {...tooltipProps}
        useScrollView
        wrapperWidth="100%"
        popover={({ toggleTooltip }) => {
          return (
            <>
              {team.map((member, i) => {
                return (
                  <ListItem
                    w="100%"
                    users={[member]}
                    title={`${member?.firstName} ${member?.lastName}`}
                    onPress={() => {
                      if (onPress) {
                        onPress({ member, toggleTooltip });
                      } else {
                        toggleTooltip?.();
                        onChange(member._id);
                      }
                    }}
                    renderRight={renderRight?.({ item: member })}
                  />
                );
              })}
            </>
          );
        }}
      >
        {children || (
          <ListItem
            w="100%"
            users={activeUser ? [activeUser] : undefined}
            title={
              activeUser
                ? `${activeUser?.firstName} ${activeUser?.lastName}`
                : "Mitarbeiter wählen"
            }
            actions={[
              {
                icon: "chevron-down",
                mr: -5,
                bg: "transparent",
              },
            ]}
            actionType="inline"
          />
        )}
      </Tooltip>
    </Flex>
  );
};

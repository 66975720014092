import * as React from "react";
import { Image } from "react-native";
import { Avatar } from "unikit";

export default ({ size = 60, ...rest }) => {
  return (
    <Avatar
      roundness={size * 0.2}
      size={size}
      source={require(`../assets/images/bg.jpg`)}
      {...rest}
    >
      <Image
        source={require(`../assets/images/logo.png`)}
        style={{
          width: "70%",
          height: "70%",
          resizeMode: "contain",
        }}
      />
    </Avatar>
  );
};

import * as React from "react";

import { useAppContext } from "../context";

let Notifications = null;
try {
  Notifications = require("expo-notifications");
} catch {}

// Replace with your experienceId below with: @your-username/your-project-slug
//
// NOTES:
// 1. You need to publish at least once so the Expo servers are aware of an app with this id
// 2. You need to have the iOS bundleIdentifier set in app.json
// 3. Once you have published, run `expo credentials:manager` and associate a
// push key with it (an existing one if you have used Expo for notifications
// before will work fine, or upload one or let Expo handle creating it)
const experienceId = "@kations/clouddoku";

export async function registerForPushNotificationsAsync() {
  if (Notifications) {
    const { status } = await Notifications.requestPermissionsAsync();
    console.log({ status });

    try {
      const expoPushToken = await Notifications.getExpoPushTokenAsync({
        experienceId,
      });
      return expoPushToken.data;
    } catch (e) {
      console.log({ e });
      return "failed";
    }
  } else {
    return "failed";
  }
}

export default function Push({ onToken }) {
  const { user, callMeteor, refetchUser } = useAppContext();

  React.useEffect(() => {
    async function getToken() {
      const token = await registerForPushNotificationsAsync();
      console.log({ pushToken: token });

      if (token !== "failed") {
        console.log({ token });
        const pushTokens = user.pushTokens ? [...user.pushTokens] : [];
        if (pushTokens.indexOf(token) === -1) {
          callMeteor({
            variables: {
              name: "savePushToken",
              config: {
                pushToken: token,
              },
            },
          }).then(() => {
            refetchUser();
          });
        }
        onToken?.(token);
      }
    }
    if (Notifications) {
      getToken();
    }
  }, []);
  return null;
}

import * as React from "react";
import { BlurView } from "expo-blur";

import { withThemeProps } from "../../style";

const Blur = ({ intensity = 30, tint, children, theme, ...rest }) => {
  return (
    <BlurView intensity={intensity} tint={tint || theme?.mode} {...rest}>
      {children}
    </BlurView>
  );
};

export default withThemeProps(Blur, "Blur");

// @flow
import * as React from "react";
import { Flex, useLayout } from "unikit";

import PdfPreview from "./PdfPreview";

export default function PDF({ source }) {
  const { width, onLayout } = useLayout();
  return (
    <Flex flex={1} alignItems="center">
      <Flex w="95%" maxWidth={900} onLayout={onLayout}>
        <PdfPreview source={source} width={width} />
      </Flex>
    </Flex>
  );
}

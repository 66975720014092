import * as React from "react";
import { Flex, Form as UniForm, Input, useTheme, Tabs } from "unikit";
import dayjs from "dayjs";
import { Page, Form, ScorePassword, List, ListItem } from "components";

import { useAppContext } from "../../context";

export const PasswordForm = ({ _id, onSuccess }) => {
  const { callMeteor } = useAppContext();
  const theme = useTheme();
  return (
    <Flex p={10}>
      <UniForm
        buttonLabel="Passwort ändern"
        onSubmit={(doc, reset) => {
          if (
            !doc?.password ||
            doc.password?.length < 8 ||
            doc.password !== doc.repeatPassword
          ) {
            theme.alert({
              type: "error",
              message:
                doc.password?.length < 8
                  ? "Ihr Passwort muss mindestens 8 Zeichen haben"
                  : "Daten stimmen nicht überein",
            });
          } else {
            callMeteor({
              variables: {
                name: "setUserPassword",
                config: { password: doc.password, _id },
              },
            })
              .then(() => {
                theme.alert({
                  type: "success",
                  message: "Paswort erfolgreich geändert",
                });
                onSuccess?.();
                reset?.();
              })
              .catch((e) => {
                theme.alert({
                  type: "error",
                  message: e.message,
                });
              });
          }
        }}
      >
        {({ doc }) => (
          <>
            <ScorePassword password={doc?.password} />
            <Input
              type="password"
              placeholder="Passwort"
              label="Passwort"
              field="password"
              mt={10}
            />
            <Input
              type="password"
              placeholder="Passwort widerholen"
              label="Passwort"
              field="repeatPassword"
              mt={10}
            />
          </>
        )}
      </UniForm>
    </Flex>
  );
};

function Screen({ navigation }) {
  const theme = useTheme();
  const [tab, setTab] = React.useState("Account");
  const formRef = React.useRef(null);
  const { user, logout, inRole } = useAppContext();

  const activeUser = user?.team?.filter((t) => t.banned !== true).length;

  const pagePropsByTab = {
    Account: {
      useFlatList: true,
      scrollComponent: Form,
      scrollProps: {
        typename: "User",
        id: user?._id,
        ref: formRef,
        mode: "update",
        defaultDoc: user,
        roles: user.roles,
        hiddenFields: ["banned"],
      },
      rightAction: {
        icon: "check",
        onPress: () => {
          formRef?.current?.submit?.();
        },
      },
    },
    Mitarbeiter: {
      scrollComponent: List,
      scrollProps: {
        data: [...(user?.team || []), ...(user?.teamBanned || [])],
        groupBy: "banned",
        groupByFormat: ({ value }) =>
          value === true
            ? "Inaktiv"
            : `Aktiv: ${activeUser}/${user.employeeCount}`,
        renderItem: ({ item }) => {
          return (
            <ListItem
              title={`${item.lastName}, ${item.firstName}`}
              desc={`Bearbeitet am ${dayjs(item.updatedAt).format(
                "DD. MMMM YYYY"
              )}`}
              users={[item]}
              onPress={() => {
                navigation.navigate("Form", {
                  title: "Mitarbeiter bearbeiten",
                  defaultDoc: item,
                  id: item._id,
                  typename: "User",
                  mode: "update",
                  customMode: "updateEmployee",
                  roles: ["employee"],
                });
              }}
            />
          );
        },
      },

      rightAction: {
        icon: "plus",
        onPress: () => {
          if (activeUser >= user.employeeCount) {
            theme.alert({
              type: "error",
              message: "Mitarbeiter limit erreicht",
            });
          } else {
            navigation.navigate("Form", {
              title: "Neuer Mitarbeiter",
              typename: "User",
              mode: "insert",
              customMode: "insertEmployee",
              roles: ["employee"],
            });
          }
        },
      },
    },
    Password: {
      rightAction: undefined,
    },
  };

  const pageProps = pagePropsByTab[tab];

  return (
    <Flex>
      <Page
        title={`${user?.firstName} ${user?.lastName}`}
        leftAction={{
          icon: "log-out",
          onPress: () => logout(),
        }}
        navbarProps={{
          footerComponent: (
            <Flex px={10} p={5} w="100%">
              <Tabs
                size={35}
                value={tab}
                options={[
                  {
                    value: "Account",
                    label: "Account",
                    icon: "user",
                  },
                  ...(inRole(["client"])
                    ? [
                        {
                          value: "Mitarbeiter",
                          label: "Mitarbeiter",
                          icon: "users",
                        },
                      ]
                    : []),
                  {
                    value: "Password",
                    label: "Passwort",
                    icon: "lock",
                  },
                ]}
                onChange={(value) => setTab(value)}
              />
            </Flex>
          ),
        }}
        {...pageProps}
      >
        <PasswordForm />
      </Page>
    </Flex>
  );
}

export default Screen;

import SimpleSchema from "../simple";

const schema = {
  surveyDate: {
    type: Date,
    label: "Orderdatum",
    defaultValue: function () {
      return new Date();
    },
    group: "Allgemein",
    input: "date",
    listedPDF: false,
  },
  name: {
    type: String,
    label: "Ordername",
    group: "Allgemein",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        const field = this.siblingField("companyId");
        const user = Meteor.users.findOne({ companyId: field.value });
        const warehouseStartNumber = user.warehouseStartNumber || 0;
        var count =
          warehouseStartNumber +
          Orders.find({
            companyId: field.value,
          }).count() +
          1;
        return `Bestellung Nr. ${count}`;
      }
    },
    optional: true,
    listed: false,
    listedPDF: false,
  },
  contactId: {
    type: String,
    label: "Lieferant",
    input: "DocList",
    optional: false,
    group: "Lieferant",
    inputProps: ({ doc }) => ({
      hideLabel: true,
      typename: "Contact",
      allowSearch: doc.send ? false : true,
      allowCreate: doc.send ? false : true,
      allowEdit: doc.send ? false : true,
      allowRemove: false,
      multiple: false,
      getFilter: ({ doc }) => ({ typ: "Lieferant" }),
      title: (item) => {
        return `${item.name}, ${item.typ}`;
      },
      desc: (item) => {
        return `Telefon: ${item.phone}, Fax: ${item.fax} - ${item.address}, ${item.zipCode} ${item.residence}`;
      },
    }),
    listedPDF: false,
  },
  note: {
    type: String,
    label: "Bemerkung an Lieferant",
    optional: true,
    input: "textarea",
    group: "Lieferant",
    listedPDF: false,
  },
  materials: {
    type: Array,
    optional: true,
    blackbox: true,
    input: "DocList",
    label: "Materialien",
    group: "Materialien",
    defaultValue: function () {
      return [];
    },
    inputProps: ({ doc, navigation }) => ({
      typename: "Material",
      disabled: doc.send,
      hideLabel: true,
      showProgress: true,
      allowSearch: doc.send ? false : true,
      previewFilter: {
        inStock: { $ne: null, $lte: 10 },
        contactId: { $ne: null },
      },
      queryData: false,
      prompt: {
        title: "Wähle eine Anzahl",
        schema: [
          { key: "count", input: "number", label: "Anzahl", defaultValue: 1 },
        ],
      },
      title: (item) => {
        return `${item.count}x ${item.producer}, ${item.name}`;
      },
      desc: (item) => {
        return `Größe: ${item.size}, Verpackungseinheit: ${item.packagingUnit}`;
      },
      progress: (item) => ({
        formatValue: (v) => `${v}/${item.count}`,
        value: item.bookedCount,
        max: item.count,
        progressColor: item.bookedCount === item.count ? "success" : "warning",
      }),
      footerActions: [
        ...(doc.send && !doc.booked
          ? [
              {
                icon: "refreshCCW",
                label: doc.bookedCount > 0 ? "Rest buchen" : "Alle Buchen",
                onPress: () => {
                  navigation.navigate("Change", {
                    title: "Wareneingang",
                    orderId: doc._id,
                    onSuccess: (navigation) => {
                      navigation.navigate("Receipt");
                    },
                    defaultDoc: {
                      materials: doc.materials.reduce((acc, m) => {
                        const count = m.count - (m.bookedCount || 0);
                        if (count > 0) {
                          acc.push({
                            ...m,
                            count,
                          });
                        }
                        return acc;
                      }, []),
                      warehouseToId: "warehouse",
                    },
                  });
                },
              },
            ]
          : []),
      ],
      tableFooter: [
        {
          value: `${doc.materials
            .reduce((acc, item) => {
              if (item && item.purchasingPrice) {
                const discount =
                  item?.discount === 0 || item?.discount > 0
                    ? item?.discount
                    : doc.contact?.discount || 0;
                const discountMulti = discount / 100;
                const total = item.count * item.purchasingPrice;
                const totalDiscounted = total - total * discountMulti;
                acc = acc + totalDiscounted;
              }
              console.log({ doc });

              return acc;
            }, 0)
            .toFixed(2)}€`,
          label: "Gesamtbetrag (Netto)",
        },
      ],
      table: {
        schema: [
          {
            label: "Menge",
            width: 60,
            formatValue: ({ value, item }) => `${value}x`,
            key: "count",
          },
          {
            label: "PZN\nBestellnummer",
            width: 100,
            key: "materialNumber",
            formatValue: ({ value, item }) =>
              `${value}\n${item.orderNumber || ""}`,
          },
          {
            label: "Hersteller\nName",
            width: 160,
            key: "producer",
            formatValue: ({ value, item }) => `${value}\n${item.name || ""}`,
          },
          {
            label: "Größe\nVerpackungseinheit",
            width: "100",
            formatValue: ({ value, item }) =>
              `${value}\n${item.packagingUnit || ""}`,
            key: "size",
          },
          {
            label: "Preis/Rabatt/Gesamt",
            width: 100,
            formatValue: ({ value, item }) => {
              console.log({
                item: item.discount,
                contact: doc.contact?.discount,
              });
              const discount =
                item?.discount === 0 || item?.discount > 0
                  ? item.discount
                  : doc.contact?.discount || 0;
              console.log({ discount });
              const discountMulti = discount / 100;
              const total = item.count * item.purchasingPrice;
              const totalDiscounted = total - total * discountMulti;
              console.log({ total, discountMulti });
              return `${value}€\n${discount}%\n${totalDiscounted.toFixed(2)}€`;
            },
            key: "purchasingPrice",
          },
        ],
      },
      skipSearch: doc.contactId ? false : true,
      getFilter: ({ doc }) =>
        doc.contactId ? { supplierIds: { $in: [doc.contactId] } } : {},
      actions: ({ doc, item, navigation, showPrompt }) => [
        {
          icon: "refresh-ccw",
          text: "Anzahl ändern",
          onPress: () => {
            showPrompt({
              item,
              title: "Wähle eine Anzahl",
              force: true,
              schema: [
                {
                  key: "count",
                  input: "number",
                  label: "Anzahl",
                  defaultValue: item.count || 1,
                },
              ],
            });
          },
        },
        ...(doc._id && doc.send && item.count - (item.bookedCount || 0) > 0
          ? [
              {
                icon: "refresh-ccw",
                text: "Buchen",
                onPress: () => {
                  navigation.navigate("Change", {
                    title: "Wareneingang",
                    orderId: doc._id,
                    onSuccess: (navigation) => {
                      navigation.navigate("Receipt");
                    },
                    defaultDoc: {
                      materials: [
                        {
                          ...item,
                          count: Math.abs(item.count - (item.bookedCount || 0)),
                          bookedCount: null,
                        },
                      ],
                      warehouseToId: "warehouse",
                    },
                  });
                },
              },
            ]
          : []),
      ],
    }),
  },
  "materials.$": {
    type: Object,
    listed: false,
    blackbox: true,
  },
  noteBooked: {
    type: String,
    label: "Bemerkung Wareneingang",
    optional: true,
    listedPDF: false,
    group: "Weiteres",
    input: "textarea",
  },
  send: {
    type: Boolean,
    label: "Gesendet",
    optional: true,
    input: "switch",
    group: "Weiteres",
    defaultValue: function () {
      return false;
    },
    listedPDF: false,
  },
  incomplete: {
    type: Boolean,
    label: "Unvollständig",
    optional: true,
    group: "Weiteres",
    listed: "update",
    listedPDF: false,
    input: "switch",
  },
  deliveryNoteImageIds: {
    type: Array,
    label: "Lieferschein",
    input: "File",
    optional: true,
    defaultValue: function () {
      return [];
    },
    multiple: true,
    group: "Weiteres",
    listed: "update",
    listedPDF: false,
  },
  "deliveryNoteImageIds.$": {
    type: String,
  },
  invoiceImageIds: {
    type: Array,
    label: "Rechnung",
    input: "File",
    optional: true,
    defaultValue: function () {
      return [];
    },
    multiple: true,
    group: "Weiteres",
    listed: "update",
    listedPDF: false,
  },
  "invoiceImageIds.$": {
    type: String,
  },
  otherIds: {
    type: Array,
    label: "Sonsitge Dokumente",
    input: "File",
    optional: true,
    defaultValue: function () {
      return [];
    },
    multiple: true,
    group: "Weiteres",
    listed: "update",
    listedPDF: false,
  },
  "otherIds.$": {
    type: String,
  },
  actions: {
    type: String,
    listed: "update",
    label: "Aktionen zur Order",
    optional: true,
    input: "Actions",
    group: "Weiteres",
    inputProps: ({ doc }) => ({
      filter: {
        orderId: doc._id,
      },
    }),
  },
  createdAt: {
    type: Date,
    label: "Date of creation",
    autoValue: function () {
      if (typeof Meteor !== "undefined" && this.isInsert) {
        return new Date();
      } else if (typeof Meteor !== "undefined" && this.isUpsert) {
        return new Date();
      } else if (typeof Meteor === "undefined") {
        return new Date();
      }
    },
    listed: false,
  },
  updatedAt: {
    type: Date,
    label: "Date of change",
    autoValue: function () {
      return new Date();
    },
    optional: true,
    listed: false,
  },
  booked: {
    type: Boolean,
    label: "Alles gebucht?",
    optional: true,
    listed: false,
  },
  bookedCount: {
    type: Number,
    label: "Gebuchte Materialien",
    optional: true,
    listed: false,
  },
  emailSend: {
    type: String,
    label: "emailSend",
    optional: true,
    listed: false,
  },
  createdBy: {
    type: String,
    listed: false,
  },
  companyId: {
    type: String,
    listed: false,
  },
  canceled: {
    type: Boolean,
    label: "Canceled",
    optional: true,
    listed: false,
  },
  deleted: {
    type: Boolean,
    label: "deleted",
    optional: true,
    listed: false,
  },
};

const simpleSchema = new SimpleSchema(schema);
if (typeof Mongo !== "undefined") {
  Orders = new Mongo.Collection("orders");
  Orders.schema = simpleSchema;
  Orders.attachSchema(simpleSchema);
  Orders._ensureIndex({ companyId: 1 });
}

export { simpleSchema as OrderSchema };

export default schema;

import * as React from "react";
import { ScrollView, Image } from "react-native";
import {
  CommonActions,
  DrawerActions,
  useNavigation,
} from "@react-navigation/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import Svg, { Path } from "react-native-svg";

import Icon from "../Icon";
import Flex from "../Flex";
import Text from "../Text";
import Avatar from "../Avatar";
import ThemeSelect from "../ThemeSelect";

import { withThemeProps, useTheme, Touchable } from "../../style";

export const AppIcon = ({ size = 60, image, ...rest }) => {
  return (
    <Avatar roundness={size * 0.2} size={size} {...rest}>
      {image ? (
        <Image
          source={image}
          style={{
            width: "70%",
            height: "70%",
            resizeMode: "contain",
          }}
        />
      ) : null}
    </Avatar>
  );
};

export const BurgerMenu = ({ onPress, inline = false }) => {
  const navigation = useNavigation();
  const theme = useTheme();
  const toggleDrawer = () => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  };
  return (
    <Touchable
      onPress={() => {
        if (onPress) {
          onPress?.({ toggleDrawer });
        } else {
          toggleDrawer();
        }
      }}
      activeOpacity={1}
      zIndex={9999}
      {...(inline ? {} : { absolute: true, r: -44, b: 50 })}
    >
      {inline ? null : (
        <Svg
          width="50px"
          height="150px"
          style={{ marginLeft: -10 }}
          viewBox="0 0 40 130"
        >
          <Path
            d="M33,111 L29.809072,110.999323 L29.8053635,110.389144 C29.6691172,98.4347164 25.7974401,89.1797801 18.1903321,82.6243354 L18.1903321,82.6243354 L17.7097375,82.2098296 C6.70960133,72.7135878 0.349159584,66.5687824 0.0139566164,55.9993631 L0.001,55.9996768 L0,55.8902203 L0.003,55.5 L0,55.1097797 L0.001,55.0003232 L0.0139566164,55.0006369 C0.349159584,44.4312176 6.70960133,38.2864122 17.7097375,28.7901704 L18.1903321,28.3756646 C25.7974401,21.8202199 29.6691172,12.5652836 29.8053635,0.610855576 L29.809072,0.000676826217 L33,0 L33,111 Z"
            id="Combined-Shape"
            fill={theme.colors.primary}
            transform="translate(16.500000, 55.500000) scale(-1, 1) translate(-16.500000, -55.500000) "
          ></Path>
        </Svg>
      )}
      <Flex
        w={40}
        h={40}
        {...(inline ? {} : { absolute: true, l: -10, b: 65 })}
        center
      >
        <Icon name={"menu"} color={inline ? "primary" : "#FFF"} size={20} />
      </Flex>
      {/* <Icon name={status === "open" ? "x" : "menu"} /> */}
    </Touchable>
  );
};

const Drawer = ({
  theme,
  state,
  navigation,
  descriptors,
  logo,
  avatar,
  version,
  FooterComponent = null,
  ScrollComponent = ScrollView,
  HeaderComponent = null,
  spacing = 18,
  small = false,
  user,
  themeselect = true,
  onBurgerPress,
  ...rest
}) => {
  const insets = useSafeAreaInsets();
  if (small) spacing = spacing * 0.5;
  return (
    <>
      <ScrollComponent
        style={{ backgroundColor: theme.colors.primary }}
        contentContainerStyle={{ flex: 1 }}
      >
        <Flex
          w="100%"
          flex={1}
          bg="primary"
          justifyContent="space-between"
          pt={insets.top}
          pb={insets.bottom}
          {...rest}
        >
          <Flex>
            {HeaderComponent}
            <Flex alignItems="flex-start" p={spacing} row>
              {avatar ? (
                <AppIcon size={small ? 42 : undefined} {...avatar} />
              ) : null}
              {themeselect ? <ThemeSelect ml={10} /> : null}
            </Flex>
            {state.routes.map((route, i) => {
              const focused = i === state.index;
              const { title, label, hidden, icon, iconSize, pill } =
                descriptors[route.key].options;
              if (hidden) return null;
              return (
                <>
                  {label ? (
                    <Text
                      color="#FFF"
                      numberOfLines={1}
                      font="label"
                      px={20}
                      pb={10}
                      pt={20}
                    >
                      {label}
                    </Text>
                  ) : null}
                  <Touchable
                    bg={focused ? "rgba(0,0,0,0.2)" : undefined}
                    key={route.key}
                    onPress={() => {
                      navigation.dispatch({
                        ...(focused
                          ? DrawerActions.closeDrawer()
                          : CommonActions.navigate({
                              name: route.name,
                              merge: true,
                            })),
                        target: state.key,
                      });
                    }}
                    row
                    alignItems="center"
                    px={small ? spacing * 2 : spacing}
                    py={small ? spacing : spacing * 0.7}
                  >
                    {icon ? (
                      <Flex relative>
                        <Icon
                          name={icon}
                          size={iconSize || undefined}
                          mr={spacing / 1.5}
                          animate={focused}
                          animateFillPath="primary:lighten:10:setAlpha:0.5"
                          duration={400}
                          strokeWidth={focused ? 1.5 : 1}
                          color={
                            focused
                              ? "primary:lighten:40"
                              : "primary:lighten:20:setAlpha:0.5"
                          }
                        />
                        {pill ? (
                          <Flex
                            bg="error"
                            px={7.5}
                            py={2.5}
                            borderRadius={10}
                            t={-6}
                            r={0}
                            absolute
                          >
                            <Text color="#FFF" font="label" bold>
                              {pill}
                            </Text>
                          </Flex>
                        ) : null}
                      </Flex>
                    ) : null}
                    {small ? null : (
                      <Text
                        numberOfLines={1}
                        style={{
                          color: "#FFF",
                          fontWeight: "500",
                        }}
                      >
                        {title || route.name}
                      </Text>
                    )}
                  </Touchable>
                </>
              );
            })}
          </Flex>
          <Flex p={spacing}>
            {FooterComponent}
            {user ? (
              <Touchable
                onPress={() => navigation.navigate(user.route)}
                w="100%"
                borderRadius={30}
                bg="primary:darken:5"
                alignItems="center"
                justifyContent="space-between"
                p={small ? 0 : spacing / 3}
                row
              >
                <Flex alignItems="center" row>
                  <Avatar
                    source={user.source}
                    borderWidth={1}
                    borderColor="primary:darken:5"
                    size={40}
                  />
                  {small ? null : (
                    <Text ml={10} color="#FFF">
                      {user.name}
                    </Text>
                  )}
                </Flex>
              </Touchable>
            ) : null}
          </Flex>

          {small ? null : version ? (
            <Flex t={spacing + insets.top} r={spacing} absolute>
              <Text color="primary:lighten:20:setAlpha:0.5" font="label">
                {version}
              </Text>
            </Flex>
          ) : null}
        </Flex>
      </ScrollComponent>
      <BurgerMenu onPress={onBurgerPress} />
    </>
  );
};

export default withThemeProps(Drawer, "Drawer");

import React from "react";
import { View } from "react-native";
import { Progress, Flex, Text } from "unikit";

export const scorePassword = (pass) => {
  var score = 0;
  if (!pass) return score;

  // award every unique letter until 5 repetitions
  var letters = new Object();
  for (var i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 5.0 / letters[pass[i]];
  }

  // bonus points for mixing it up
  var variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  };

  var variationCount = 0;
  for (var check in variations) {
    variationCount += variations[check] == true ? 1 : 0;
  }
  score += (variationCount - 1) * 10;

  return parseInt(score) > 100 ? 100 : parseInt(score);
};
const scores = [
  { min: 0, max: 33.33, label: "schwach", color: "#f44336" },
  { min: 33.34, max: 66.66, label: "mittel", color: "#ffbb33" },
  { min: 66.67, max: 100, label: "stark", color: "#8bc34a" },
];

export default ({ password, ...rest }) => {
  const score = scorePassword(password || 0);
  const scoreInfo = scores.find((s) => s.min <= score && s.max >= score);
  console.log({ score });
  return (
    <Flex
      p={15}
      borderRadius={10}
      shadow={3}
      alignItems="center"
      bg="surface"
      row
      {...rest}
    >
      <Progress
        size={36}
        trackWidth={5}
        progressWidth={5}
        trackColor="primary:setAlpha:0.1"
        progressColor={scoreInfo.color}
        value={score}
      />
      <View style={{ marginLeft: 12 }}>
        <Text font="label">{`Passwort stärke:`}</Text>
        <Text color={scoreInfo.color}>{`${scoreInfo.label}`}</Text>
      </View>
    </Flex>
  );
};

import React, { useState, useEffect, useRef } from "react";
import { Flex, Input, Tooltip } from "unikit";

import ListView from "./list";
import i18n from "../../i18n";
import { renderAction } from "../page";

import { useData } from "api";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const getFilterByOptions = ({ options, filterOptions, filter = {} }) => {
  let newFilter = {};

  Object.keys(options).forEach((key) => {
    const filterOption = filterOptions?.[key];
    newFilter = {
      ...newFilter,
      ...(options?.[key] && filterOption?.key
        ? { [filterOption.key]: filterOption?.formatFilter(options[key]) }
        : {}),
    };
  });
  return { ...newFilter, ...filter };
};

export default ({
  title,
  titleProps,
  searchData,
  query,
  filter,
  skip,
  subFilter = {},
  sort = {},
  limit = 25,
  addTitle = "",
  onAdd,
  typename,
  horizontal = false,
  onLoad,
  action = null,
  withDeleted = false,
  filterOptions,
  defaultOptions = {},
  renderToSection,
  loadMoreMode,
  ...rest
}) => {
  const [options, setOptions] = useState(defaultOptions);
  const [page, setPage] = useState(0);
  const listRef = useRef(null);
  const filterWithOptions = React.useMemo(
    () =>
      filterOptions
        ? getFilterByOptions({ options, filterOptions, filter })
        : filter || {},
    [options, filterOptions, filter]
  );
  const { loading, data, count, name, fetchMore } = useData({
    typename,
    skip,
    variables: {
      filter: filterWithOptions,
      sort: sort,
      page: loadMoreMode === "paging" ? page : 0,
      limit: limit || undefined,
      ...(withDeleted ? { withDeleted: true } : {}),
      ...subFilter,
    },
  });

  const prevFilter = usePrevious(JSON.stringify(filter));

  useEffect(() => {
    if (loading === false && data && onLoad) {
      onLoad({ name, typename, data, count });
    }
  }, [loading, data]);

  useEffect(() => {
    if (prevFilter !== JSON.stringify(filter)) {
      setPage(0);
    }
  }, [filter]);

  useEffect(() => {
    if (renderToSection && filterOptions) {
      renderToSection(
        <Tooltip
          popover={() => (
            <>
              {Object.keys(filterOptions).map((key) => {
                return (
                  <Flex mr={10}>
                    <Input
                      label={key}
                      w="auto"
                      type={filterOptions[key]?.input || "select"}
                      placeholder={filterOptions[key]?.placeholder}
                      borderRadius={25}
                      size={44}
                      fontSize={15}
                      value={options?.[key]}
                      options={filterOptions[key].options}
                      onChange={(option) => {
                        setOptions?.((o) => ({
                          ...o,
                          [key]: option,
                        }));
                      }}
                    />
                  </Flex>
                );
              })}
            </>
          )}
        >
          {renderAction({ icon: "filter", mr: 10 })}
        </Tooltip>
      );
      return () => {
        renderToSection(null);
      };
    }
  }, [options]);

  const getData = (data) => {
    if (!data) return [];
    if (withDeleted) return data;
    return data.filter((d) => d.deleted !== true);
  };

  const listData = searchData ? searchData : data ? getData(data || []) : [];

  const onLoadMoreTriggert = () => {
    let newPage = page + 1;
    setPage(newPage);
    const variables = {
      filter: filterWithOptions,
      sort: sort,
      page: newPage,
      limit: limit || undefined,
    };
    console.log({ variables });
    fetchMore({
      variables,
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult || !fetchMoreResult[name]) return prev;
        return Object.assign({}, prev, {
          [name]: {
            data: [
              ...(prev[name]?.data || []),
              ...(fetchMoreResult[name]?.data || []),
            ],
            count: fetchMoreResult[name]?.count || 0,
          },
        });
      },
    });
  };

  const ListHeaderComponent = React.useCallback(
    () =>
      filterOptions && !renderToSection ? (
        <Flex p={10} wrap row>
          {Object.keys(filterOptions).map((key) => {
            return (
              <Flex mr={10}>
                <Input
                  label={key}
                  w="auto"
                  type={filterOptions[key]?.input || "select"}
                  borderRadius={25}
                  size={44}
                  fontSize={15}
                  value={options[key]}
                  options={filterOptions[key].options}
                  onChange={(option) => {
                    setOptions((o) => ({ ...o, [key]: option }));
                  }}
                />
              </Flex>
            );
          })}
        </Flex>
      ) : null,
    []
  );

  //console.log({ typename, listData, count, filter });

  return (
    <>
      <ListView
        ref={listRef}
        data={listData}
        loading={loading}
        page={page}
        loadMoreMode={loadMoreMode}
        maxPages={Math.ceil(count / limit) - 1}
        setPage={setPage}
        limit={page * limit}
        count={count}
        style={horizontal ? { padding: 7.5 } : {}}
        horizontal={horizontal}
        noContentText={
          typename
            ? `Noch keine ${
                title || i18n.t(`types.${typename.toLowerCase()}_plural`)
              } vorhanden`
            : undefined
        }
        options={options}
        setOptions={setOptions}
        ListHeaderComponent={ListHeaderComponent}
        // refreshing={false}
        // onRefresh={() => {
        //   setRefresh(true);
        //   setPage(0);
        //   refetch()
        //     .then(() => {
        //       setRefresh(false);
        //     })
        //     .catch((error) => {
        //       console.log(error);
        //     });
        // }}
        {...rest}
        loadMore={() => {
          onLoadMoreTriggert();
        }}
      />
    </>
  );
};
